var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 container d-flex",staticStyle:{"display":"block","margin":"0px","padding":"0px","min-height":"calc(100vh - 150px)","margin-bottom":"55px"}},[_c('div',{staticClass:"row p-0 m-0",staticStyle:{"max-width":"590px"}},[(_vm.showFiveSecCountDown)?_c('div',{staticClass:"fullScreenMessages",attrs:{"id":"fiveSecCountDown"}}):_vm._e(),(_vm.soundCountDown)?_c('audio',{staticStyle:{"display":"none"},attrs:{"id":"audio","controls":"","autoplay":""}},[_c('source',{attrs:{"src":require("../../static/sounds/5sec.mp3"),"type":"audio/mp3"}})]):_vm._e(),(!_vm.showVideo)?_c('div',{staticClass:"sentenceAboveMap mb-3",style:(_vm.variableBackgroundColor),attrs:{"id":"sentenceAboveMap"}},[_c('div',{staticStyle:{"font-family":"'system-ui'"}},[_vm._v(" "+_vm._s(_vm.lang)+_vm._s(_vm.sentenceShownAboveMap)+" ")])]):_vm._e(),_c('div',{staticStyle:{"min-height":"320px","background":"black","max-width":"590px","position":"relative","overflow":"hidden"}},[_c('div',{style:(_vm.variableBackgroundColor),attrs:{"id":"map2"}},[(_vm.showMap)?_c('GmapMap',{staticStyle:{"width":"100vw","height":"50vh"},attrs:{"center":_vm.centerMap,"zoom":Number(_vm.mapParameters.zoom),"map-type-id":"satellite","tilt":0,"styles":_vm.myMapStyle,"options":{
            zoomControl: false,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false,
            disableDefaultUi: false,
          }}},[(_vm.noGoogleRouteArray[_vm.pointNum])?_c('PolylineMap',{attrs:{"path":_vm.wayPoints[_vm.pointNum],"editable":false,"options":{
              strokeColor: '#5C81C4',
              strokeWeight: 5,
              strokeOpacity: 0.8,
            }}}):_c('DirectionsRenderer',{attrs:{"travelMode":"WALKING","origin":_vm.startLocation,"destination":_vm.endLocation,"waypoints":_vm.wayPoints[_vm.pointNum],"icon":_vm.iconImage}}),(_vm.showPosMarker)?_c('GmapMarker',{attrs:{"clickable":true,"position":_vm.myCurrentPosition,"icon":_vm.iconImage},on:{"click":function($event){_vm.centerMap = _vm.m.position}}}):_vm._e(),_vm._l((_vm.twoMarkersArray),function(m,index){return _c('GmapMarker',{key:index,staticStyle:{"margin-right":"10px !important"},attrs:{"position":m,"clickable":true,"draggable":false,"label":{
              text: ("" + (index + 1)),
              color: 'white',
              fontSize: '9px',
            }},on:{"click":function($event){_vm.centerMap = m}}})})],2):_vm._e()],1),_c('div',[(_vm.showMessageTimeFinished)?_c('div',{staticClass:"messageAboveMap p-3",staticStyle:{"font-size":"40px"},attrs:{"id":"timeFinishedElement"}},[_vm._v(" "+_vm._s(_vm.translation.timeOver)+" ")]):_vm._e(),(_vm.showVerifyingPosition)?_c('div',{staticClass:"messageAboveMap p-3",staticStyle:{"font-size":"30px"},attrs:{"id":"verifyingPosition"}},[_vm._v(" "+_vm._s(_vm.translation.locationVerification)+" "),_c('img',{attrs:{"src":require("../../static/img/satellite.gif"),"alt":""}})]):_vm._e(),(_vm.showBuzzer)?_c('div',{staticClass:"messageAboveMap",attrs:{"id":"buzzer"}},[_c('button',{staticClass:"buzzerButton btn btn-primary btn-lg font-weight-bold mb-4",staticStyle:{"z-index":"20"},attrs:{"id":"buttonBuzzer"},on:{"click":_vm.buzzerClicked}})]):_vm._e()])]),_c('div',{staticClass:"row align-items-center justify-content-center align-self-center m-0 p-0",staticStyle:{"min-height":"15%","width":"100vw","margin-bottom":"50px !important","height":"50px","top":"-145px","position":"relative"}},[(!_vm.reachedThePoint && _vm.showTimerBackground)?_c('div',{staticClass:"checkLocationButtonDisabeld rtl text-center mt-4"},[(_vm.showTimerBackground)?_c('div',{staticClass:"timerBackground",on:{"click":_vm.flipToBuzzer}},[_c('div',{staticClass:"text-center",attrs:{"id":"timer"}},[_vm._v("0"+_vm._s(_vm.timePerPoint)+":00")])]):_vm._e(),(_vm.showExplosion)?_c('div',{attrs:{"id":"explosionDiv"}},[_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"}),_c('div',{staticClass:"explosion"})]):_vm._e()]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }