import { render, staticRenderFns } from "./tutoPage.vue?vue&type=template&id=82fe487e&scoped=true"
import script from "./tutoPage.vue?vue&type=script&lang=js"
export * from "./tutoPage.vue?vue&type=script&lang=js"
import style0 from "./tutoPage.vue?vue&type=style&index=0&id=82fe487e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82fe487e",
  null
  
)

export default component.exports