<template >
  <div
    style="
      height: calc(100vh - 60px);
      font-size: 43px;
      color: white;
      max-width: 590px;
      font-family: system-ui;
    "
  >
    <div
      class="row align-items-center text-center rousseauVoltaire"
      style="height: 30%"
      @click="openRousseauVoltaire"
    >
      <div class="col-12">ROUSSEAU ET VOLTAIRE</div>
    </div>
    <div
      class="row align-items-center text-center fanDeFoot"
      style="height: 30%"
      @click="openEuroGeneve"
    >
      <div class="col-12">FAN DE FOOT</div>
    </div>
    <div
      class="row align-items-center text-center geneveEscalade"
      style="height: 30%"
      @click="openEscalade"
    >
      <div class="col-12">
        <div class="textDarkGeneva" style="font-size: 19px; top: 17px">
          Revivez l'Escalade
        </div>
        GENEVE
      </div>
    </div>
    <a href="http://www.rayonnez.ch">
      <div
        class="row align-items-center text-center renens"
        style="height: 30%; color: white"
      >
        <div class="col-12">RENENS</div>
      </div>
    </a>
    <div
      class="row align-items-center text-center darkGenevaMain"
      style="height: 30%"
      @click="openDarkGeneva"
    >
      <div class="col-12">
        <div class="textDarkGeneva">Les heures sombres de</div>
        GENEVE
      </div>
    </div>
    <div
      class="row align-items-center text-center nationsMain"
      style="height: 30%"
      @click="openNations"
    >
      <div class="col-12">NATIONS</div>
    </div>
    <div
      class="row align-items-center text-center lakeMain"
      style="height: 30%"
      @click="openLake"
    >
      <div class="col-12">{{ lang }}{{ translation.lake }}</div>
    </div>
    <div
      class="row align-items-center text-center geneveMain"
      style="height: 30%"
      @click="openGeneve"
    >
      <div class="col-12">{{ lang }}{{ translation.geneva }}</div>
    </div>
    <div
      class="row align-items-center text-center lancyMain"
      style="height: 30%"
      @click="openLancy"
    >
      <div class="col-12">LANCY</div>
    </div>

    <div
      class="row align-items-center text-center carougeMain"
      style="height: 30%"
      @click="openCarouge"
    >
      <div class="col-12">CAROUGE</div>
    </div>
    <!-- <div>
      <button
        id="goToDoItYourself"
        class="fullWidth btn font-weight-bold"
        @click="openDIY"
      >
        {{ translation.buttonDIY }}
      </button>
    </div> -->
    <div class="row align-items-center text-center">
      <div class="col-6 footer">© 2024 ouchui.ch</div>
      <div class="col-6 footer">contact@ouchui.ch</div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      navigationName: "",
      translation: {
        geneva: "GENEVE",
        lancy: "LANCY",
        lake: "LAC",
        buttonDIY: "Créez votre parcours",
      },
    };
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    // loading() {
    //   return this.$store.getters.loading;
    // }
  },

  methods: {
    translate(lang) {
      if (lang == "fr") {
        this.translation = {
          geneva: "GENEVE",
          lancy: "LANCY",
          lake: "LAC",
          buttonDIY: "Créez votre parcours",
        };
      } else {
        this.translation = {
          geneva: "GENEVA",
          lancy: "LANCY",
          lake: "LAKE",
          buttonDIY: "Do your own game",
        };
      }
    },
    openDIY() {
      this.$router.push("/doItYourselfPage");
    },
    openEuroGeneve() {
      this.$router.push("/euro24Geneva");
    },
    openEscalade() {
      this.$router.push("/escalade");
    },
    openRousseauVoltaire() {
      this.$router.push("/rousseauVoltaireGeneve");
    },
    openCarouge() {
      this.$router.push("/carouge");
    },
    openNations() {
      this.$router.push("/nations");
    },
    openLancy() {
      this.$router.push("/lancy");
    },
    openGeneve() {
      this.$router.push("/geneve");
    },
    openLake() {
      this.$router.push("/lake");
    },
    openMeyrin() {
      this.$router.push("/meyrin");
    },
    openDarkGeneva() {
      this.$router.push("/darkGeneva");
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>

<style scoped>
#goToDoItYourself {
  position: fixed;
  background-color: rgba(84, 163, 49, 80%);
  width: 80px;
  height: 80px;
  border: solid 2px white;
  border-radius: 82px;
  bottom: 28px;
  font-size: 12px;
  color: white;
  right: 10px;
}
.footer {
  max-height: 30px;
  font-size: 15px;
  background: #3369bc;
}
.darkGenevaMain {
  background: url("../../static/img/darkGenevaMainPage.png") no-repeat center
    bottom;
}
.textDarkGeneva {
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  top: 11px;
  position: relative;
}
.nationsMain {
  background: url("../../static/img/GeneveBlackMain.jpg") no-repeat center
    center;
}
.lakeMain {
  background: url("../../static/img/LakeBlackMain.jpg") no-repeat center center;
}
.geneveMain {
  background: url("../../static/img/GeneveBlackMain1.jpg") no-repeat center
    center;
}
.geneveEscalade {
  background: url("../../static/img/escalade.png") no-repeat center center;
}
.rousseauVoltaire {
  font-size: 26px;
  background: url("../../static/img/rousseauVoltaire.jpg") no-repeat top 30%
    center;
}
.fanDeFoot {
  background: url("../../static/img/stade.jpg") no-repeat center center;
}
.renens {
  background: url("../../static/img/autourDuRail.png") no-repeat center center;
}
.carougeMain {
  background: url("../../static/img/CarougeBlackMain.jpg") no-repeat center
    center;
}
.lancyMain {
  background: url("../../static/img/LancyBlackMain.jpg") no-repeat center center;
}
.meyrinMain {
  background: url("../../static/img/MeyrinBlackMain.jpg") no-repeat center
    center;
}
.myModal {
  width: 320px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  background-color: white;
}
.over {
  position: fixed;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.center {
  display: block;
  margin: 0px auto;
}
/*This is the code to make the spinner spin*/
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>



