<template >
  <div id="smart-button-container">
    <div style="background: white">
      <div class="p-3">
        <strong> Avant de se lancer, informations importantes: </strong>
      </div>
      <div class="px-3">
        - Prix:
        <strong> 5 francs </strong>
      </div>
      <div class="px-3 pt-1">- Paiement: Carte de crédit ou PayPal.</div>

      <div class="px-3 pt-1">- Le jeu commence dès le paiement effectué.</div>
      <div class="px-3 pt-1">
        - Le jeu sera disponible sur ce smartphone pendant 24 heures après le
        paiement.
      </div>
      <div class="px-3 pb-4 pt-1">- Confirmation de paiement par courriel.</div>
      <div class="row px-2 pb-4 pt-1 my-4 ml-0 mr-0">
        <div class="col-3 pl-2">Coupon:</div>
        <input class="col-4" v-model="coupon" type="text" />
        <button class="col-3 ml-3" @click="checkCoupon">Envoyer</button>
      </div>

      <div id="paypal-button-container"></div>

      <div v-if="confirmation === true" class="over center">
        <div
          class="p-3 myModal2"
          style="background-color: rgb(84, 163, 49); color: white"
        >
          <div class="mt-3 text-center">
            <div
              class="mt-3 rtl"
              style="font-family: 'noot', cursive; font-size: 40px"
            >
              Le paiment est passé avec succès, nous pouvons commencer!
            </div>
            <div class="mt-3 text-center">{{ translation.noPassword }}</div>
          </div>
          <div class="font-weight-bold mt-5 mb-2 h4 text-center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      coupon: "",
      buyerName: "",
      confirmation: false,
    };
  },
  mounted() {
    const script = document.createElement("script");
    // LIVE SUISSE
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AQ4zqAKziwpPiG12RcV1_dqwhx2fW_jZy3QM1IATpUHC5j-JK1sn5BJ6sEmHokvDerMSvySl8Pq2jrGs&currency=CHF&locale=fr_CH";

    // DEMO SUISSE
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=AXWu7-6QzQWJFovTtA5cAXkA44eXIV9-E5yAV7QyXmVBfn4ZIjPLJeITnwUKY-sUeANc2SZyZsUvmY1R&currency=CHF&locale=fr_CH";
    // // DEMO
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=AT8H_86S3uUnwgMYtKwJiJXrjzpis2FDYUmr8da45d46wuHhFGIgniH3NgNIdJeyKnUEQa-pyXJHpK9B&currency=CHF&locale=fr_CH";
    // LIVE
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=ARLYyNiETX705wbleW13jVZbEmlHUs6HCxKDpDyYpyu4akvxajWf7U9AmzPMf789jos4EpjBvJc-zy2-&currency=CHF&locale=fr_CH";
    // data - sdk - integration - source = "button-factory";
    script.addEventListener("load", this.initPayPalButton);
    document.body.appendChild(script);
    this.waitForAnswer();
  },

  computed: {},
  methods: {
    checkCoupon() {
      if (this.coupon == "ouchui1602") {
        this.$router.push("/play");
      } else {
        console.log("BAD COUPON", this.coupon);
      }
    },
    waitForAnswer() {
      // console.log("In wait for answer");
      var waitForAnswerLoop = setInterval(() => {
        if (localStorage.getItem("details")) {
          console.log("details from local storage");
          this.details = JSON.parse(localStorage.getItem("details"));
          // console.log("this.details", this.details);
          clearInterval(waitForAnswerLoop);
          if (this.details.status == "COMPLETED") {
            setTimeout(() => {
              localStorage.removeItem("details");
            }, 86400000);
            // this.$router.push("/startGame");
            this.$router.push("/play");
          } else {
            alert(
              "Le paiement n'est pas passé, veuillez s'il-vous plait réessayer."
            );
          }
        }
        // else {
        //   console.log("STILL WAITING IN waitForAnswerLoop");
        // }
      }, 200);
    },
    initPayPalButton() {
      paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "blue",
            layout: "vertical",
            label: "paypal",
          },

          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Parcours de l'Escalade - valide 24 heures",
                  amount: { currency_code: "CHF", value: 5 },
                },
              ],
            });
          },

          onApprove: function (data, actions) {
            console.log("data and actions", data, actions);
            return actions.order.capture().then(function (details) {
              console.log("details", details);
              localStorage.setItem("details", JSON.stringify(details));
              // this.buyerName = details.payer.name.given_name;
              // this.confirmation = true;
              alert(
                "Transaction completed by " +
                  details.payer.name.given_name +
                  "!"
              );
            });
          },

          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    },
  },
};
</script>

<style scoped>
.myModal2 {
  width: 320px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  background-color: rgb(84, 163, 49);
  color: white;
  border: solid 2px white;
}
.over {
  position: fixed;
  top: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 100vh;
  background: url("../../static/img/escalade.png") no-repeat center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
}
.center {
  display: block;
  margin: 0px auto;
}
.rtl {
  direction: rtl;
}
.resultDiv {
  text-align: right;
  border-bottom: solid 1px grey;
  font-size: 25px;
}
.colorDiv {
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 25px;
  padding-top: 30px;
}
.container {
  margin-top: 16px;
  background-color: rgba(248, 242, 242, 0.2);
  font-size: 20px;
  /* height: calc(100vh - 82px); */
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.rtl {
  direction: rtl;
}
.userName {
  font-size: 25px;
  text-align: right;
  color: green;
  font-family: "noot";
  /* text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0); */
}
</style>
