<template >
  <div
    class="row align-items-center justify-content-center align-self-center m-0 p-0"
    style="
      min-height: 15%;
      width: 100vw;
      margin-bottom: 50px !important;
      color: white;
    "
  >
    <div class="col-12 p-0 mt-4">
      <p class="text-center px-3 h4">{{ translation.pointNumber }}</p>
    </div>
    <div class="font-weight-bold h4 text-center pointNumber my-2">
      {{ Object.values(points)[pointsArrayOrder[pointNum] - 1].pointNumber }}
    </div>
    <div>
      <div class="col-12 p-0 m-0 mb-2">
        <p class="px-2 text-center" style="font-size: 18px">
          {{ translation.findMissingWord }}
        </p>
      </div>
      <img id="imgUrl" class="align-center" width="95%" :src="getImgUrl" />
    </div>
    <div
      class="col-1 pl-2 pr-0 text-right"
      style="font-size: 30px; color: rgb(0, 112, 176); margin-top: 11px"
      @click="showAlertSkipPoint"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        class="bi bi-skip-forward-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5z"
        />
      </svg>
    </div>
    <div class="mt-3 col-9 pr-1">
      <input
        class="center fullWidth text-center"
        aria-describedby="missingWordAnswer"
        :placeholder="translation.translationMissingWord"
        name="missingWordAnswer"
        label="missingWordAnswer"
        id="missingWordAnswer"
        v-model="missingWordAnswer"
        required
        style="height: 50px; border: 1px rgb(0, 112, 176) solid; width: 100%"
      />
    </div>
    <div
      class="col-1 pl-0"
      style="
        font-size: 30px;
        color: rgb(0, 112, 176);
        padding: 0px;
        margin-top: 17px;
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="currentColor"
        class="bi bi-send-fill float-right"
        viewBox="0 0 16 16"
        style="transform: rotate(45deg)"
        @click="checkMissingWord"
      >
        <path
          d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"
        />
      </svg>
    </div>
    <div v-if="alertSkipPoint" class="over">
      <div class="myModal p-4 mb-5 red container d-flex">
        <div class="row justify-content-center align-self-center">
          <div class="h5 mb-4 text-center col-12">
            {{ translation.skipPointBeware }}
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="width: 100%"
              @click="skipPoint()"
            >
              {{ translation.skipPoint }}
            </div>
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="
                width: 100%;
                padding-top: 9px;
                color: red;
                background: white;
                height: 38px;
                border-radius: 30px;
              "
              @click="alertSkipPoint = false"
            >
              {{ translation.back }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      missingWordAnswer: "",
      pointNum: this.$parent.pointNum,
      alertSkipPoint: false,
      activeClass: "activePoint",
      notActive: "smallPoint",
      // activeClassPoint: "activeClassPoint",
      notActivePoint: "numbersInCercle",
      slide: 0,
      sliding: null,
      key: "",
      translation: {
        back: "Fermer",
        skipPoint: "Passer au point suivant",
        findMissingWord:
          "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
        pointNumber: "Sur la carte, repérez le nº",
        translationMissingWord: "Mot manquant sur l'image",
        skipPointBeware:
          "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
      },
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
    };
  },
  mounted() {},
  computed: {
    getImgUrl() {
      if (
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .imageBase64
      ) {
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].imageBase64;
      } else {
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].imageUrl;
      }
    },
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
    points() {
      return this.$store.getters.points;
    },
  },

  methods: {
    checkMissingWord() {
      ////////CORRECT ANSWER///////////////////
      this.storePointNumber();
      var audio;
      if (
        this.missingWordAnswer.toLowerCase() ===
          Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
            .missingWord ||
        this.missingWordAnswer.toLowerCase().replace(/\s/g, "") ===
          Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
            .missingWord
      ) {
        this.$parent.checkElementPicked();
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        this.$parent.showStarUp("5+");
        this.$parent.showCorrectAnswerIcon = true;

        document
          .getElementById("missingWordAnswer")
          .classList.add("greenBorder");
        audio = new Audio(this.correctAnswerSound);
        audio.play();
        setTimeout(() => {
          document
            .getElementById("missingWordAnswer")
            .classList.remove("greenBorder");
          this.missingWordAnswer = "";
          this.$parent.showPointInformation = true;
          this.$parent.showCorrectAnswerIcon = false;
          localStorage.setItem(
            "showPointInformation",
            this.$parent.showPointInformation
          );
          setTimeout(() => {
            this.$parent.showButtonInfoScreen = true;
          }, 2000);
          this.$parent.showPickedItem = false;
          localStorage.setItem("showPickedItem", this.$parent.showPickedItem);
          this.$parent.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.$parent.showMainScreen);
        }, 1000);
        ////////WRONG ANSWER///////////////////
      } else {
        document.getElementById("missingWordAnswer").classList.add("redBorder");
        audio = new Audio(this.$parent.wongAnswerSound);
        audio.play();
        setTimeout(() => {
          document
            .getElementById("missingWordAnswer")
            .classList.remove("redBorder");
        }, 1000);
        // this.errorNumber++;
        this.$parent.errorNumber--;

        localStorage.setItem("errorNumber", this.$parent.errorNumber);
      }
    },
    storePointNumber() {
      localStorage.setItem("pointNumber", this.pointNum);
      localStorage.setItem(
        "pointNumForEvolution",
        this.$parent.pointNumForEvolution
      );
    },
    skipPoint() {
      this.$parent.checkElementPicked();
      this.storePointNumber();
      this.$parent.errorNumber = this.$parent.errorNumber - 20;
      localStorage.setItem("errorNumber", this.$parent.errorNumber);
      setTimeout(() => {
        localStorage.setItem(
          "showPointInformation",
          this.$parent.showPointInformation
        );
        document
          .getElementById("buttonContinueDisabled")
          .classList.remove("active");
        this.$parent.showButtonInfoScreen = true;
      }, 1000);
      this.$parent.showPointInformation = true;
      this.$parent.showPickedItem = false;
      localStorage.setItem("showPickedItem", this.showPickedItem);
      this.$parent.showMainScreen = false;
      localStorage.setItem("showMainScreen", this.showMainScreen);
      this.alertSkipPoint = false;
      // localStorage.setItem("firstQuestion", 1);
      this.$parent.startTime = this.$parent.startTime - 60 * 10 * 1000;
      this.$store.dispatch("addEvolutionToDB", {
        pointNumber: this.pointNum,
        userKey: this.userKey,
        navigationName: this.$parent.gameParameters.navigationName,
      });
    },
    showAlertSkipPoint() {
      this.alertSkipPoint = true;
    },
    checkAnswer(index, event) {
      // REPONSE EST JUSTE
      if (
        index ==
        this.arrayOfImages.indexOf(
          this.points[this.pointsArrayOrder[this.$parent.pointNum]].imageBase64
        )
      ) {
        event.target.parentElement.classList.add("greenBorder");
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        this.$parent.showStarUp("5+");
        this.$parent.showCorrectAnswerIcon = true;
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.checkElementPicked();
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        setTimeout(() => {
          this.$parent.showPointInformation = true;
          localStorage.setItem(
            "showPointInformation",
            this.$parent.showPointInformation
          );
          this.$parent.showCorrectAnswerIcon = false;
          this.$parent.showButtonInfoScreen = true;
          this.$parent.showPickedItem = false;
          localStorage.setItem("showPickedItem", this.showPickedItem);

          this.$parent.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.showMainScreen);

          // this.nextPointNumber();
        }, 1000);

        // REPONSE EST FAUSSE
      } else {
        event.target.parentElement.classList.add("redBorder");
        this.$parent.errorNumber = this.$parent.errorNumber - 10;
        this.$parent.$refs.pointsCounting.classList.add("redColor");
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        this.$parent.showWongAnswerIcon = true;

        audio = new Audio(this.wongAnswerSound);
        audio.play();

        setTimeout(() => {
          //   console.log("b4 remove redborder");
          event.target.parentElement.classList.remove("redBorder");
          // this.$refs.checkAnswerButton.classList.remove("redBorder");
          this.$parent.$refs.pointsCounting.classList.remove("redColor");
          this.$parent.showWongAnswerIcon = false;
        }, 1000);
      }
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            back: "Fermer",
            skipPoint: "Passer au point suivant",
            skipPointBeware:
              "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
            findMissingWord:
              "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
            checkAnswer: "Vérifier",
            chooseImageSeen: "Depuis ce point, je vois le visuel ci-dessus",
            imageNumber: "Visuel nº",
            goThere: "Rends-toi sur place.",
            pointNumber: "Sur la carte, repérez le nº",
            explaination:
              "Parmi les " +
              this.arrayOfImages.length +
              " visuels ci-dessous, lequel vois-tu de ce point ?",
            translationMissingWord: "Mot manquant sur l'image",
          };
          break;
        case "en":
          this.translation = {
            back: "Fermer",
            skipPoint: "Passer au point suivant",
            skipPointBeware:
              "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
            findMissingWord:
              "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
            translationMissingWord: "Mot manquant sur l'image",
          };
          break;
        case "de":
          this.translation = {
            back: "Fermer",
            skipPoint: "Passer au point suivant",
            skipPointBeware:
              "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, cela vous coûtera 10 points.",
            findMissingWord:
              "Rends-toi sur place et trouve l'inscription qui se trouve sur l'image ci-dessous.",
            checkAnswer: "Überprüfen",
            chooseImageSeen: "Von diesem Punkt aus sehe ich das obige Bild",
            imageNumber: "Bild Nr.",
            goThere: "Gehe dorthin.",
            pointNumber: "Finde die Nummer auf der Karte:",
            explaination:
              "Welches der folgenden " +
              this.arrayOfImages.length +
              " Bilder siehst du von diesem Punkt aus?",
            translationMissingWord: "Mot manquant sur l'image",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    // onSlideStart() {
    //   this.sliding = true;
    // },
    // onSlideEnd() {
    //   this.sliding = false;
    // },
  },
};
</script>

<style scoped>
.over {
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.myModal {
  height: auto;
  min-height: 200px;
  min-width: 300px;
  width: auto;
  border-radius: 10px;
  max-width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
}
#imgUrl {
  display: block;
  margin: 0 auto;
}
.pointNumber {
  margin: 0 auto;
  border: #3369bc solid 3px !important;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 3px;
  font-weight: bold;
  color: white;
}
.imageDiv {
  width: 86%;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
  border: 5px solid white;
}
.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
  height: 56px;
  border: solid white 5px;
  color: rgb(0, 112, 176);
  font-size: 15px;
}
.greenBorder {
  border: solid rgb(5, 253, 5) 5px !important;
}
.redBorder {
  border: solid rgb(253, 5, 5) 5px !important;
}
.red {
  background-color: #d41a1b;
  border: solid white 10px;
}
.spanNumbers {
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Cabin";
  padding-top: 3px;
}
.rowClickablePoints {
  display: flex;
  justify-content: center;
}
.arrowRight {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-top-width: 5px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  border-color: white;
  border-style: solid;
  top: 70px;
}
.arrowLeft {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 0px;
  border-color: white;
  border-style: solid;
  top: 70px;
}

.slidesCounting {
  width: auto;
  font-size: 21px;
  padding-top: 5px;
  background: #83cb978f;
  color: white;
  text-align: center;
  font-weight: bold;
  border: solid white 4px;
  border-radius: 4px;
  position: absolute;
  bottom: 229px;
  z-index: 2;
  padding: 8px;
  font-family: system-ui;
}

.bi-arrow-right-short,
.bi-arrow-left-short {
  font-size: 38px;
  color: white;
  position: relative;
  height: 40px;
  width: 40px;
  top: 0px;
}
.img-fluid {
  width: 100vw;
  height: auto;
}
#carousel-1 {
  /* background: rgb(171, 171, 171); */
  text-shadow: rgb(51 51 51) 1px 1px 2px;
  min-height: 230px;
  margin: 0px auto;
  width: 100vw;
  display: block;
  position: relative;
  /* max-width: 330px; */
  overflow: hidden;
}
.numbersInCercle {
  height: 40px;
  width: 40px;
  border-radius: 60px;
  background: #83cb97;
  opacity: 0.8;
  border: solid rgb(0 0 0) 1px;
  font-size: 22px;
  color: white;
}

.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.8;
}
.activePoint {
  opacity: 0.7;
  background: rgb(30, 255, 0);
  height: 10px;
  width: 10px;
  bottom: 3px;
  right: 2px;
  position: relative;
}
</style>



