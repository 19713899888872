<template>
  <div id="mainDiv">
    <nav
      class="navbar navbar-expand-lg navbar-light"
      style="background: #0070b0; height: 54px; flex-wrap: wrap"
    >
      <div class="navBarTitle align-middle">
        <span style="justify-content: flex-center">
          <img src="../static/img/logoWhite.png" alt="" />
          <!-- <img src="../static/img/logoRed.png" alt="" /> -->
        </span>
      </div>
      <!-- <div class="navBarTitle align-middle">
        <span style="justify-content: flex-center">{{ title }}</span>
      </div> -->
      <!-- <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-three-dots-vertical float-right mainMenu dropdown-toggle"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <path
          fill-rule="evenodd"
          d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
        />
      </svg> -->
      <!-- //*********************************************************************************
      */ -->
      <div
        class="nav-item dropdown"
        style="position: absolute; right: 0px; top: 7px"
      >
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-three-dots-vertical float-right mainMenu dropdown-toggle"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            />
          </svg>
        </a>
        <div
          class="dropdown-menu"
          style="
            top: 45px;
            width: 80vw;
            right: 0px !important;
            left: auto;
            width: 100vw;
            max-width: 590px;
          "
          aria-labelledby="navbarDropdown"
        >
          <ul
            class="navbar-nav mr-auto mt-lg-0"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            href=".navbar-collapse.show"
          >
            <li class="nav-item active p-3 text-right" @click="restartGame">
              {{ lang }}{{ translation.restart }}
            </li>
            <li class="nav-item active p-3 text-right" @click="reopenGame">
              {{ lang }}{{ translation.reopen }}
            </li>
            <li class="nav-item active p-3 text-right" @click="backToMainPage">
              {{ lang }}{{ translation.backToMainPage }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      translation: {
        restart: "Recommencer le jeu",
        reopen: "Reprendre le jeu",
        passNextPoint: "Passer au prochain point",
        backToMainPage: "Retour en page d'accueil",
      },
      title: "oùchui?",
    };
  },
  computed: {
    gameOn() {
      if (
        this.$store.getters.points.length != 0 &&
        this.$store.getters.evolutionPointNumber > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    gameOn2() {
      if (this.$store.getters.points.length != 0) {
        return true;
      } else {
        return false;
      }
    },
    user() {
      return this.$store.getters.user;
    },
    lang() {
      return this.translate(this.$store.getters.lang);
    },
  },
  methods: {
    // translate(lang) {
    //   if (lang == "fr") {
    //     this.translation = {
    //       restart: "Recommencer le jeu",
    //       reopen: "Reprendre le jeu",
    //       passNextPoint: "Passer au prochain point",
    //       backToMainPage: "Retour en page d'accueil",
    //     };
    //   } else {
    //     this.translation = {
    //       restart: "Restart the game",
    //       reopen: "Back to the game",
    //       passNextPoint: "Pass to the next point",
    //       backToMainPage: "Back to the main page",
    //     };
    //   }
    // },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            restart: "Recommencer le jeu",
            reopen: "Reprendre le jeu",
            passNextPoint: "Passer au prochain point",
            backToMainPage: "Retour en page d'accueil",
          };
          break;
        case "en":
          this.translation = {
            restart: "Restart the game",
            reopen: "Resume the game",
            passNextPoint: "Proceed to the next point",
            backToMainPage: "Back to the main page",
          };
          break;
        case "de":
          this.translation = {
            restart: "Spiel neu starten",
            reopen: "Spiel fortsetzen",
            passNextPoint: "Zum nächsten Punkt gehen",
            backToMainPage: "Zurück zur Hauptseite",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            restart: "Restart the game",
            reopen: "Resume the game",
            passNextPoint: "Proceed to the next point",
            backToMainPage: "Back to the main page",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    reopenGame() {
      if (localStorage.getItem("navigationName")) {
        this.$router.push("/" + localStorage.getItem("navigationName"));
        location.reload();
      } else {
        this.$router.push("/");
        location.reload();
      }
    },
    backToMainPage() {
      this.$router.push("/");
      location.reload();

      Object.keys(localStorage).forEach((key) => {
        if (!key.includes("details")) delete localStorage[key];
      });
      console.log(Object.keys(localStorage));

      // localStorage.clear();
    },
    restartGame() {
      console.log(
        "localStorage.getItem('navigationName')",
        localStorage.getItem("navigationName")
      );
      if (localStorage.getItem("navigationName")) {
        this.$router.push("/" + localStorage.getItem("navigationName"));
        location.reload();
        // localStorage.clear();

        Object.keys(localStorage).forEach((key) => {
          if (!key.includes("details")) delete localStorage[key];
        });
        console.log(Object.keys(localStorage));
      } else {
        this.$router.push("/");
        location.reload();
        // localStorage.clear();

        Object.keys(localStorage).forEach((key) => {
          if (!key.includes("details")) delete localStorage[key];
        });
        console.log(Object.keys(localStorage));
      }
    },
  },
  name: "App",
};
</script>

<style media="screen">
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-menu.show {
  display: block;
  opacity: 1 !important;
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}
.mainMenu {
  font-size: 26px;
  margin-bottom: 24px;
  color: white;
}
.logoCarouge {
  height: 51px;
  position: absolute;
  left: 8px;
}
.navBarTitle {
  position: relative;
  margin: 0 auto;
  font-family: "Slackey", cursive;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: 42px !important;
  top: -13px;
  color: white;
}

.navBarTitle img {
  height: 50px;
}

#mainDiv {
  direction: ltr;
  font-family: "Open Sans", sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  max-width: 590px;
  margin: 0 auto;
}
#mainDiv::after {
  content: "";
  /* background-image: url("../static/img/carougeBackground.png") !important; */
  background-image: url("../static/img/bgGreen.png") !important;
  background-position: center;
  background-size: cover;
  filter: hue-rotate(93deg);
  opacity: 0.8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}
#app {
  color: rgb(211, 211, 211);
  font-family: "Assistant", sans-serif;
}
.rtl {
  direction: rtl;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 600px) {
}
@font-face {
  font-family: lithos;
  src: url(/static/fonts/LithosPro-Regular.otf);
}
</style>