<template>
  <div v-if="!accepted">
    <p class="mt-4">
      <!-- Nous utilisons des cookies pour améliorer votre expérience. En continuant,
      vous acceptez notre utilisation des cookies. -->

      Ce site recourt à l'utilisation de cookies afin de garantir le bon
      fonctionnement de l'application, en sauvegardant des informations telles
      que votre nombre de points, votre progression, etc. Ces cookies sont
      indispensables pour assurer une expérience utilisateur optimale. Une fois
      le jeu terminé, tous les cookies sont supprimés de votre téléphone mobile.
      <button
        class="btn btn-primary btn-lg btn3d fullWidth font-weight-bold"
        @click="accept"
      >
        Accepter
      </button>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accepted: false,
    };
  },
  mounted() {
    if (localStorage.getItem("cokiesAccepted")) {
      this.accepted = localStorage.getItem("cokiesAccepted");
    }
  },
  methods: {
    accept() {
      this.accepted = true;
      localStorage.setItem("cokiesAccepted", true);
      // Enregistrez l'acceptation des cookies dans votre base de données Firebase
    },
  },
};
</script>

<style scoped>
.fullWidth {
  width: 80%;
  display: block;
  margin: 30px auto !important;
  border-radius: 50px;
  background-color: #0090e3 !important;
  margin-bottom: 45px;
  height: 47px;
}
/* .cookie-consent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
} */
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #354093 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #354093, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #354093 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
</style>