<template >
<div class="container h-100 rtl">
    <div row>
      <div xs12 sm6 offset-sm3 >
        <div style="background-color: rgba(248, 242, 242, 0.8)">
          <div primary-title class="p-3">ברוכים הבאים למשחק ניווט לכבוד היום הולדת של:</div>
          <div primary-title class="rtl font-weight-bold display-3 my-4" style="text-align: center">שילי</div>
          <div primary-title class="rtl p-3">תבחרו שם של קבוצה ובאו נצא לדרך! </div>
          <!-- <v-card-text> -->
              <form @submit.prevent="onSignin">

                <div class="p-3">
                    <input name="groupName" label="שם הקבוצה" id="groupName" v-model="groupName" required class="center fullWidth">
                </div>

                <div class="p-3">
                    <button block type="submit" :disabled="loading" :loading="loading" dark class="fullWidth">
                      הרשמה
                     <!-- <span slot="loader" class="custom-loader">
                         <v-icon light>cached</v-icon> 
                      </span> -->
                    </button>
                </div>

              </form>
          <!-- </v-card-text> -->
        </div>
        <div row v-if="error">
            <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
        </div>
      </div>
    </div>
</div>
</template>


<script>
  export default {
    data () {
      return {
        groupName: '',
        password: ''
      }
    },
    computed: {
      // user () {
      //   return this.$store.getters.user
      // },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },

    methods: {
      onSignin () {
        this.$store.dispatch('fetchPoints')
        console.log('onSignin clicked this.groupName =>', this.groupName)
        if (this.groupName === 'admin') {
          this.$router.push('/admin')
        } else {
          this.$router.push('/welcomePage')
          this.$store.dispatch('createNewGroup', {groupName: this.groupName})
        }
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style scoped>
    .fullWidth{
      width: 100%;
    }
    .rtl {
      direction: rtl;
    }
    .center {
      display: block; 
      margin: 0px auto;
    }
    .container{
      margin-top: 0px;
      padding: 8px;
      background-color: rgba(248, 242, 242, 0);
      font-family: 'Assistant', sans-serif;
      font-size: 18px;
    }
/*This is the code to make the spinner spin*/
      .custom-loader {
        animation: loader 1s infinite;
        display: flex;
      }
      @-moz-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @-o-keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
      @keyframes loader {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }

</style>
