<template >
  <div class="container" style="direction: ltr; text-align: left">
    <!-- ******************************POINTS*************************** -->

    <!-- ******************************POP UP CREATE NEW POINTS*************************** -->

    <div class="p-3">
      <!-- <form @submit.prevent="getImageBase64" width="90%"> -->
      <!-- <div class="mb-2 form-group"> -->
      <div>
        <button
          tag="label"
          type="button"
          class="btn btn-primary mb-5 btn-block btn-sm btnEditChangeImage"
          @click="onPickFile"
        >
          Upload Image
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="image/*"
            @change="onFilePicked"
          />
        </button>
      </div>
      <div>
        <img :src="imageUrl" ref="imageToCanvas" style="display: none" />
        <canvas ref="canvas" v-if="showCanvas" class="fitScreen"></canvas>
        <button
          block
          v-if="showCanvas"
          @click="onPickFile2"
          class="btn btn-primary mt-3 btnEditChangeImage mb-3"
        >
          Change image
        </button>
        <input
          type="file"
          style="display: none"
          ref="fileInput2"
          accept="image/*"
          @change="onFilePicked"
        />
      </div>
    </div>

    <div class="tab-content px-sm-0 mt-5" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-points"
        role="tabpanel"
        aria-labelledby="nav-points-tab"
      ></div>
    </div>
  </div>
</template>

<script>
// const exifparser = require("exif-parser");

export default {
  data() {
    return {
      picked: "",
      ImageBase64: "",
      imageUrl: "",
      showCanvas: false,
      image: null,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // ******************************************** UPLOAD PICTURE**********************************
    onPickFile() {
      // the $refs below give us access to all the ref elements in the template of this component
      this.$refs.fileInput.click();
    },
    onPickFile2() {
      // the $refs below give us access to all the ref elements in the template of this component
      console.log("this.$refs.fileInput2", this.$refs.fileInput2);
      console.log("this.showCanvas", this.showCanvas);
      this.$refs.fileInput2.click();
    },
    onPickFile3() {
      // the $refs below give us access to all the ref elements in the template of this component
      console.log("this.$refs.fileInput3", this.$refs.fileInput3[0]);
      this.$refs.fileInput3[0].click();
    },
    onFilePicked2(event) {
      console.log("[onFilePIcked] event", event);
      const files = event.target.files;
      console.log("[onFilePIcked] files", files);
      // As we can choose only one file, we take the first one in the array
      this.filename = files[0].name;
      // console.log("[onFilePIcked] filename", this.filename);
      // Simple chack if the file is valid
      if (this.filename.lastIndexOf(".") <= 0) {
        return alert("Please enter a valid image");
      }
      // Turn it into base64
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // the result here is a base64 image
        this.imageUrl = fileReader.result;
        this.ImageBase64 = fileReader.result;
        console.log("this.ImageBase64", this.ImageBase64);

        var img = new Image();
        img.src = this.imageUrl;
      });
      fileReader.readAsDataURL(files[0]);
      this.showCanvas = true;
    },
    onFilePicked(event) {
      // We get the wanted file
      // console.log("[onFilePIcked] event", event);
      const files = event.target.files;
      // console.log("[onFilePIcked] files", files);
      // this.fillEventDataFromImage(files[0]);
      // As we can choose only one file, we take the first one in the array
      this.filename = files[0].name;
      console.log("[onFilePIcked] filename", this.filename);
      // Simple chack if the file is valid
      if (this.filename.lastIndexOf(".") <= 0) {
        return alert("Please enter a valid image");
      }
      // Turn it into base64
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // the result here is a base64 image
        this.imageUrl = fileReader.result;
        this.ImageBase64 = fileReader.result;
        console.log("this.ImageBase64", this.ImageBase64);

        this.$emit("puzzleImageBase64", this.ImageBase64);
        this.$emit("ItemImage1", this.ImageBase64);
        this.$emit("ItemImage2", this.ImageBase64);
        this.$emit("ItemImage3", this.ImageBase64);
        this.$emit("ItemImage4", this.ImageBase64);

        var img = new Image();
        img.src = this.imageUrl;
        img.addEventListener("load", () => {
          let context = this.$refs.canvas.getContext("2d");
          let image = this.$refs.imageToCanvas;
          // let imageWidth = window.outerWidth - 16
          let imageWidth = 500;
          this.$refs.canvas.width = imageWidth;
          this.$refs.canvas.height = (imageWidth * image.height) / image.width;
          // Now I create the image - what?, top, left, width, height
          context.drawImage(
            image,
            0,
            0,
            imageWidth,
            (imageWidth * image.height) / image.width
          );
          this.image = this.dataURItoBlob(this.$refs.canvas.toDataURL());
        });
      });
      fileReader.readAsDataURL(files[0]);
      this.showCanvas = true;
    },
    // fillEventDataFromImage(file) {
    //   if ("image/jpeg" !== file.type) {
    //     console.log("IMAGE NOT OK");
    //     return;
    //   }
    //   console.log("image ok");
    //   const file_reader = new FileReader();
    //   const component = this;
    //   file_reader.addEventListener("load", (e) => {
    //     console.log("e", e);
    //     const parser = exifparser.create(file_reader.result);
    //     try {
    //       const imagemeta = parser.parse();
    //       var lng = imagemeta.tags.GPSLongitude;
    //       var lat = imagemeta.tags.GPSLatitude;
    //       if (lat === undefined) {
    //         // var emptyMarker = {
    //         //   position: { lat: 1, lng: 1 },
    //         //   pointNumber: this.pointNumber,
    //         //   navigationName: this.navigationName,
    //         // };
    //         this.pointLat = 1;
    //         this.pointLng = 1;
    //         console.log("empty marker");
    //         // this.$store.dispatch("createNewMarker", emptyMarker);
    //       } else {
    //         // var marker = {
    //         //   position: { lat: lat, lng: lng },
    //         //   pointNumber: this.pointNumber,
    //         //   navigationName: this.navigationName,
    //         // };
    //         this.pointLat = lat;
    //         this.pointLng = lng;
    //         console.log("lng, lat", lng, lat);
    //         // this.$store.dispatch("createNewMarker", marker);
    //       }
    //     } catch (err) {
    //       component.$error(err);
    //     }
    //   });
    //   file_reader.readAsArrayBuffer(file);
    // },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      console.log("[dataURItoBlob] blob", blob);
      return blob;
    },
  },
};
</script>

<style scoped>
.btnEditChangeImage {
  height: 35px;
  border: 0px;
  border-radius: 16px;
  margin: 0 auto;
  display: block;
  max-width: 170px;
  color: #3c69d9;
  border: solid #3c69d9 2px;
  padding-top: 4px;
}

.fitScreen {
  max-width: 20%;
}

.container {
  margin-top: 0px;
  background-color: rgba(255, 255, 255, 0);
  margin: 0px;
  padding: 0px;
}
</style>



