import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { CarouselPlugin } from "bootstrap-vue";

// import * as firebase from "firebase";
import { store } from "../store";
import firebase from "firebase/app";
import "firebase/app";
import "firebase/firestore";

import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyACbBFnoaG5EVR7-IDGn8lsiTtPHxWQWB4",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});
// Vue.forceUpdate()
Vue.config.productionTip = false;
Vue.use(CarouselPlugin);

const configOptions = {
  apiKey: "AIzaSyAknXfpDBZYJec-cfEGNiNm-gOQ8hh9tlU",
  authDomain: "individualcellnavapp.firebaseapp.com",
  databaseURL: "https://individualcellnavapp.firebaseio.com",
  projectId: "individualcellnavapp",
  storageBucket: "individualcellnavapp.appspot.com",
  messagingSenderId: "799101429507",
  appId: "1:799101429507:web:87f3c6f74745323e666b36",
  measurementId: "G-NKQ0QFCN3Y",
};

firebase.initializeApp(configOptions);

new Vue({
  router,
  store,
  rtl: true,
  render: (h) => h(App),
}).$mount("#app");
