<template >
  <div>
    <div class="container p-3">
      <div id="quitResults" @click="showEmailBox = true">
        <span>
          <svg
            class="bi bi-x-circle"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              fill-rule="evenodd"
              d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
            />
          </svg>
        </span>
      </div>
      <div>
        <p class="results text-center">{{ lang }}{{ translation.results }}</p>
      </div>
      <div
        :key="result.id"
        v-for="result in allResults"
        class="row resultDiv text-left"
      >
        <div v-if="result.place == 1" class="col-6 resultName">
          {{ result.place }}{{ translation.first }} place:
        </div>
        <div v-else class="col-6 resultName">
          {{ result.place }}{{ translation.afterFirst }} place:
        </div>
        <div class="col-6 resultName">
          {{ result.resultsOfTheGame.userName }}
        </div>
        <div class="col-12">
          {{ translation.errors }} : {{ result.resultsOfTheGame.errorNumber }}
        </div>
        <div class="col-12">
          {{ translation.gameTime }} :
          {{ result.resultsOfTheGame.secondsToTime }}
        </div>
        <div class="col-12 font-weight-bold">
          {{ translation.totalTime }} : {{ result.resultsOfTheGame.totalTime }}
        </div>
      </div>

      <div v-if="showEmailBox === true" class="over d-flex">
        <div
          class="myModal p-2 green d-flex row justify-content-center align-self-center align-items-center mt-0"
          v-if="!afterStars"
        >
          <div class="text-center">Votre avis nous interesse!</div>
          <star-rating
            :starSize="starSize"
            v-model="rating"
            :show-rating="false"
            style="margin-left: calc(50vw - 150px)"
            class="mb-3 mt-3"
          ></star-rating>
          <textarea
            name="review"
            rows="4"
            cols="50"
            v-model="comment"
            placeholder="Laissez-nous un commentaire..."
            class="p-2"
          >
          </textarea>
          <div
            class="position-relative text-center col-5 ml-2 pr-0"
            style="margin-top: 22px"
            @click="afterStars = true"
          >
            {{ translation.skip }}
          </div>
          <div
            class="colorBtn btn position-relative col-5"
            @click="sendComment"
          >
            {{ translation.send }}
          </div>
        </div>
        <div
          class="myModal p-2 green d-flex row justify-content-center align-self-center align-items-center mt-0"
          v-if="afterStars"
        >
          <div class="row"></div>
          <div class="row my-0">
            <div class="mt-0">
              <div class="mt-2 p-3">{{ translation.leaveEmail }}</div>
            </div>
          </div>
          <div class="row">
            <div class="mt-2 col-10 pr-1">
              <input
                class="center fullWidth text-center"
                name="email"
                type="email"
                label="Email"
                id="email"
                v-model="email"
                required
                placeholder="E-mail"
                style="
                  border-radius: 12px;
                  height: 41px;
                  border: 0px solid #0070b0;
                  width: 100%;
                "
              />
            </div>
            <div
              class="col-2 pl-0"
              style="font-size: 40px; color: white; padding: 0px; top: -5px"
              @click="sendEmail"
            >
              <svg
                class="bi bi-arrow-right-circle-fill"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
                />
              </svg>
            </div>
          </div>
          <!-- <div class="row"> -->

          <div class="row">
            <div
              class="backToResults mb-0 col-12"
              @click="showEmailBox = false"
            >
              {{ translation.backToResults }}
            </div>
          </div>
          <div class="backToResults mb-3 col-10" @click="goToHomePage">
            {{ translation.backToHomePage }}
          </div>
          <div class="row">
            <div class="col-12 mt-2 mb-4 whatsappButton" @click="openWhatsapp">
              <div class="row">
                <div class="col-4">
                  <button class="btn bmd-btn-fab p-0">
                    <i
                      class="fa fa-whatsapp"
                      style="font-size: 34px; color: #46ad46"
                    ></i>
                  </button>
                </div>
                <div class="col-8 mt-1 pr-1" style="padding-top: 0px">
                  {{ translation.share }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="homePageButton col-6" @click="goToHomePage">
              {{ translation.backToHomePage }}
            </div> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating,
  },
  data() {
    return {
      comment: "",
      afterStars: false,
      starSize: Number(40),
      rating: 0,
      translation: {
        share: "Partager",
        send: "Envoyer",
        skip: "Non merci.",
        results: "Résultats",
        first: "ère",
        afterFirst: "ème",
        errors: "Erreurs",
        gameTime: "Temps de jeu",
        totalTime: "Temps total",
        leaveEmail:
          "Vous pouvez nous laisser votre email, on vous tiendra au courant chaque fois qu'un nouveau parcours ouvrira!",
        backToHomePage: "Page d'accueil",
        backToResults: "Retour aux résultats",
      },
      loading: false,
      showEmailBox: false,
      email: "",
      eventurl: "",
      message:
        "Seul, en famille ou avec des amis, découvrez Genève et ses environs de manière ludique et didactique! Découvrez tous les parcours de jeu de pistes disponibles sur le site: ouchui.ch ",
    };
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.$store.dispatch("getResults", localStorage.getItem("navigationName"));
  },
  computed: {
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    allResults() {
      return this.$store.getters.results;
    },
    user() {
      return this.$store.getters.user;
    },
    // loading () {
    // return this.$store.getters.loading
    // },
    timeNow() {
      return setInterval((_) => {
        let d = new Date();
        this.time = d.toLocaleTimeString("he");
      }, 1000);
    },
  },
  methods: {
    sendComment() {
      this.afterStars = true;
      var comment = {
        navigationName: localStorage.getItem("navigationName"),
        rating: this.rating,
        comment: this.comment,
        time: new Date().toString(),
        user: localStorage.getItem("myNameOuchui"),
      };
      this.$store.dispatch("sendComment", comment);
      console.log("comment, commen", comment);
    },
    translate(lang) {
      if (lang == "fr") {
        this.translation = {
          share: "Partager",

          send: "Envoyer",
          skip: "Non merci.",
          results: "Résultats",
          first: "ère",
          afterFirst: "ème",
          errors: "Erreurs",
          gameTime: "Temps de jeu",
          totalTime: "Temps total",
          leaveEmail:
            "Vous pouvez nous laisser votre email, on vous tiendra au courant chaque fois qu'un nouveau parcours ouvrira!",
          backToHomePage: "Page d'accueil",
          backToResults: "Retour aux résultats",
        };
      } else {
        this.translation = {
          share: "Share",

          send: "Envoyer",
          skip: "No thanks.",
          results: "Results",
          first: "st",
          afterFirst: "th",
          errors: "Errors",
          gameTime: "Game time",
          totalTime: "Total time",
          leaveEmail:
            "If you enjoyed the game, you can leave us your email, we will keep you posted whenever a new course opens!",
          backToHomePage: "Homepage",
          backToResults: "Back to results",
        };
      }
    },
    openWhatsapp() {
      window.open(`whatsapp://send?text=` + this.message);
    },
    //
    //
    goToHomePage() {
      localStorage.clear();

      this.$router.push("/");
    },
    sendEmail() {
      if (this.email === "") {
        alert(
          "Elle est bonne celle là! On n'est pas encore assez bon pour deviner votre adresse e-mail... :-) "
        );
      } else {
        if (this.email.includes("@")) {
          this.$store.dispatch("sendEmail", {
            navigationName: localStorage.getItem("navigationName"),
            email: this.email,
          });
          this.email = "";
          alert("Votre addresse e-mail nous a été envoyée!");

          console.log("send email");
        } else {
          alert("Votre addresse e-mail à plutôt l'air d'être incorrecte...");
        }
      }
    },
  },
};
</script>

<style scoped>
.whatsappButton {
  border-radius: 36px;
  padding-top: 2px;
  background: white;
  height: 41px;
  margin-top: 2px;
  color: green;
}
.homePageButton {
  border-radius: 36px;
  height: 41px;
  width: 100%;
  font-size: 16px;
  background: white;
  color: green;
  padding-top: 8px;
  margin-top: 24px;
  margin-left: 7px;
  text-align: center;
}
.backToResults {
  border-radius: 36px;
  height: 41px;
  width: 100%;
  font-size: 21px;
  color: white;
  padding-top: 8px;
  margin-top: 8px;
  text-align: center;
}
.center {
  display: block;
  margin: 0px auto;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.green {
  background-color: rgb(119, 190, 87);
  border: 2px white solid;
}
.myModal {
  min-height: 200px;
  overflow: auto;
  width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  color: white;
}
.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
span {
  position: fixed;
  top: 55px;
  font-size: 37px;
  color: #0070b0;
  left: 7px;
}
#quitResults {
  position: absolute;
  top: 0px;
  left: 0px;
}
.resultName {
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
}
.resultDiv {
  border-bottom: solid 1px grey;
  font-size: 15px;
  width: 100%;
  padding: 5px;
  background: white;
  border-radius: 12px;
  margin: 5px auto;
}
.colorDiv {
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 25px;
  padding-top: 30px;
}
.container {
  /* margin-top: 16px; */
  /* background-color: rgba(248, 242, 242, 0.2); */
  font-size: 20px;
  /* height: calc(100vh - 82px); */
}
.colorBtn {
  border-radius: 36px;
  color: #008726;
  margin: 0px auto;
  margin-top: 20px;
  background-color: white;
  padding-top: 8px;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.rtl {
  direction: rtl;
}
.results {
  font-size: 25px;
  text-align: right;
  color: black;
  /* text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0); */
}
</style>
