<template >
  <div id="questionDiv" v-if="showQuestion">
    <div class="my-2 px-1">
      <!-- <p class="question px-3 text-center">
        {{ questionsShuffled[$parent.questionNumber].question }}
      </p> -->
      <p class="question px-3 text-center">
        {{ questionsArray[firstQuestion].question }}
      </p>
    </div>
    <div class="mb-5 pb-5" style="min-width: 320px">
      <div :key="answer.id" v-for="answer in getAnswerArray">
        <button
          class="btn btn-primary btn-lg btn3d fullWidth"
          style="
            background-color: white;
            margin-bottom: 4vh;
            min-height: 60px;
            color: #0070b0;
            font-size: 18px;
          "
          @click="checkAnswer(answer, $event)"
        >
          <!-- class="fullWidth colorBtn btn font-weight-bold mb-3" -->

          {{ answer }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      //firstQuestion: 1,
      firstQuestion: 0,
      audio: new Audio(this.soundToPlay),
      soundToPlay: "",
      questionNumber: 0,
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
      // errorNumber: 0,
      showLastDialog: false,
      endTime: "",
    };
  },
  mounted() {
    if (localStorage.getItem("firstQuestion")) {
      //this.firstQuestion = localStorage.getItem("firstQuestion");
      this.firstQuestion = JSON.parse(localStorage.getItem("firstQuestion"));
    }
    localStorage.setItem("questionNumber", this.$parent.questionNumber);

    // this.$parent.showMultipleMatch = false;
    // this.$parent.showPuzzle = false;
  },
  computed: {
    showQuestion() {
      if (this.$parent.showQuestion == true) {
        return true;
      } else {
        return false;
      }
    },
    numberOfQUestionPerPoint() {
      return Math.floor(
        this.$store.getters.questionsShuffled.length /
          (this.$store.getters.points.length - 1)
      );
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
    points() {
      return this.$store.getters.points;
    },
    // questionsShuffled() {
    //   if (localStorage.getItem("questionsShuffled")) {
    //     // console.log(
    //     //   "[questionsShuffled] this.$store.getters.questionsShuffled",
    //     //   JSON.parse(localStorage.getItem("questionsShuffled"))
    //     // );
    //     return JSON.parse(localStorage.getItem("questionsShuffled"));
    //   } else {
    //     // console.log(
    //     //   "[questionsShuffled] this.$store.getters.questionsShuffled",
    //     //   this.$store.getters.questionsShuffled
    //     // );
    //     return this.$store.getters.questionsShuffled;
    //   }
    // },
    questionsArray() {
      var parentPointNum = this.$parent.pointNum;
      //  console.log("parentPointNum", parentPointNum);

      var pointArrayOrder = this.$parent.pointsArrayOrder;
      var arrayOfQuestionForSpecificPoint = [];
      var fullArray;
      if (localStorage.getItem("questionsArray")) {
        fullArray = JSON.parse(localStorage.getItem("questionsArray"));
        // console.log("fullArray", fullArray);
      } else {
        fullArray = this.$store.getters.questionsArray;
        // console.log("fullArray", fullArray);
      }
      //// TRYING TO GET THE QUESTIONARRAY INSTEAD OF THE QUESTIONSHUFFLED
      // Si les questions ne sont pas liées aux points et doivent garder le même ordre peux importe l'ordre des points
      if (this.$store.getters.gameParameters.questionsOrder == "sorted") {
        fullArray.forEach(function (question) {
          // console.log(
          //   "JSON.stringify(parentPointNum)",
          //   JSON.stringify(parentPointNum)
          // );
          // console.log("question.pointLinked", question.pointLinked);
          if (question.pointLinked == JSON.stringify(parentPointNum)) {
            // console.log(
            //   "JSON.stringify(parentPointNum)",
            //   JSON.stringify(parentPointNum)
            // );
            // console.log("question.pointLinked", question.pointLinked);

            arrayOfQuestionForSpecificPoint.push(question);
          }
        });
        console.log(
          "arrayOfQuestionForSpecificPoint",
          arrayOfQuestionForSpecificPoint
        );
        return arrayOfQuestionForSpecificPoint;
        /// Si les questions sont liées aux points spécifique
      } else if (
        this.$store.getters.gameParameters.questionsOrder ==
        "linkedToPointNumber"
      ) {
        fullArray.forEach(function (question) {
          if (
            question.pointLinked ==
            JSON.stringify(pointArrayOrder[parentPointNum])
          ) {
            arrayOfQuestionForSpecificPoint.push(question);
          }
        });
        // console.log(
        //   "arrayOfQuestionForSpecificPoint",
        //   arrayOfQuestionForSpecificPoint
        // );
        return arrayOfQuestionForSpecificPoint;

        ///************fini avec linkedToPointNumber */
      } else {
        if (localStorage.getItem("questionsArray")) {
          return JSON.parse(localStorage.getItem("questionsArray"));
        } else {
          return this.$store.getters.questionsShuffled;
        }
      }
    },
    getAnswerArray() {
      let answerArray;
      // answerArray = [
      //   this.questionsShuffled[this.$parent.questionNumber].wrongAnswer1,
      //   this.questionsShuffled[this.$parent.questionNumber].wrongAnswer2,
      //   this.questionsShuffled[this.$parent.questionNumber].wrongAnswer3,
      //   this.questionsShuffled[this.$parent.questionNumber].correctAnswer,
      // ];
      answerArray = [
        this.questionsArray[this.firstQuestion].wrongAnswer1,
        this.questionsArray[this.firstQuestion].wrongAnswer2,
        this.questionsArray[this.firstQuestion].wrongAnswer3,
        this.questionsArray[this.firstQuestion].correctAnswer,
      ];
      let currentIndex = answerArray.length,
        temporaryValue,
        randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = answerArray[currentIndex];
        answerArray[currentIndex] = answerArray[randomIndex];
        answerArray[randomIndex] = temporaryValue;
      }
      return answerArray;
    },
  },

  methods: {
    checkAnswer(answer, event) {
      this.$parent.showMultipleMatch = false;
      this.$parent.showPuzzle = false;
      if (document.getElementById("questionDiv")) {
        document.getElementById("questionDiv").classList.remove("fadeIn");
      } else {
        console.log("[checkAnswer] No questionDiv");
      }
      //////////////////  CORRECT ANSWER ///////////////////////////

      // if (
      //   answer ===
      //   this.questionsShuffled[this.$parent.questionNumber].correctAnswer
      // )
      if (answer === this.questionsArray[this.firstQuestion].correctAnswer) {
        event.target.classList.add("bg-green");
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        this.$parent.showStarUp("5+");
        this.$parent.showCorrectAnswerIcon = true;
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        if (
          Object.values(this.points)[
            this.pointsArrayOrder[this.$parent.pointNum] - 1
          ].pointNumber == this.$parent.gameParameters.lastPoint
        ) {
          //   console.log("JE VAIS AU NEXTPOINT ******************");
          // this.goToNextPoint();
        }
        setTimeout(() => {
          this.firstQuestion++;
          //this.$parent.questionNumber++;
          this.$parent.showCorrectAnswerIcon = false;
          localStorage.setItem("questionNumber", this.$parent.questionNumber);
          //   if (this.$parent.questionNumber != this.questionsShuffled.length) {
          this.checkForNextQuestion();
          this.getRidOfButtonColors();
          // }
        }, 3000);
        // }
      } else {
        //////////////////  WRONG ANSWER //////////////////////////
        this.$parent.$refs.pointsCounting.classList.add("redColor");
        this.$parent.showWongAnswerIcon = true;

        event.target.classList.add("bg-red");
        audio = new Audio(this.wongAnswerSound);
        audio.play();
        this.$parent.errorNumber--;

        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        setTimeout(() => {
          this.$parent.$refs.pointsCounting.classList.remove("redColor");
          this.$parent.showWongAnswerIcon = false;
        }, 1000);
      }
    },
    checkForNextQuestion() {
      // ***********************************************ADDED THIS JUST FOR THE RANDOM QUESTIONS
      if (this.$parent.gameParameters.randomQuestions === true) {
        if (this.firstQuestion < this.numberOfQUestionPerPoint + 1) {
          // this.$parent.questionNumber++;
          localStorage.setItem("questionNumber", this.$parent.questionNumber);
          this.firstQuestion++;
          localStorage.setItem("firstQuestion", this.firstQuestion);
        } else {
          localStorage.setItem("questionNumber", this.$parent.questionNumber);
          this.firstQuestion = 1;
          localStorage.setItem("firstQuestion", 1);
          this.goToNextPoint();
          // }
        }
      } else {
        if (
          this.$store.getters.gameParameters.questionsOrder ==
            "linkedToPointNumber" ||
          this.$store.getters.gameParameters.questionsOrder == "sorted"
        ) {
          if (this.firstQuestion < this.questionsArray.length) {
            localStorage.setItem("firstQuestion", this.firstQuestion);
          } else {
            localStorage.setItem("questionNumber", this.$parent.questionNumber);
            this.firstQuestion = 0;
            localStorage.setItem("firstQuestion", 0);
            this.goToNextPoint();
          }
        } else {
          if (
            Object.values(this.points)[
              this.pointsArrayOrder[this.$parent.pointNum] - 1
            ].pointNumber ==
            this.questionsArray[this.$parent.questionNumber].pointLinked
          ) {
            // this.$parent.questionNumber++;
            // localStorage.setItem("questionNumber", this.$parent.questionNumber);
            this.firstQuestion++;
            localStorage.setItem("firstQuestion", this.firstQuestion);
          } else {
            localStorage.setItem("questionNumber", this.$parent.questionNumber);

            this.firstQuestion = 1;
            localStorage.setItem("firstQuestion", 1);
            this.goToNextPoint();

            // }
          }
        }
      }
    },
    // checkForNextQuestion() {
    //   // ***********************************************ADDED THIS JUST FOR THE RANDOM QUESTIONS
    //   if (this.$parent.gameParameters.randomQuestions === true) {
    //     if (this.firstQuestion < this.numberOfQUestionPerPoint + 1) {
    //       // this.$parent.questionNumber++;
    //       localStorage.setItem("questionNumber", this.$parent.questionNumber);
    //       this.firstQuestion++;
    //       localStorage.setItem("firstQuestion", this.firstQuestion);
    //     } else {
    //       localStorage.setItem("questionNumber", this.$parent.questionNumber);

    //       // if (this.showMainScreen) {
    //       this.$store.dispatch("addEvolutionToDB", {
    //         pointNumber: this.$parent.pointNum,
    //         userKey: this.$parent.userKey,
    //         navigationName: this.$parent.gameParameters.navigationName,
    //       });
    //       this.firstQuestion = 1;
    //       localStorage.setItem("firstQuestion", 1);
    //       this.goToNextPoint();
    //       // }
    //     }
    //   } else {
    //     // ******************************************************************************************
    //       if (
    //       Object.values(this.points)[
    //         this.pointsArrayOrder[this.$parent.pointNum] - 1
    //       ].pointNumber ==
    //       this.questionsShuffled[this.$parent.questionNumber].pointLinked
    //     ) {
    //       // this.$parent.questionNumber++;
    //       // localStorage.setItem("questionNumber", this.$parent.questionNumber);
    //       this.firstQuestion++;
    //       localStorage.setItem("firstQuestion", this.firstQuestion);
    //     } else {
    //       localStorage.setItem("questionNumber", this.$parent.questionNumber);
    //       // if (this.showMainScreen) {
    //       this.$store.dispatch("addEvolutionToDB", {
    //         pointNumber: this.$parent.pointNum,
    //         userKey: this.$parent.userKey,
    //         navigationName: this.$parent.gameParameters.navigationName,
    //       });
    //       this.firstQuestion = 1;
    //       localStorage.setItem("firstQuestion", 1);
    //       this.goToNextPoint();

    //       // }
    //     }
    //   }
    // },
    getRidOfButtonColors() {
      const redButton = document.querySelectorAll("button.bg-red");
      redButton.forEach((button) => {
        button.classList.remove("bg-red");
      });
      const greenButton = document.querySelectorAll("button.bg-green");
      greenButton.forEach((button) => {
        button.classList.remove("bg-green");
      });
    },
    goToNextPoint() {
      this.$parent.showPointInformation = false;
      localStorage.setItem(
        "showPointInformation",
        this.$parent.showPointInformation
      );
      this.$parent.showPickedItem = false;
      localStorage.setItem("showPickedItem", this.$parent.showPickedItem);
      this.$parent.showMainScreen = true;
      localStorage.setItem("showMainScreen", this.$parent.showMainScreen);
      this.$parent.nextPointNumber();
      // localStorage.setItem("showMainScreen", this.$parent.showMainScree);
    },
  },
};
</script>

<style scoped>
.bg-green {
  background: #99bd39 !important;
  box-shadow: 0 0 0 1px #687f27 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 3px 0 0 #687f27, 0 8px 8px 1px rgb(0 0 0 / 50%) !important;
  color: white !important;
}
.bg-red {
  background: #ff7b7d !important;
  box-shadow: 0 0 0 1px #b6494d inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 3px 0 0 #b6494d, 0 8px 8px 1px rgb(0 0 0 / 50%) !important;
  color: white !important;
}
.question {
  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 21px;
  font-weight: bold;
  color: white;
  padding: 21px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #b1b2b9 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #b1b2b9, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #b1b2b9 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
.fullWidth {
  width: 85%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
}
</style>



