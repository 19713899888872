<template>
  <div v-if="showSingleWord">
    <div class="text-content">
      <p>{{ singleWordDescription }}</p>
      <img :src="singleWordImage" alt="Puzzle Image" v-if="singleWordImage" />
    </div>
    <div class="solution-dashes">
      <span v-for="dash in solutionDashes" :key="dash.id" class="dash">{{
        dash.char
      }}</span>
    </div>
    {{ initializeDashes }}
    <input
      type="text"
      v-model="userInput"
      @input="checkInput"
      placeholder="Type here..."
    />
    <div v-if="completed" class="modal">
      <img src="../../static/img/like.gif" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      singleWordDescription: "",
      singleWordImage: "",
      solutionWord: "shalom",
      userInput: "",
      completed: false,
      solutionDashes: [],
      showSingleWord: true,
    };
  },
  computed: {
    // showSingleWord() {
    //   return this.$parent.currentMode === "textPuzzle";
    // },
    // currentPoint() {
    //   const point = Object.values(this.$parent.points)[
    //     this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
    //   ];
    //   this.singleWordDescription = point.singleWordDescription;
    //   this.singleWordImage = point.singleWordImage;
    //   this.solutionWord = point.solutionWord;
    //   this.initializeDashes();
    // },
  },
  methods: {
    initializeDashes() {
      this.solutionDashes = Array.from(this.solutionWord, () => ({
        char: "_",
        id: Math.random(),
      }));
    },
    checkInput() {
      let updatedDashes = [];
      for (let i = 0; i < this.solutionWord.length; i++) {
        updatedDashes.push({
          char: this.userInput[i] || "_",
          id: this.solutionDashes[i].id,
        });
      }
      this.solutionDashes = updatedDashes;

      if (this.userInput.toLowerCase() === this.solutionWord.toLowerCase()) {
        this.completed = true;
        this.showSuccessAnimation();
      }
    },
    showSuccessAnimation() {
      setTimeout(() => {
        this.completed = false;
        this.$parent.nextPoint();
      }, 2000);
    },
  },
};
</script>

<style scoped>
.text-content p {
  font-size: 20px;
  text-align: center;
}
.text-content img {
  display: block;
  margin: 10px auto;
  max-height: 200px;
}
.solution-dashes {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.dash {
  font-size: 24px;
  margin: 0 5px;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal img {
  height: 100px;
}
</style>
