<template >
  <div style="font-family: system-ui">
    <div v-if="loading" class="over container">
      <div class="row" style="height: 45vh">
        <div class="col-12"></div>
      </div>
      <div class="row">
        <div class="spinner-border text-primary center" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else class="over">
      <CookieConsent class="cookieConsent p-4" />

      <div class="over container d-flex">
        <div class="myModal p-2 container row">
          <div
            class="row align-items-center justify-content-center align-self-center"
            style="height: 40%"
          >
            <!-- <div class="logoRN">
              <img src="../../static/img/logorn.png" alt="" />
            </div> -->
            <div
              id="titleMainPage"
              class="row text-center justify-content-center center"
              style="color: rgb(114 203 170)"
            >
              {{ lang }} {{ title }}
            </div>
            <div id="stampGame" class="text-center">
              {{ translation.stamp }}
            </div>
          </div>

          <div class="row align-items-center" style="height: 15%">
            <div class="col-12">
              <div class="py-3">
                <input
                  name="userName"
                  label="Pseudo"
                  id="userName"
                  v-model="userName"
                  required
                  placeholder="Pseudo"
                  class="center fullWidth text-center"
                  style="
                    color: black;
                    border: solid 2px rgb(84, 163, 49);
                    height: 50px;
                  "
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center" style="height: 20%">
            <div class="col-12">
              <div
                class="p-2 h5 text-center blur startPoint mb-3"
                style="max-width: 360px; color: white; border: 2px white solid"
              >
                <div class="font-weight-bold">
                  {{ translation.beware }}
                </div>
                <div class="mt-2 blur">
                  {{ translation.startPoint }}
                </div>
              </div>
              <div
                class="col-12"
                style="display: flex; justify-content: center"
              >
                <button
                  class="btn btn-primary btn-lg btn3d fullWidth font-weight-bold"
                  style="
                    background-color: rgb(117 185 172);
                    margin-bottom: 45px;
                    height: 60px;
                  "
                  @click="checkIfName()"
                >
                  {{ translation.letsGoButton }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="confirmName == true" class="over">
        <div class="p-3 myModal2 container d-flex">
          <div class="row">
            <div
              class="px-2 mt-3 col-12 text-center"
              style="font-family: 'noot', cursive; font-size: 33px"
            >
              {{ translation.wellDone }}
              {{ userName }}!
            </div>
            <div class="px-2 mt-3 col-12 text-left">
              {{ translation.beforeStarting }}
            </div>
            <div
              class="colorBtn btn font-weight-bold position-relative col-5 ml-2"
              @click="onSignin"
            >
              {{ translation.ok }}
            </div>
            <div
              class="position-relative text-center col-5"
              style="margin-top: 37px"
              @click="startGame"
            >
              {{ translation.skip }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="showAlertNoName === true" class="over">
        <div
          class="p-3 myModal2"
          style="
            background-color: rgb(243 72 72);
            color: white;
            border: 4px solid yellow;
          "
          @click="showAlertNoName = false"
        >
          <div class="mt-3 text-center">
            <div class="mt-3" style="font-size: 40px">Oups...</div>
            <div class="mt-3 text-center">{{ translation.noPassword }}</div>
          </div>
          <div class="font-weight-bold mt-5 mb-2 h4 text-center">
            {{ translation.gotIt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CookieConsent from "./CookieConsent.vue";

export default {
  components: {
    CookieConsent,
  },
  data() {
    return {
      translation: {
        stamp: "Jeu de piste",
        beware: "Attention!",
        startPoint: "Le jeu commence à la place de Meyrin Village",
        letsGoButton: "Commencer",
        beforeStarting:
          "Avant de se lancer, vous pouvez rapidement recevoir quelques explications sur le fonctionnement de l'application.",
        ok: "Ca marche!",
        skip: "Non merci",
        noPassword:
          "Il semble que vous ayez oublié d'entrer un pseudonyme... Nous en avons besoin pour pouvoir vous indiquer votre position dans le classement à la fin du jeu.",
        gotIt: "Ok, j'ai compris",
      },
      confirmName: false,
      navigationName: "escaladeKids",
      showAlertNoName: false,
      groupColorEnglish: "",
      userName: "",
      title: "L'Escalade",
      userKey: "",
      language: "Français",
    };
  },
  mounted() {
    if (localStorage.getItem("navigationName")) {
      if (
        localStorage.getItem("navigationName") != this.navigationName
        // ||
        // localStorage.getItem("startTimeOuchui") - Date.now() <
        //   -60000 * 1 * 60 * 5 - 600000
      ) {
        // localStorage.clear();
        Object.keys(localStorage).forEach((key) => {
          if (!key.includes("details")) delete localStorage[key];
        });
        console.log(Object.keys(localStorage));
      }
    }
    if (localStorage.getItem("pointNumber")) {
      this.userName = localStorage.getItem("myNameOuchui");
      this.groupColorEnglish = localStorage.getItem("groupColorEnglish");
      // this.$store.dispatch("fetchSentence", {
      //   navigationName: this.navigationName
      // });
      this.userKey = localStorage.getItem("userKey");
      this.$store.dispatch("createNewUser", {
        userName: this.userName,
        navigationName: this.navigationName,
        groupColorEnglish: this.groupColorEnglish,
        pointNumber: localStorage.getItem("pointNumber"),
      });
      this.$store.dispatch("fetchGameDataFull", {
        navigationName: this.navigationName,
        userName: this.userName,
        groupColorEnglish: this.groupColorEnglish,
      });

      let pushStartGame = setInterval(() => {
        if (this.loading == true) {
          console.log("loading");
        } else {
          console.log("[mounted] b4 push play");

          this.$router.push("/play");
          clearInterval(pushStartGame);
        }
      }, 200);
    }
  },
  computed: {
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    switchLangToFrench() {
      this.$store.dispatch("switchLangToFrench");
    },
    switchLangToEnglish() {
      this.$store.dispatch("switchLangToEnglish");
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            wellDone: "Bravo",
            stamp: "Jeu de piste",
            beware: "Attention!",
            startPoint: "Le jeu commence à la place de La Madelaine",
            letsGoButton: "Commencer",
            beforeStarting:
              "Avant de se lancer, vous pouvez rapidement recevoir quelques explications sur le fonctionnement de l'application.",
            ok: "Ca marche!",
            skip: "Non merci",
            noPassword:
              "Il semble que vous ayez oublié d'entrer un pseudonyme... Nous en avons besoin pour pouvoir vous indiquer votre position dans le classement à la fin du jeu.",
            gotIt: "Ok, j'ai compris",
          };
          console.log("french");
          break;
        case "en":
          this.translation = {
            wellDone: "Well done",

            stamp: "Jeu de piste",
            beware: "Attention!",
            startPoint: "The game begins at the Place of La Madelaine",
            letsGoButton: "I'M HERE!",
            beforeStarting:
              "Before getting started, you can learn how the app works.",
            ok: "Sure!",
            skip: "No thanks, skip.",
            noPassword:
              "It looks like you forgot to enter a nickname... It's just so you can see where you ended up in the leaderboard.",
            gotIt: "Ok, got it",
          };
          console.log("english");

          // code block
          break;
        case "he":
          this.translation = {
            wellDone: "יופי",

            stamp: "משחק ניווט",
            beware: " שימו לב !",
            startPoint: "המשחק מתחיל בנמל הדייגים",
            letsGoButton: "להתחיל",
            beforeStarting: "לפני שמתחילים, תראו איך זה עובד",
            ok: "בטח!",
            skip: "לא תודה",
            noPassword: "נראה ששכת להכניס את שמך",
            gotIt: "טוב בסדר",
          };
          console.log("english");

          // code block
          break;
        default:
        // code block
      }
    },
    startGame() {
      console.log("[startGame] b4 push play");
      if (localStorage.getItem("details")) {
        console.log("details from local storage");
        this.details = JSON.parse(localStorage.getItem("details"));
        // console.log("this.details", this.details);
        if (this.details.status == "COMPLETED") {
          // this.$router.push("/startGame");
          this.$router.push("/play");
        }
      } else {
        //this.$router.push("/paymentPage");
        this.$router.push("/play");
      }

      // this.$router.push("/play");
    },
    checkIfName() {
      if (this.userName === "") {
        this.showAlertNoName = true;
      } else {
        localStorage.setItem("myNameOuchui", this.userName);
        localStorage.setItem("navigationName", this.navigationName);
        localStorage.setItem("groupColorEnglish", this.groupColorEnglish);
        setTimeout(() => {
          console.log("this.confirmName", this.confirmName);

          this.confirmName = true;
          this.$store.dispatch("createNewUser", {
            userName: this.userName,
            navigationName: this.navigationName,
            groupColorEnglish: this.groupColorEnglish,
            evolution: 1,
          });
          console.log("b4 fetchGameDataFull");
          this.$store.dispatch("fetchGameDataFull", {
            navigationName: this.navigationName,
            userName: this.userName,
            groupColorEnglish: this.groupColorEnglish,
          });
          console.log("this.confirmName", this.confirmName);
        }, 1000);
      }
    },
    onSignin() {
      this.$router.push("/tutoPage");
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>

<style scoped>
.centerFlex {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.startPoint {
  max-width: 360px;
  color: white;
  border: 2px white solid;
  margin: 0 auto;
}
.blur {
  backdrop-filter: blur(10px);
}
.logoRN {
  position: absolute;
  top: 16px;
  right: 16px;
}

.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
}
#stampGame {
  color: rgb(114 203 170);
  font-weight: 700;
  border: 0.25rem solid rgb(114 203 170);
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  -webkit-mask-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png);
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: hard-light;
  position: absolute;
  top: 25%;
  -webkit-transform: rotate(20deg);
  transform: rotate(-10deg);
  z-index: 3;
  font-size: 23px;
  width: 225px;
  -webkit-animation-name: stamp;
  animation-name: stamp;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  margin-left: 81px;
}
.myModal {
  display: block;
  margin: 0px auto;
  background: transparent;
}
#titleMainPage {
  position: relative;
  font-size: 76px;
  color: #03ce39;
  background: 50% 100% / 50% 50% no-repeat
    radial-gradient(
      ellipse at bottom,
      rgb(111, 235, 28),
      transparent,
      transparent
    );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: reveal 1000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}

.myModal2 {
  height: auto;
  width: 320px;
  border-radius: 10px;
  /* display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px); */
  background-color: white;
  color: #0070b0;
  /* border: solid 8px #d7fb0f; */
}
.cookieConsent {
  position: relative;
  font-size: 20px;
  height: auto;
  width: 320px;
  border-radius: 10px;
  display: -webkit-box;
  /* margin-left: calc(50vw - 160px); */
  display: -ms-flexbox;
  display: flex;
  margin: 0px auto;
  background-color: white;
  color: #0070b0;
  /* border: solid 8px #d7fb0f; */
  z-index: 10;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.over {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 100vh;
  background: url("../../static/img/escalade.png") no-repeat center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
}
.colorBtn {
  border-radius: 36px;
  color: white;
  margin: 0px auto;
  margin-top: 30px;
  background-color: #0070b0;
  padding-top: 8px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #447c70, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
.center {
  display: block;
  margin: 0px auto;
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px rgb(31, 250, 2);
  }
}
@keyframes stamp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  94% {
    opacity: 0.5;
    transform-origin: 50% 50%;
    transform: scale(4);
    transition: all 0.1s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(-20deg) scale(1);
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    background-position: -500%;
    background-size: 80%;
  }
  20% {
    opacity: 1;
    background-position: 400%;
    background-size: 80%;
  }
  40% {
    background-position: -200%;
    background-size: 80%;
  }
  60% {
    background-position: 0%;
    background-size: 80%;
  }
  80% {
    background-position: 0%;
    background-size: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }

  30%,
  70% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  93% {
    opacity: 0.5;
  }
  95% {
    opacity: 1;
    transform: scale(1.05);
  }
  98% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: 120vw;
  }
  72% {
    margin-left: 120vw;
  }

  98% {
    margin-left: 3%;
  }

  to {
    margin-left: 0%;
  }
}
</style>



