<template >
  <div>
    <div v-if="loading" class="over container center">
      <!-- <div class="row" style="height: 45vh">
        <div class="col-12"></div>
      </div> -->
      <div class="row">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="over container d-flex">
        <div class="myModal p-2 container row">
          <div
            style="position: absolute; top: 10px; left: 10px"
            @click="openMain"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-arrow-left-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              style="color: white; font-size: 45px"
            >
              <path
                fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </div>
          <div
            class="row align-items-center justify-content-center align-self-center"
            style="height: 40%"
          >
            <div
              id="titleMainPage"
              primary-title
              class="row text-center justify-content-center center"
            >
              {{ title }}
            </div>
            <div id="stampGame" class="text-center">
              {{ lang }}{{ translation.stamp }}
            </div>
          </div>

          <!-- <div class="row align-items-center" style="height: 15%">
            <div class="col-12">
              <div class="dropdown">
                <button
                  class="dropdown-toggle center mb-50px"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :language="language"
                  style="color:black; padding: 0px 40px; height: 45px; border: 0px; border-radius: 16px; min-width: 174px"
                >{{language}}</button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    @click="language = 'Français', switchLangToFrench() "
                  >Français</a>
                  <a
                    class="dropdown-item"
                    @click="language = 'English', switchLangToEnglish()"
                  >English</a>
                </div>
              </div>
            </div>
          </div>-->

          <div class="row align-items-center" style="height: 25%">
            <div class="col-12">
              <div class="py-3">
                <input
                  v-if="language === 'Français'"
                  name="userName"
                  label="Pseudo"
                  id="userName"
                  v-model="userName"
                  required
                  placeholder="Ton pseudo"
                  class="center fullWidth text-center"
                  style="color: black; border: solid 2px rgb(84, 163, 49)"
                />
                <input
                  v-else
                  name="userName"
                  label="Pseudo"
                  id="userName"
                  v-model="userName"
                  required
                  placeholder="Your Nickname"
                  class="center fullWidth text-center"
                  style="color: black; border: solid 2px rgb(84, 163, 49)"
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center" style="height: 25%">
            <div class="col-12">
              <div class="p-2 h6" style="max-width: 360px; color: white">
                <strong>{{ translation.beware }}</strong>
                {{ translation.startPoint }}
              </div>
              <button
                class="fullWidth btn font-weight-bold"
                style="
                  background-color: rgb(84, 163, 49);
                  width: 110px;
                  height: 110px;
                  border: solid 2px white;
                  border-radius: 82px;
                "
                @click="checkIfName()"
              >
                {{ translation.letsGoButton }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="confirmName === true" class="over center">
        <div class="p-3 myModal2 container d-flex">
          <div class="row">
            <div
              class="px-2 mt-3 col-12 text-center"
              style="font-family: 'Slackey', cursive; font-size: 26px"
            >
              Hello {{ userName }} !
            </div>
            <div class="px-2 mt-3 col-12">{{ translation.beforeStarting }}</div>
            <div
              class="position-relative text-center col-5 ml-2 pr-0"
              style="margin-top: 35px"
              @click="startGame"
            >
              {{ translation.skip }}
            </div>
            <div
              class="colorBtn btn font-weight-bold position-relative col-5"
              @click="onSignin"
            >
              {{ translation.ok }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="showAlertNoName === true" class="over center">
        <div
          class="p-3 myModal2"
          style="background-color: rgb(84, 163, 49); color: white"
          @click="showAlertNoName = false"
        >
          <div class="mt-3 text-center">
            <div
              class="mt-3"
              style="font-family: 'Slackey', cursive; font-size: 40px"
            >
              Hummm!
            </div>
            <div class="mt-3 text-left">{{ translation.noPassword }}</div>
          </div>
          <div class="font-weight-bold mt-5 mb-2 h4 text-center">
            {{ translation.gotIt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      translation: {
        stamp: "Jeu de piste",
        beware: "Attention !",
        startPoint: "Le jeu commence dans le parc Moynier, Rue de Lausanne 120",
        letsGoButton: "ON Y EST !",
        beforeStarting:
          "Voulez-vous quelques explications sur le fonctionnement de l’application avant de vous lancer ?",
        ok: "Oui !",
        skip: "Non merci.",
        noPassword:
          "Il semblerait que vous avez oublié d'entrer un pseudo... C'est juste pour que vous puissiez voir à quelle place vous avez fini au classement.",
        gotIt: "Ok, j'ai compris",
      },
      confirmName: false,
      navigationName: "nations",
      showAlertNoName: false,
      groupColorEnglish: "",
      userName: "",
      title: "NATIONS",
      userKey: "",
      language: "Français",
    };
  },
  mounted() {
    if (localStorage.getItem("navigationName")) {
      if (
        localStorage.getItem("navigationName") != this.navigationName ||
        localStorage.getItem("startTimeOuchui") - Date.now() > 24 * 60 * 60
      ) {
        localStorage.clear();
      }
    }
    if (localStorage.getItem("questionNumber")) {
      this.userName = localStorage.getItem("myNameOuchui");
      this.groupColorEnglish = localStorage.getItem("groupColorEnglish");
      this.userKey = localStorage.getItem("userKey");
      this.$store.dispatch("createNewUser", {
        userName: this.userName,
        navigationName: this.navigationName,
        groupColorEnglish: this.groupColorEnglish,
        pointNumber: localStorage.getItem("pointNumberOuchui"),
      });
      this.$store.dispatch("fetchGameData", {
        navigationName: this.navigationName,
        userName: this.userName,
        groupColorEnglish: this.groupColorEnglish,
      });
      let pushStartGame = setInterval(() => {
        if (this.loading == true) {
          console.log("loading");
        } else {
          this.$router.push("/startGame");
          clearInterval(pushStartGame);
        }
      }, 200);
      // setTimeout(() => {
      //   this.$router.push("/startGame");
      // }, 3000);
    }
  },
  computed: {
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    switchLangToFrench() {
      this.$store.dispatch("switchLangToFrench");
    },
    switchLangToEnglish() {
      this.$store.dispatch("switchLangToEnglish");
    },
    translate(lang) {
      if (lang == "fr") {
        this.translation = {
          stamp: "Jeu de piste",
          beware: "Attention !",
          startPoint:
            "Le jeu commence dans le parc de l'Ariana, Avenue de la Paix 10",
          letsGoButton: "ON Y EST !",
          beforeStarting:
            "Voulez-vous quelques explications sur le fonctionnement de l’application avant de vous lancer ?",
          ok: "Oui !",
          skip: "Non merci.",
          noPassword:
            "Il semblerait que vous avez oublié d'entrer un pseudo... C'est juste pour que vous puissiez voir à quelle place vous avez fini au classement.",
          gotIt: "Ok, j'ai compris",
        };
      } else {
        this.translation = {
          stamp: "Jeu de piste",
          beware: "Attention !",
          startPoint: "The game begins in parc Ariana, Avenue de la Paix 10",
          letsGoButton: "I'M HERE!",
          beforeStarting:
            "Before getting started, you can learn how the app works.",
          ok: "Sure!",
          skip: "No thanks, skip.",
          noPassword:
            "It looks like you forgot to enter a nickname... It's just so you can see where you ended up in the leaderboard.",
          gotIt: "Ok, got it",
        };
      }
    },
    openMain() {
      this.$router.push("/");
    },
    startGame() {
      this.$router.push("/startGame");
    },
    checkIfName() {
      if (this.userName === "") {
        this.showAlertNoName = true;
      } else {
        localStorage.setItem("myNameOuchui", this.userName);
        localStorage.setItem("navigationName", this.navigationName);
        localStorage.setItem("groupColorEnglish", this.groupColorEnglish);
        this.confirmName = true;
        this.$store.dispatch("createNewUser", {
          userName: this.userName,
          navigationName: this.navigationName,
          groupColorEnglish: this.groupColorEnglish,
        });
        this.$store.dispatch("fetchGameData", {
          navigationName: this.navigationName,
          userName: this.userName,
          groupColorEnglish: this.groupColorEnglish,
        });
      }
    },
    onSignin() {
      this.$router.push("/welcomePage");
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>

<style scoped>
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
  border-radius: 36px;
  height: 56px;
  color: white;
}
#stampGame {
  color: #a4daa4;
  font-weight: 700;
  border: 0.25rem solid #a4daa4;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  -webkit-mask-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png);
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: hard-light;
  position: absolute;
  top: 25%;
  -webkit-transform: rotate(20deg);
  transform: rotate(-10deg);
  z-index: 3;
  font-size: 23px;
  width: 225px;
  -webkit-animation-name: stamp;
  animation-name: stamp;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  margin-left: 81px;
}
.myModal {
  display: block;
  margin: 0px auto;
  background: transparent;
}
#titleMainPage {
  position: relative;
  font-size: 70px;
  color: white;
  background: 50% 100% / 50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: reveal 1000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}

.main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 52px;
  position: relative;
}
.moveTitle {
  animation-duration: 3s;
  animation-name: slidein;
}

.title {
  font-family: "Righteous", cursive;
  font-size: 70px;
  color: #cf1110;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
.ltr {
  direction: ltr;
  text-align: left;
}
.myModal2 {
  width: 320px;
  border-radius: 10px;
  display: block;
  /* margin: 0px auto;
  margin-top: calc(50vh - 170px); */
  background-color: rgb(84, 163, 49);
  color: white;
  border: solid 2px white;
  height: auto;
}
.over {
  position: fixed;
  top: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 100vh;
  background: url("../../static/img/GeneveBlackMain.jpg") no-repeat center
    center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
}
.colorBtn {
  border-radius: 36px;
  color: rgb(84, 163, 49);
  margin: 0px auto;
  margin-top: 30px;
  background-color: white;
  padding-top: 8px;
}
.center {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}
@keyframes stamp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  94% {
    opacity: 0.5;
    transform-origin: 50% 50%;
    transform: scale(4);
    transition: all 0.1s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(-20deg) scale(1);
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    background-position: -500%;
    background-size: 80%;
  }
  20% {
    opacity: 1;
    background-position: 400%;
    background-size: 80%;
  }
  40% {
    background-position: -200%;
    background-size: 80%;
  }
  60% {
    background-position: 0%;
    background-size: 80%;
  }
  80% {
    background-position: 0%;
    background-size: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }

  30%,
  70% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  93% {
    opacity: 0.5;
  }
  95% {
    opacity: 1;
    transform: scale(1.05);
  }
  98% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: 120vw;
  }
  72% {
    margin-left: 120vw;
  }

  98% {
    margin-left: 3%;
  }

  to {
    margin-left: 0%;
  }
}
</style>



