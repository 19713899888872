<template >
  <div>
    <div class="col-12 p-0 m-0 mb-3">
      <div class="px-2 text-center font-weight-bold" style="font-size: 18px">
        {{ lang }} {{ translation.goThere }}
      </div>
      <div class="px-2 text-center" style="font-size: 18px">
        {{ translation.explaination }}
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="slidesCounting">
        {{ translation.imageNumber }} {{ slide + 1 }}
        <!-- /
        {{ arrayOfImages.length }} -->
      </div>
    </div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      no-wrap
      img-width="1024"
      img-height="600"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="item in arrayOfImages"
        :key="item.id"
        :style="{ 'background-image': 'url(\'' + item + '\')' }"
        style="background-size: cover"
      >
        <img
          slot="img"
          class="d-block w-95 img-fluid"
          width="1024"
          height="600"
        />
      </b-carousel-slide>

      <div class="row mb-3 controlSlider">
        <div class="row mx-0 mt-2" style="width: 100%">
          <div class="col-2 pl-4 pr-0 arrowLeft" @click="slide = slide - 1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              class="bi bi-arrow-left-short"
              viewBox="0 0 16 16"
              style="right: 5px"
            >
              <path
                fill-rule="evenodd"
                d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </div>
          <div
            class="col-8"
            style="padding-top: 17px; display: flex; justify-content: center"
          >
            <div class="row">
              <div v-for="(item, index) in arrayOfImages" :key="index">
                <div class="col-1 pr-0">
                  <div
                    v-bind:class="[
                      slide === index ? activeClass : '',
                      notActive,
                    ]"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2 pl-0 arrowRight" @click="slide = slide + 1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              class="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
              style="left: 3px px"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </b-carousel>
    <!-- <div
      class="col-12"
      style="padding-top: 17px; display: flex; justify-content: center"
    >
      <div class="row rowClickablePoints">
        <div v-for="(item, index) in arrayOfImages" :key="index">
          <div class="col-1 mb-4" @click="checkClickedAnswer(index, $event)">
            <div
              v-bind:class="[
                slide === index ? activeClassPoint : '',
                notActivePoint,
              ]"
            >
              <span class="spanNumbers">{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="text-center px-3 my-4" style="font-size: 18px">
      <!-- <div>
        {{ translation.chooseImageSeen }}
      </div> -->
    </div>
    <button
      ref="checkAnswerButton"
      class="fullWidth font-weight-bold"
      @click="checkAnswer()"
    >
      {{ translation.checkAnswer }}
    </button>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeClass: "activePoint",
      notActive: "smallPoint",
      activeClassPoint: "activeClassPoint",
      notActivePoint: "numbersInCercle",
      slide: 0,
      sliding: null,
      key: "",
      translation: "",
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
    };
  },
  mounted() {},
  computed: {
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    arrayOfImages() {
      let arrayOfImages;
      if (localStorage.getItem("arrayOfImagesBase64")) {
        arrayOfImages = JSON.parse(localStorage.getItem("arrayOfImagesBase64"));
      } else {
        arrayOfImages = this.$store.getters.arrayOfImagesBase64;
      }
      let currentIndex = arrayOfImages.length,
        temporaryValue,
        randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = arrayOfImages[currentIndex];
        arrayOfImages[currentIndex] = arrayOfImages[randomIndex];
        arrayOfImages[randomIndex] = temporaryValue;
      }
      return arrayOfImages;
      // return this.$store.getters.arrayOfImagesBase64;
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
    points() {
      return this.$store.getters.points;
    },
  },

  methods: {
    // checkClickedAnswer(index, event) {
    checkAnswer() {
      if (
        this.slide ==
        this.arrayOfImages.indexOf(
          this.points[this.$parent.pointNum + 1].imageBase64
        )
      ) {
        // event.target.parentElement.classList.add("greenBorder");
        this.$refs.checkAnswerButton.classList.add("greenBorder");
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        this.$parent.showStarUp();
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.checkElementPicked();

        localStorage.setItem("errorNumber", this.$parent.errorNumber);

        setTimeout(() => {
          this.$parent.showPointInformation = true;
          localStorage.setItem(
            "showPointInformation",
            this.showPointInformation
          );

          setTimeout(() => {
            this.$parent.showButtonInfoScreen = true;
          }, 3000);
          this.$parent.showPickedItem = false;
          localStorage.setItem("showPickedItem", this.showPickedItem);

          this.$parent.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.showMainScreen);

          // this.nextPointNumber();
        }, 1000);
      } else {
        // event.target.parentElement.classList.add("redBorder");
        this.$refs.checkAnswerButton.classList.add("redBorder");
        this.$parent.errorNumber = this.$parent.errorNumber - 1;
        this.$parent.$refs.pointsCounting.classList.add("redColor");
        localStorage.setItem("errorNumber", this.$parent.errorNumber);

        audio = new Audio(this.wongAnswerSound);
        audio.play();

        setTimeout(() => {
          // event.target.parentElement.classList.remove("redBorder");
          this.$refs.checkAnswerButton.classList.remove("redBorder");
          this.$parent.$refs.pointsCounting.classList.remove("redColor");
        }, 1000);
      }
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            checkAnswer: "Vérifier",
            chooseImageSeen: "Depuis ce point, je vois le visuel ci-dessus",
            imageNumber: "Visuel nº",
            goThere: "Rends-toi sur place.",
            explaination:
              "Parmi les " +
              this.arrayOfImages.length +
              " visuels ci-dessous, lequel vois-tu de ce point ?",
          };
          break;
        case "en":
          this.translation = {
            goThere: "Go there, and find this inscription ",
          };

          // code block
          break;
        case "he":
          this.translation = {
            goThere: "הגיעו לתחנה",
            explaination:
              "מבין " +
              this.arrayOfImages.length +
              " התמונות מטה, מה ניתן לראות במקום?",
          };
          // code block
          break;
        default:
        // code block
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
  background-color: rgb(255 124 124);
  margin-bottom: 45px;
  height: 56px;
  border: solid white 5px;
  color: white;
  font-size: 20px;
}
.greenBorder {
  border: solid rgb(5, 253, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative;*/
}
.redBorder {
  border: solid rgb(253, 5, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative; */
}
.spanNumbers {
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Cabin";
  padding-top: 3px;
}
.rowClickablePoints {
  display: flex;
  justify-content: center;
}
.arrowRight {
  height: 50px;
  width: 10px;
  background: #f17b7b;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-top-width: 5px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  border-color: white;
  border-style: solid;
  top: 70px;
}
.arrowLeft {
  height: 50px;
  width: 10px;
  background: #f17b7b;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 0px;
  border-color: white;
  border-style: solid;
  top: 70px;
}
/* .slidesCounting {
  height: 40px;
  width: 80px;
  font-size: 18px;
  padding-top: 5px;
  background: #f17b7b;
  color: white;
  text-align: center;
  font-weight: bold;
  border: solid white 2px;
  border-radius: 4px;
  position: relative;
  top: 14px;
  z-index: 2;
} */

.slidesCounting {
  width: auto;
  font-size: 21px;
  padding-top: 5px;
  background: rgb(233 151 151 / 70%);
  color: white;
  text-align: center;
  font-weight: bold;
  border: solid white 4px;
  border-radius: 4px;
  position: absolute;
  bottom: 310px;
  z-index: 2;
  padding: 8px;
  font-family: system-ui;
}

.bi-arrow-right-short,
.bi-arrow-left-short {
  font-size: 38px;
  color: white;
  position: relative;
  height: 40px;
  width: 40px;
  top: 0px;
}
.img-fluid {
  width: 100vw;
  height: auto;
}
#carousel-1 {
  /* background: rgb(171, 171, 171); */
  text-shadow: rgb(51 51 51) 1px 1px 2px;
  min-height: 230px;
  margin: 0px auto;
  width: 100vw;
  display: block;
  position: relative;
  /* max-width: 330px; */
  overflow: hidden;
}
.numbersInCercle {
  height: 40px;
  width: 40px;
  border-radius: 60px;
  background: #f17b7b;
  opacity: 0.8;
  border: solid rgb(0 0 0) 1px;
  font-size: 22px;
  color: white;
}
.activeClassPoint {
  opacity: 0.7;
  height: 50px;
  width: 50px;
  /* border: solid red 2px; */
  position: relative;
  font-size: 28px;
  bottom: 6px;
}
.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.8;
}
.activePoint {
  opacity: 0.7;
  background: red;
  height: 10px;
  width: 10px;
  bottom: 3px;
  right: 2px;
  position: relative;
}
.carousel {
  width: 640px;
  height: 200px;
}
</style>



