import { render, staticRenderFns } from "./adminV2.vue?vue&type=template&id=08fe0b00&scoped=true"
import script from "./adminV2.vue?vue&type=script&lang=js"
export * from "./adminV2.vue?vue&type=script&lang=js"
import style0 from "./adminV2.vue?vue&type=style&index=0&id=08fe0b00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fe0b00",
  null
  
)

export default component.exports