<template>
  <div v-if="showMultipleMatch">
    <div class="row m-0">
      <div class="col-12 explaination mt-2">
        {{ lang }}{{ translation.chooseImageThenDefinition }}
      </div>
    </div>
    <div class="container mb-2">
      <div class="image-list">
        <ul>
          <li
            v-for="(image, index) in images"
            :key="index"
            @click="selectImage(index, $event)"
          >
            <img :src="image.src" alt="image" />
          </li>
        </ul>
      </div>
      <div class="description-list">
        <div
          v-for="(item, index) in shuffledItems"
          :key="index"
          class="descriptionItem"
          @click="checkSelection(item, $event)"
        >
          {{ item.description }}
        </div>
      </div>
      <div v-if="completed" class="over">
        <div class="myModal">
          <img src="../../static/img/like.gif" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      completed: false,
      activeIndex: null,
      imageIndex: "",
      selectedItemId: null,
      translation: {},
    };
  },
  computed: {
    showMultipleMatch() {
      if (this.$parent.showMultipleMatch == true) {
        return true;
      } else {
        return false;
      }
    },
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    shuffledItems() {
      return this.shuffleArray(this.descriptions);
    },
    currentPoint() {
      this.images;
      return Object.values(this.$parent.points)[
        this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
      ];
    },
    images() {
      var imagesArray = [
        {
          src: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].itemImage1,
          index: 1,
        },
        {
          src: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].itemImage2,
          index: 2,
        },
        {
          src: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].itemImage3,
          index: 3,
        },
        {
          src: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].itemImage4,
          index: 4,
        },
      ];
      return imagesArray;
    },
    descriptions() {
      var descriptionsArray = [
        {
          description: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].description1,
          index: 1,
        },
        {
          description: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].description2,
          index: 2,
        },
        {
          description: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].description3,
          index: 3,
        },
        {
          description: Object.values(this.$parent.points)[
            this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
          ].description4,
          index: 4,
        },
      ];
      // console.log("descriptionsArray", descriptionsArray);
      return descriptionsArray;
    },
  },
  methods: {
    checkSelection(element, event) {
      if (element.index == this.imageIndex) {
        // console.log("CORRECT");
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.errorNumber++;
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        event.target.classList.add("greenBorder");
        const activeElement = document.querySelector(".yellowBorder");
        activeElement.classList.remove("yellowBorder");
        activeElement.classList.add("greenBorder");
        var countGreenBorder =
          document.getElementsByClassName("greenBorder").length;
        // console.log("number of green border", countGreenBorder);
        if (countGreenBorder / 2 == this.descriptions.length) {
          setTimeout(() => {
            this.completed = true;
            setTimeout(() => {
              this.$parent.showPointInformation = false;
              localStorage.setItem(
                "showPointInformation",
                this.$parent.showPointInformation
              );
              this.$parent.showPickedItem = false;
              localStorage.setItem(
                "showPickedItem",
                this.$parent.showPickedItem
              );
              this.$parent.showMainScreen = true;
              localStorage.setItem(
                "showMainScreen",
                this.$parent.showMainScreen
              );
              this.$parent.nextPointNumber();
              this.$parent.showMainScreen = true;
            }, 2000);
          }, 200);
        }
      } else {
        console.log("WRONG");
        this.$parent.errorNumber--;
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        const activeElement = document.querySelector(".yellowBorder");
        activeElement.classList.remove("yellowBorder");
        activeElement.classList.add("redBorder");
        event.target.classList.add("redBorder");
        setTimeout(() => {
          activeElement.classList.remove("redBorder");
          event.target.classList.remove("redBorder");
        }, 1000);
      }
    },
    selectImage(index, event) {
      if (document.querySelector(".yellowBorder")) {
        const activeElement = document.querySelector(".yellowBorder");
        activeElement.classList.remove("yellowBorder");
      }

      event.target.parentElement.classList.add("yellowBorder");

      this.imageIndex = this.images[index].index;
    },
    shuffleArray(array) {
      // Shuffle array using Fisher-Yates algorithm
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    // translate(lang) {
    //   switch (lang) {
    //     case "fr":
    //       this.translation = {
    //         chooseImageThenDefinition:
    //           "Cliques sur l'image et le mot correspondant.",
    //       };
    //       break;
    //     case "en":
    //       this.translation = {
    //         chooseImageThenDefinition: "Fermer",
    //       };

    //       // code block
    //       break;
    //     case "he":
    //       this.translation = {
    //         chooseImageThenDefinition:
    //           "עליכם להתאים בין תמונה למילה המתאימה לה.",
    //       };
    //       // code block
    //       break;
    //     default:
    //     // code block
    //   }
    // },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            chooseImageThenDefinition:
              "Cliquez sur l'image et le mot correspondant.",
          };
          break;
        case "en":
          this.translation = {
            chooseImageThenDefinition:
              "Click on the image and the corresponding word.",
          };
          break;
        case "de":
          this.translation = {
            chooseImageThenDefinition:
              "Klicken Sie auf das Bild und das entsprechende Wort.",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            chooseImageThenDefinition:
              "Click on the image and the corresponding word.",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },
  },
};
</script>

<style scoped>
.explaination {
  text-align: center;
  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: black;
  border: solid grey 1px;
  background: #ffffff78;
  padding: 11px;
  border-radius: 10px;
}
.myModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  background: rgb(29 151 127);
  border-radius: 100%;
  height: 220px;
  width: 220px;
}
.myModal img {
  height: 200px;
  border-radius: 100%;
}
.over {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
.greenBorder {
  border: solid rgb(5, 253, 5) 6px !important;
}
.redBorder {
  border: solid rgb(253, 13, 13) 6px !important;
}
.descriptionItem {
  width: 100%;
  height: 138px;
  border: 6px solid #d7d4d4;
  margin-right: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  margin-bottom: 10px;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  background: white;
  border-radius: 10px;
  color: #0070b0;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-list {
  position: relative;
  /* left: 8px; */
  width: 43%;
}

.image-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image-list li {
  display: block;
  margin-bottom: 9px;
  cursor: pointer;
  overflow: hidden;
  border: solid white 6px;
  border-radius: 10px;
}
/* 
.image-list li.active {
  background-color: #e6f2ff;
  border: solid yellow 6px;
} */

.yellowBorder {
  border: solid yellow 6px !important;
}

.image-list li img {
  width: auto;
  height: 127px;
}

.description-list {
  width: 50%;
  text-align: center;
}

.description-list p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  padding: 10px;
}
</style>