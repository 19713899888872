<template>
  <div id="app">
    <div class="title">Anykan | Au-delà des yeux</div>
    <header>
      <h3>Explore Museums with Our Interactive Guide</h3>
    </header>
    <section id="how-it-works">
      <h2>How It Works</h2>
      <p>
        Visitors use their smartphones to scan QR codes next to each exhibit to
        hear fascinating details about the artwork or item.
      </p>
    </section>
    <section id="why-choose">
      <h2>Why Choose Our App?</h2>
      <ul>
        <li>Enhanced Interactivity: Engage more deeply with exhibits.</li>
        <li>Cost-Effective: Reduces the need for additional equipment.</li>
        <li>Customizable: Tailored to fit the unique style of your museum.</li>
      </ul>
    </section>
    <section id="personalization">
      <h2>Personalization and Support</h2>
      <p>
        Our team ensures the app fits your museum's identity and provides
        ongoing support.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      // Define your data and methods here if needed
    };
  },
  methods: {
    // Possible methods for interaction
  },
};
</script>

<style scoped>
.title {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 54px;
  background: rgb(0, 112, 176);
  width: 100vw;
  color: white;
  font-size: 25px;
  text-align: center;
  padding-top: 8px;
  font-family: system-ui;
}
#app {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header h3 {
  color: white;
  font-family: system-ui;
  text-align: center;
}

section {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

section h2 {
  color: #34495e;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

li:last-child {
  border-bottom: none;
}
</style>
