<template>
  <div style="position: absolute; top: 0px; left: 0px">
    <div class="row mx-0 mb-0">
      <img src="../../static/img/2024.png" style="width: 100vw" />
    </div>
    <div
      class="row mx-0 mb-3 text-center fontHeebo p-3"
      style="
        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
      "
    >
      <div style="font-size: 16px" class="py-2 col-12">
        {{ lang }} {{ translation.welcome }}
      </div>
      <div style="font-size: 30px; font-weight: 600" class="py-2 col-12">
        {{ translation.expoTitle }}
      </div>
      <div style="font-size: 16px" class="py-2 col-12">
        {{ translation.explaination }}
      </div>
    </div>
    <div class="row p-0 m-0">
      <div
        v-for="(audio, index) in audioFiles"
        :key="index"
        @click="toggleAudio(index)"
        class="col-4 d-flex justify-content-center"
      >
        <div
          class="itemSquare mb-2"
          :style="{ backgroundColor: isPlaying(index) ? '#f1750a' : '#FFFFFF' }"
        >
          <div class="row d-flex justify-content-center" style="color: #63c4c3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70px"
              height="70px"
              fill="currentColor"
              class="bi bi-headphones"
              viewBox="0 0 16 16"
              style="position: relative; bottom: 2px"
            >
              <path
                d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5"
              />
            </svg>
          </div>
          <div class="playPause">
            <!-- <i :class="{'bi bi-play-circle': !isPlaying(index), 'bi bi-pause-circle': isPlaying(index)}"></i>  -->
            <svg
              v-if="isPlaying(index) && !showPause"
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              class="bi bi-pause-circle"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
              />
              <path
                d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              fill="currentColor"
              class="bi bi-play-circle"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
              />
              <path
                d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"
              />
            </svg>
          </div>
          <div
            class="row d-flex justify-content-center font-weight-bold soundNumber"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mx-0 mb-3 text-center fontHeebo p-3"
      style="
        background-color: black;
        color: white;
        display: flex;
        justify-content: center;
      "
    >
      <div style="font-size: 32px; font-weight: 600" class="py-2 col-12">
        {{ translation.thanks }}
      </div>
      <div style="font-size: 16px" class="py-2 col-12">
        {{ translation.moreInfo }}
      </div>

      <a :href="museumSite">
        <div class="siteButton mt-2">www.ouchui.ch</div>
      </a>

      <div
        class="row d-flex justify-content-center mt-4 col-12"
        style="color: #63c4c3"
      >
        <!-- <a :href="openWhatsapp">
                    <div class="col-3 links">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor"
                            class="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path
                                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                        </svg>
                    </div>
                </a> -->
        <a :href="instaPage">
          <div class="col-6 links">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              fill="currentColor"
              class="bi bi-instagram"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
              />
            </svg>
          </div>
        </a>
        <a :href="facebookPage">
          <div class="col-6 links">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              fill="currentColor"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
              />
            </svg>
          </div>
        </a>
      </div>
      <div
        class="row d-flex justify-content-center mt-4 pt-3"
        style="border-top: 1px solid white"
      >
        <a :href="ouchui">
          <div class="col-12 linksToWebsite" style="font-family: 'segoeui'">
            designed and developped by Oùchui.ch
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      translation: {
        welcome: "Bienvenus à l'exposition",
        expoTitle: "Lumières intemporelles",
        explaination:
          "Nous vous invitons à appuyer sur le bouton correspondant au numéro affiché et à écouter les artistes vous parler de leurs œuvres.",
        moreInfo:
          "Pour plus d'informations sur le musée, les expositions et les événements qui s'y déroulent tout au long de l'année, nous vous invitons à visiter notre site web et à nous suivre sur Facebook et Instagram.",
        thanks: "Merci de votre visite.",
      },
      ouchui: "https://ouchui.ch/",
      // openWhatsapp: "https://api.whatsapp.com/send?phone=972544233297&text=אשמח להכנס לקבוצת וואטסאפ של המוזאון",
      showPause: true,
      // facebookPage: "https://www.facebook.com/lagrange",
      // instaPage: "https://www.instagram.com/lagrange",
      museumSite: "https://ouchui.ch/",
      key: "",
      audioFiles: [
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice1.mp3?alt=media&token=13c9e64d-f0d7-4901-9213-6d4295fda6de",
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice2.mp3?alt=media&token=d3be995f-d1cb-4f64-9c81-0df592d59f88",
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice3.mp3?alt=media&token=b81709cd-4491-42fc-bbb8-8fc04a65e4a5",
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice4.mp3?alt=media&token=dac751e0-6f83-4722-ad4e-0fc450d11d10",
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice5.mp3?alt=media&token=9a5171c5-2b83-45b8-ae13-5363007e6ff6",
        "https://firebasestorage.googleapis.com/v0/b/individualcellnavapp.appspot.com/o/guideDigital%2Fexample%2Fvoice6.mp3?alt=media&token=016fb17b-241c-42e6-86de-eef673f55139",
      ],
      audioElements: [],
      currentlyPlayingIndex: null,
    };
  },

  computed: {
    lang() {
      console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
  },
  methods: {
    translate(lang) {
      switch (lang) {
        case "en":
          this.translation = {
            welcome: "Welcome to the exhibition",
            expoTitle: "Timeless Lights",
            explanation:
              "We invite you to press the button corresponding to the number displayed and listen to the artists talk about their works.",
            moreInfo:
              "For more information about the museum, the exhibitions, and the events held throughout the year, we invite you to visit our website and follow us on Facebook and Instagram.",
            thanks: "Thank you for your visit.",
          };
          break;
        case "de":
          this.translation = {
            welcome: "Willkommen zur Ausstellung",
            expoTitle: "Zeitlose Lichter",
            explanation:
              "Wir laden Sie ein, die Taste zu drücken, die der angezeigten Nummer entspricht und den Künstlern zuzuhören, wie sie über ihre Werke sprechen.",
            moreInfo:
              "Für weitere Informationen über das Museum, die Ausstellungen und die Veranstaltungen, die das ganze Jahr über stattfinden, besuchen Sie bitte unsere Webseite und folgen Sie uns auf Facebook und Instagram.",
            thanks: "Vielen Dank für Ihren Besuch.",
          };
          break;
        case "fr":
          this.translation = {
            welcome: "Bienvenus à l'exposition",
            expoTitle: "Lumières intemporelles",
            explanation:
              "Nous vous invitons à appuyer sur le bouton correspondant au numéro affiché et à écouter les artistes vous parler de leurs œuvres.",
            moreInfo:
              "Pour plus d'informations sur le musée, les expositions et les événements qui s'y déroulent tout au long de l'année, nous vous invitons à visiter notre site web et à nous suivre sur Facebook et Instagram.",
            thanks: "Merci de votre visite.",
          };
          break;
        default:
          // Default case to handle other languages or missing cases
          this.translation = {};
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    isPlaying(index) {
      // console.log("isPlaying")
      // console.log("this.currentlyPlayingIndex", this.currentlyPlayingIndex)
      // console.log("index", index)
      // if (this.currentlyPlayingIndex !== null) {

      // }

      // if (this.currentlyPlayingIndex === index) {
      //     console.log("index*********************", index)
      //     if (this.audioElements[index].paused) {
      //         console.log("this.audioElements[index].paused*********************", this.audioElements[index].paused)
      //         return this.currentlyPlayingIndex === null
      //     } else {
      //         console.log("this.audioElements[index].paused*********!!!!!!!!!!************", this.audioElements[index].paused)
      //         return this.currentlyPlayingIndex === index
      //     }
      // } else {
      //     return this.currentlyPlayingIndex === index
      // }

      return this.currentlyPlayingIndex === index;
    },
    toggleAudio(index) {
      // console.log("index", index)

      // this.isPlaying(index)
      if (this.currentlyPlayingIndex !== null) {
        if (this.currentlyPlayingIndex === index) {
          // if (this.audioElements[index] && this.audioElements[index].paused) {
          if (this.audioElements[index].paused) {
            // console.log("this.audioElements", this.audioElementsֲ[index])
            this.showPause = false;
            this.audioElements[index].play();
          } else {
            this.showPause = true;
            this.audioElements[index].pause();
            // console.log("this.audioElements", this.audioElementsֲ[index])
            // console.log("this.audioElements[index]", this.audioElements[index].parentNode)
          }
        } else {
          if (this.audioElements[this.currentlyPlayingIndex]) {
            this.audioElements[this.currentlyPlayingIndex].pause();
            this.audioElements[this.currentlyPlayingIndex].currentTime = 0;
          }
          this.audioElements[index] = new Audio(this.audioFiles[index]);
          this.audioElements[index].play();
          this.currentlyPlayingIndex = index;
        }
      } else {
        this.audioElements[index] = new Audio(this.audioFiles[index]);
        this.audioElements[index].play();
        this.showPause = false;
        this.currentlyPlayingIndex = index;
      }
    },
  },
};
</script>

<style scoped>
.links {
  color: white !important;
  text-decoration: none !important;
}

.linksToWebsite {
  color: white !important;
  text-decoration: none !important;
}

.siteButton {
  background: #63c4c3;
  width: 300px;
  height: 40px;
  font-size: 20px;
  font-family: Heebo;
  border-radius: 3px;
  padding-top: 5px;
  color: white !important;
  text-decoration: none !important;
}

.siteButton:active {
  text-decoration: none !important;
}

.soundNumber {
  color: black;
  font-size: 27px;
  position: relative;
  bottom: 16px;
}

.playPause {
  position: absolute;
  top: 19px;
  left: calc(50% - 10px);
  /* right: 53px; */
  color: #63c4c3;
}

.presented {
  color: white;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  bottom: 11px;
  text-align: left;
  left: 58px;
}

.itemSquare {
  height: 100px;
  width: 100px;
  border: solid 1px #63c4c3;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 3px #80808026;
  /* background-color: #f1750a; */
}

.titleListName {
  position: absolute;
  top: -45px;
  width: 200px;
  background: white;
  height: 37px;
  z-index: 30;
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  padding-top: 5px;
  font-size: 20px;
}

.captionArrow {
  position: absolute;
  font-size: 12px;
  top: 23px;
  left: 30px;
}

.audioButton {
  width: 100%;
  border: 2px #79a541 solid;
  border-radius: 25px;
  height: 40px;
  margin-bottom: 16px;
  position: relative;
}

.titleSpan {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  color: #63c4c3;
  text-align: center;
  font-weight: bold;
}

.closingCross {
  position: relative;
  top: 0px;
  right: 39px;
  z-index: 20;
  color: black;
  font-size: 16px;
  z-index: 50;
}
/* 
.fontHeebo {
    font-family: Heebo;
}

@font-face {
    font-family: Heebo;
    src: url(../../static/Heebo/Heebo-VariableFont_wght.ttf);
}
.fontPacifico {
    font-family: Pacifico;
}

@font-face {
    font-family: Pacifico;
    src: url(../../static/Pacifico/Pacifico-Regular.ttf);
} */
</style>