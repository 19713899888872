<template >
  <div style="direction: ltr">
    <div v-if="loading" class="over container">
      <div class="row" style="height: 45vh">
        <div class="col-12"></div>
      </div>
      <div class="row">
        <div class="spinner-border text-primary center" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="over container">
        <div class="myModal p-2 container row">
          <div class="row align-items-center justify-content-center logoBar">
            <div
              style="position: absolute; top: 10px; left: 10px"
              @click="openMain"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-arrow-left-short"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                style="color: white; font-size: 45px"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                />
              </svg>
            </div>

            <!-- <select v-model="language" @click="changeLanguage">
              <option>Français</option>
              <option>English</option>
              <option>Deutsch</option>
            </select> -->
            <select v-model="language" @change="changeLanguage">
              <option>Français</option>
              <option>Deutsch</option>
              <option>English</option>
            </select>
          </div>
          <div
            class="row align-items-center justify-content-center align-self-center"
            style="height: 40%"
          >
            <div id="stampGame" class="text-center mt-5">
              <img src="../../static/img/logoWhite.png" alt="" />
            </div>
            <div
              id="titleMainPage"
              class="row text-center justify-content-center center blur"
            >
              {{ lang }} {{ translation.title }}
            </div>
          </div>

          <div class="row align-items-center text-center" style="height: 17%">
            <div class="col-12 font-weight-bold">
              <!-- <div style="color: white; font-size: 18px">
                {{ translation.pleaseToKnowYou }}
              </div> -->
              <div class="py-3">
                <input
                  name="userName"
                  label="Pseudo"
                  id="userName"
                  v-model="userName"
                  required
                  :placeholder="yourName"
                  class="center nameDiv text-center"
                  style=""
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center" style="height: 20%">
            <div class="col-12 d-flex justify-content-center">
              <div
                class="p-2 h5 text-center"
                style="max-width: 360px; color: white"
              >
                <div class="font-weight-bold">
                  {{ translation.beware }}
                </div>
                <div class="mt-2 font-weight-bold">
                  {{ translation.startPoint }}
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-center bottomBar">
            <div
              class="startButton d-flex justify-content-center"
              @click="checkIfName()"
            >
              <img
                src="../../static/img/step.png"
                alt=""
                style="height: 60px; position: relative; top: 10px"
              />
            </div>
            <div class="startText">
              {{ translation.letsGoButton }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="confirmName === true" class="warning center">
        <div class="p-3 myModal2 container d-flex">
          <div class="row">
            <div class="px-2 mt-3 col-12 text-center" style="font-size: 33px">
              {{ translation.wellDone }} {{ userName }}!
            </div>
            <div class="px-2 mt-3 col-12 text-center">
              {{ translation.beforeStarting }}
            </div>
            <div
              class="okBtn btn font-weight-bold position-relative col-5 ml-2"
              style="padding-top: 8px"
              @click="onSignin"
            >
              {{ translation.ok }}
            </div>
            <div
              class="position-relative text-center col-5"
              style="margin-top: 37px; padding: 0px"
              @click="startGame"
            >
              {{ translation.skip }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="showAlertNoName === true" class="warning center">
        <div class="p-3 myModal2" @click="showAlertNoName = false">
          <div class="mt-3 text-center">
            <div class="mt-3" style="font-size: 33px">
              {{ translation.oups }}...
            </div>
            <div class="mt-3 text-center">{{ translation.noPassword }}</div>
          </div>
          <div class="font-weight-bold mt-5 mb-2 text-center">
            {{ translation.gotIt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      yourName: "Ton pseudo",
      translation: {
        title: "Fan de foot",
        pleaseToKnowYou: "",
        stamp: "Jeu de piste",
        beware: "Attention!",
        startPoint: "Le jeu commence à la place de Meyrin Village",
        letsGoButton: "C'est parti",
        beforeStarting: "לפני שנצא לדרך, אפשר להציע לך הדרכה קצרה?",
        ok: "Ca marche!",
        skip: "Non merci, passer",
        noPassword:
          "Il semblerait que vous avez oublié d'entrer un pseudo... C'est juste pour que vous puissiez voir à quelle place vous avez fini au classement.",
        gotIt: "Ok, j'ai compris",
      },
      confirmName: false,
      navigationName: "meyrin",
      showAlertNoName: false,
      groupColorEnglish: "",
      userName: "",
      // title: "מעין דור לנחל תבור",
      userKey: "",
      language: "Français",
      buttonText: "العربية",
    };
  },
  mounted() {
    if (localStorage.getItem("navigationName")) {
      if (
        localStorage.getItem("navigationName") != this.navigationName
        // ||
        // localStorage.getItem("startTimeOuchui") - Date.now() <
        //   -60000 * 1 * 60 * 5 - 600000
      ) {
        localStorage.clear();
      }
    }
    if (localStorage.getItem("pointNumber")) {
      this.userName = localStorage.getItem("myNameOuchui");
      this.groupColorEnglish = localStorage.getItem("groupColorEnglish");
      // this.$store.dispatch("fetchSentence", {
      //   navigationName: this.navigationName
      // });
      if (localStorage.getItem("language")) {
        this.language = localStorage.getItem("language");
        this.$store.dispatch("switchLanguage", this.language);
      }
      this.userKey = localStorage.getItem("userKey");
      this.$store.dispatch("createNewUser", {
        userName: this.userName,
        navigationName: this.navigationName,
        groupColorEnglish: this.groupColorEnglish,
        pointNumber: localStorage.getItem("pointNumber"),
      });
      this.$store.dispatch("fetchGameDataFull", {
        navigationName: this.navigationName,
        userName: this.userName,
        groupColorEnglish: this.groupColorEnglish,
      });
      let pushStartGame = setInterval(() => {
        if (this.loading == true) {
          console.log("loading");
        } else {
          this.$router.push("/play");
          clearInterval(pushStartGame);
        }
      }, 200);
    }
  },
  computed: {
    lang() {
      console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    openMain() {
      this.$router.push("/");
    },
    changeLanguage() {
      var localLang;
      switch (this.language) {
        case "English":
          this.buttonText = "English";
          this.language = "English";
          localLang = "en";
          console.log("English");

          break;
        case "Français":
          this.buttonText = "Français";
          this.language = "Français";
          localLang = "fr";
          console.log("Français");

          break;
        case "Deutsch":
          this.buttonText = "Deutsch";
          this.language = "Deutsch";
          localLang = "de";
          console.log("Deutsch");

          break;
        default:
      }
      localStorage.setItem("language", this.language);
      localStorage.setItem("lang", localLang);
      this.translate(localLang);
      this.$store.dispatch("switchLanguage", localLang);
    },
    // switchToArabic() {
    //   this.$store.dispatch("switchLangToFrench");
    // },
    // switchToHebrew() {
    //   this.$store.dispatch("switchLangToEnglish");
    // },
    // translate(lang) {
    //   switch (lang) {
    //     case "ar":
    //       this.yourName = " الاسم";
    //       this.translation = {
    //         pleaseToKnowYou: "من الرائع أن ألتقي",
    //         title: "من عين دور حتى وادي البيرة",
    //         wellDone: "جيد",
    //         stamp: "لعبة ملاحة",
    //         beware: "انتباه!",
    //         goToStartPointWaze: "وصلت إلى نقطة البداية",
    //         startPoint: "ستبدأ اللعبة بعد النقر على الزر",
    //         letsGoButton: "ابدأ",
    //         beforeStarting: "قبل أن تبدؤوا، ألقوا نظرة على كيفية عمل التطبيق",
    //         ok: "طبعًا",
    //         skip: "لا شكرا",
    //         noPassword: "يبدو أنك نسيت إدخال اسمك",
    //         gotIt: "حسناً",
    //         oups: "عفوا",
    //       };
    //       //   console.log("arabic");
    //       break;
    //     case "en":
    //       this.translation = {
    //         pleaseToKnowYou: "On se fear un plaisir à te connaire:",
    //         title: "מעין דור לנחל תבור",

    //         stamp: "Jeu de piste",
    //         beware: "Attention!",
    //         startPoint: "The game begins at the Place of Meyrin Village",
    //         letsGoButton: "I'M HERE!",
    //         beforeStarting:
    //           "Before getting started, you can learn how the app works.",
    //         ok: "Sure!",
    //         skip: "No thanks, skip.",
    //         noPassword:
    //           "It looks like you forgot to enter a nickname... It's just so you can see where you ended up in the leaderboard.",
    //         gotIt: "Ok, got it",
    //       };
    //       //  console.log("english");

    //       // code block
    //       break;
    //     case "fr":
    //       this.yourName = "Ton pseudo";

    //       this.translation = {
    //         wellDone: "Bravo",
    //         pleaseToKnowYou: "Plaisir de faire ta connaissance",
    //         title: "Fan de foot",

    //         stamp: "Jeu de piste",
    //         beware: " Attention !",
    //         startPoint: "Le jeu commence à la Place de la Madelaine",
    //         letsGoButton: "C'est parti",
    //         beforeStarting: "Tu veux voir le tuto avant de commencer ?",
    //         ok: "Ok !",
    //         skip: "Non merci",
    //         noPassword: "Tu as oublié de mettre ton pseudo",
    //         gotIt: "Je réessaye",
    //         oups: "Oups",
    //       };
    //       // console.log("fr");

    //       // code block
    //       break;
    //     default:
    //     // code block
    //   }
    // },
    translate(lang) {
      switch (lang) {
        case "en":
          this.translation = {
            wellDone: "Well done",
            pleaseToKnowYou: "Pleased to meet you",
            title: "Football fan",
            stamp: "Scavenger hunt",
            beware: "Beware!",
            goToStartPointWaze: "You've reached the starting point",
            startPoint: "The game begins at Place de la Madelaine",
            letsGoButton: "Let's go",
            beforeStarting:
              "Would you like to see the tutorial before starting?",
            ok: "Ok!",
            skip: "No thanks",
            noPassword: "You forgot to enter your nickname",
            gotIt: "Let's try again",
            oups: "Oops",
          };
          break;
        case "de":
          this.translation = {
            wellDone: "Gut gemacht",
            pleaseToKnowYou: "Freut mich, dich kennenzulernen",
            title: "Fußballfan",
            stamp: "Schnitzeljagd",
            beware: "Achtung!",
            goToStartPointWaze: "Du hast den Startpunkt erreicht",
            startPoint: "Das Spiel beginnt am Place de la Madelaine",
            letsGoButton: "Los geht's",
            beforeStarting:
              "Möchtest du das Tutorial sehen, bevor du anfängst?",
            ok: "Ok!",
            skip: "Nein danke",
            noPassword: "Du hast vergessen, deinen Spitznamen einzugeben",
            gotIt: "Ich versuche es nochmal",
            oups: "Hoppla",
          };
          break;
        case "fr":
          this.yourName = "Ton pseudo";
          this.translation = {
            wellDone: "Bravo",
            pleaseToKnowYou: "Plaisir de faire ta connaissance",
            title: "Fan de foot",
            stamp: "Jeu de piste",
            beware: "Attention !",
            startPoint: "Le jeu commence à la Place de la Madelaine",
            letsGoButton: "C'est parti",
            beforeStarting: "Tu veux voir le tuto avant de commencer ?",
            ok: "Ok !",
            skip: "Non merci",
            noPassword: "Tu as oublié de mettre ton pseudo",
            gotIt: "Je réessaye",
            oups: "Oups",
          };
          break;
        default:
          // Default case to handle other languages or missing cases
          this.translation = {
            wellDone: "Well done",
            pleaseToKnowYou: "Pleased to meet you",
            title: "Football fan",
            stamp: "Scavenger hunt",
            beware: "Beware!",
            startPoint: "The game begins at Place de la Madelaine",
            letsGoButton: "Let's go",
            beforeStarting:
              "Would you like to see the tutorial before starting?",
            ok: "Ok!",
            skip: "No thanks",
            noPassword: "You forgot to enter your nickname",
            gotIt: "Let's try again",
            oups: "Oops",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    startGame() {
      this.$router.push("/play");
    },
    checkIfName() {
      //  console.log("checkName - this.userName", this.userName);
      if (this.userName === "") {
        this.showAlertNoName = true;
      } else {
        localStorage.setItem("myNameOuchui", this.userName);
        localStorage.setItem("navigationName", this.navigationName);
        localStorage.setItem("groupColorEnglish", this.groupColorEnglish);
        setTimeout(() => {
          this.confirmName = true;
          this.$store.dispatch("createNewUser", {
            userName: this.userName,
            navigationName: this.navigationName,
            groupColorEnglish: this.groupColorEnglish,
            evolution: 1,
          });
          this.$store.dispatch("fetchGameDataFull", {
            navigationName: this.navigationName,
            userName: this.userName,
            groupColorEnglish: this.groupColorEnglish,
          });
        }, 1000);
      }
    },
    onSignin() {
      this.$router.push("/tutorialV2");
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>

<style scoped>
select {
  background: transparent;
  color: white;
  border: none;
}
select option {
  margin: 40px;
  background: #3369bc;
  color: #fff;
}
.row {
  margin: 0px !important;
}
.startText {
  color: white;
  position: absolute;
  bottom: 10px;
  font-weight: bold;
}
.startButton {
  height: 80px;
  width: 80px;
  background: white;
  color: #3369bc;
  position: relative;
  bottom: 47px;
  border-radius: 100%;
}
.logoBar {
  height: 10%;
  background: #3369bc;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  max-width: 590px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  font-family: "system-ui";
  font-size: 25px;
  color: white;
}
.bottomBar {
  height: 10%;
  background: #3369bc;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  max-width: 590px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.blur {
  backdrop-filter: blur(10px);
}
.languageChoice {
  border: #00000017 solid 2px;
  border-radius: 14px;
  padding: 5px;
  color: black;
  text-align: center;
  font-size: 22px;
  z-index: 5;
  background: #80808021;
  min-width: 89px;
}
.logoRN {
  /* position: absolute;
  top: 16px;
  right: 16px; */
}
.logoRN img {
  height: 42px;
}
.logoEDM {
  /* position: absolute;
  top: 26px;
  left: 16px; */
}
.logoEDM img {
  height: 42px;
  border-radius: 0px;
}

.nameDiv {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 5px;
  color: black;
  border: none;
  height: 50px;
}
#stampGame {
  color: white;
  font-weight: 700;
  font-size: 23px;
  width: auto;
}
#stampGame img {
  height: 88px;
}

.myModal {
  display: block;
  margin: 0px auto;
  background: transparent;
  height: 100%;
}
#titleMainPage {
  position: relative;
  font-size: 21px;
  padding: 8px 25px;
  font-weight: bold;
  color: white;
  -webkit-background-clip: text;
  background-clip: text;
  background: #3369bc;
  border-radius: 50px;
}

.myModal2 {
  height: auto;
  width: 320px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  background-color: white;
  color: #3369bc;
}
.over {
  top: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 100vh;
  background: url("../../static/img/stade.jpg") no-repeat center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.9);
}
.warning {
  display: flex;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.okBtn {
  border-radius: 36px;
  color: white;
  margin: 0px auto;
  margin-top: 30px;
  background-color: #3369bc;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 8px 0 0 #447c70, 0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px #447c70 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
.center {
  display: block;
  margin: 0px auto;
}
@keyframes reveal {
  80% {
    letter-spacing: 8px;
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes glow {
  40% {
    text-shadow: 0 0 8px rgb(31, 250, 2);
  }
}
@keyframes stamp {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  94% {
    opacity: 0.5;
    transform-origin: 50% 50%;
    transform: scale(4);
    transition: all 0.1s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: rotate(-20deg) scale(1);
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    background-position: -500%;
    background-size: 80%;
  }
  20% {
    opacity: 1;
    background-position: 400%;
    background-size: 80%;
  }
  40% {
    background-position: -200%;
    background-size: 80%;
  }
  60% {
    background-position: 0%;
    background-size: 80%;
  }
  80% {
    background-position: 0%;
    background-size: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }

  30%,
  70% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  93% {
    opacity: 0.5;
  }
  95% {
    opacity: 1;
    transform: scale(1.05);
  }
  98% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slidein {
  0% {
    margin-left: 120vw;
  }
  72% {
    margin-left: 120vw;
  }

  98% {
    margin-left: 3%;
  }

  to {
    margin-left: 0%;
  }
}
</style>


