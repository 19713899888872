
<template >
  <div class="mt-0">
    <div class="row d-flex align-items-center justify-content-center mx-0">
      <!-- <div
        class="photoInstruction col-12 text-center my-4"
        v-if="imageUrl == ''"
      > -->
      <div class="photoInstruction col-12 text-center py-4">
        <div v-if="shareOnWhatsApp">
          {{ lang }} {{ translation.clickOnWhatsapp }} {{ photoInstruction }}
        </div>
      </div>
      <div
        v-if="showUploadImage && !shareOnWhatsApp"
        class="d-flex align-items-center justify-content-center mr-0 ml-0 mt-4"
      >
        <div class="position-relative" style="z-index: 2">
          <button
            class="colorBtn btn font-weight-bold mb-2"
            tag="label"
            type="button"
            style="bottom: 14px"
            @click="onPickFile"
          >
            <svg
              style="font-size: 36px"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-camera-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path
                fill-rule="evenodd"
                d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"
              />
            </svg>

            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"
            />
          </button>
          <div v-if="imageUrl != ''" class="anotherPhoto">
            {{ translation.reshoot }}
          </div>
        </div>

        <div
          class="position-relative m-4"
          v-if="imageUrl != ''"
          style="width: 80px; text-align: center; z-index: 2"
        >
          <button
            class="colorBtn btn font-weight-bold mb-2"
            @click="downloadImage"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="font-size: 36px"
              width="1em"
              height="1em"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
              />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
              />
            </svg>
          </button>
          <div class="anotherPhoto">{{ translation.saveOnPhone }}</div>
        </div>
      </div>
      <div v-else>
        <div class="whatsappButton col-12" @click="openWhatsapp">
          <i class="fa fa-whatsapp" style="font-size: 100px; color: white"></i>

          <!-- <button class="btn bmd-btn-fab p-0" style="color: #3369bc !important">
            <i class="fa fa-whatsapp" style="font-size: 130px"></i>
          </button> -->
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-primary btn-lg btn3d font-weight-bold continueButton"
          @click="goToNextPoint"
        >
          {{ lang }} {{ translation.letsGoButton }}
        </button>
      </div>

      <div xs12 sm6 offset-sm3>
        <img :src="imageUrl" ref="imageToCanvas" style="display: none" />
        <canvas ref="canvas" v-if="showCanvas" class="fitScreen"></canvas>
        <input
          type="file"
          style="display: none"
          ref="fileInput2"
          accept="image/*"
          @change="onFilePicked"
        />
      </div>
    </div>

    <div v-if="showPictureTaken" class="h-100">
      <div
        class="row align-items-center justify-content-center align-self-center h-100"
      >
        <div
          class="p-3 myModal"
          style="background-color: rgb(14 112 247 / 85%)"
        >
          <div class="py-3 text-center">
            <div xs12 sm6 class v-if="showUploadImage">
              <button
                tag="label"
                type="button"
                class="btn btn-primary mt-5 mb-5 btn-lg btn-block"
                @click="onPickFile"
              >
                Upload Image
                <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="image/*"
                  @change="onFilePicked"
                />
              </button>
            </div>
            <div class="imageTaken">
              <img :src="imageUrl" ref="imageToCanvas" style="display: none" />
              <canvas ref="canvas" v-if="showCanvas" class="fitScreen"></canvas>
              <button
                block
                v-if="showCanvas"
                @click="onPickFile2"
                class="btn btn-primary mt-5 btn-lg btn-block"
              >
                Change point's photo
              </button>
              <input
                type="file"
                style="display: none"
                ref="fileInput2"
                accept="image/*"
                @change="onFilePicked"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button
      class="btn btn-primary btn-lg btn3d font-weight-bold continueButton"
      @click="goToNextPoint"
    >
      {{ lang }} {{ translation.letsGoButton }}
    </button> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      // MessageMedia: require("whatsapp-web.js"),
      imageUrl: "",
      photoTaken: false,
      showCanvas: false,
      image: null,
      showUploadImage: true,
      showPictureTaken: false,
      translation: {
        nextTime: "Prochaine fois",
        letsGoButton: "Continue",
        reshoot: "Reommencer",
        saveOnPhone: "Sauver sur le téléphone",
        clickOnWhatsapp:
          "Cliquez sur l'icône WhatsApp ci-dessous et envoyez à un(e) ami(e) une photo ",
        // currentlyPlaying:
        //   "Je joue actuellement à un super parcours sur l'application ouchui.ch. Je te recommande vivement de le tester : https://ouchui.ch/",
      },
      message:
        "Je joue actuellement à un super parcours sur l'application ouchui.ch. Je te recommande vivement de le tester : https://ouchui.ch/" +
        // this.translation.currentlyPlaying +
        localStorage.getItem("navigationName"),
    };
  },
  computed: {
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    error() {
      return this.$store.getters.error;
    },
    photoInstruction() {
      if (
        Object.values(this.$parent.points)[
          this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
        ].photoInstruction != null
      ) {
        return Object.values(this.$parent.points)[
          this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
        ].photoInstruction;
      } else {
        return "";
      }
    },
    shareOnWhatsApp() {
      return Object.values(this.$parent.points)[
        this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
      ].shareOnWhatsApp;
    },
    // loading() {
    //   return this.$store.getters.loading;
    // }
  },
  methods: {
    // translate(lang) {
    //   switch (lang) {
    //     case "fr":
    //       this.translation = {
    //         nextTime: "Prochaine fois",
    //         letsGoButton: "Continue",
    //         reshoot: "Reommencer",
    //         saveOnPhone: "Sauver sur le téléphone",
    //         clickOnWhatsapp:
    //           "Cliquez sur l'icône WhatsApp ci-dessous et envoyez à un(e) ami(e) une photo ",
    //       };
    //       break;
    //     case "en":
    //       this.translation = {
    //         nextTime: "Next time",
    //         tryThePuzzle: "Elapsed time",
    //         clickOnWhatsapp:
    //           "Click on the icon to send a photo to a friend via WhatsApp.",
    //       };

    //       // code block
    //       break;
    //     case "he":
    //       this.translation = {
    //         nextTime: "כמעט! תעשו יותר טוב בנקודה באה!",
    //         letsGoButton: "המשך",
    //         reshoot: "לצלם שוב",
    //         saveOnPhone: "שמירה לטלפון",
    //       };
    //       // code block
    //       break;
    //     default:
    //     // code block
    //   }
    // },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            nextTime: "Prochaine fois",
            letsGoButton: "Continue",
            reshoot: "Recommencer",
            saveOnPhone: "Sauver sur le téléphone",
            clickOnWhatsapp:
              "Cliquez sur l'icône WhatsApp ci-dessous et envoyez à un(e) ami(e) une photo",
            currentlyPlaying:
              "Je joue actuellement à un super parcours sur l'application ouchui.ch. Je te recommande vivement de le tester : https://ouchui.ch/",
          };
          break;
        case "en":
          this.translation = {
            nextTime: "Next time",
            letsGoButton: "Continue",
            reshoot: "Retake",
            saveOnPhone: "Save on phone",
            clickOnWhatsapp:
              "Click on the WhatsApp icon below to send a photo to a friend",
            currentlyPlaying:
              "I am currently playing a great course on the ouchui.ch app. I highly recommend you try it out: https://ouchui.ch/",
          };
          break;
        case "de":
          this.translation = {
            nextTime: "Nächstes Mal",
            letsGoButton: "Weiter",
            reshoot: "Neu aufnehmen",
            saveOnPhone: "Auf dem Handy speichern",
            clickOnWhatsapp:
              "Klicken Sie auf das WhatsApp-Symbol unten, um einem Freund ein Foto zu senden",
            currentlyPlaying:
              "Ich spiele gerade einen großartigen Kurs in der ouchui.ch-App. Ich empfehle dir dringend, es auszuprobieren: https://ouchui.ch/",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            nextTime: "Next time",
            letsGoButton: "Continue",
            reshoot: "Retake",
            saveOnPhone: "Save on phone",
            clickOnWhatsapp:
              "Click on the WhatsApp icon below to send a photo to a friend",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    goToNextPoint() {
      var audio = new Audio(this.correctAnswerSound);
      audio.play();
      setTimeout(() => {
        this.$parent.nextPointNumber();
        this.$parent.showPointInformation = false;
        localStorage.setItem(
          "showPointInformation",
          this.$parent.showPointInformation
        );
        this.$parent.showPickedItem = false;
        localStorage.setItem("showPickedItem", this.$parent.showPickedItem);
        this.$parent.showMainScreen = true;
        localStorage.setItem("showMainScreen", this.$parent.showMainScreen);
        //  console.log("this.pointNum", this.$parent.pointNum);
      }, 2000);
    },
    downloadImage() {
      const link = document.createElement("a");
      link.setAttribute("href", this.imageUrl);
      link.setAttribute("download", "filename");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openWhatsapp() {
      window.open(`whatsapp://send?text=` + this.message);
      //window.open("whatsapp://send?text=" + encodeURIComponent(this.imageUrl));
    },
    takePhoto() {
      //  console.log("take a picture");
      this.photoTaken = true;
      //console.log("this.photoTaken", this.photoTaken);

      this.showPictureTaken = true;
    },

    onDismissed() {
      this.$store.dispatch("clearError");
    },
    // ******************************************** UPLOAD PICTURE**********************************
    onPickFile() {
      // the $refs below give us access to all the ref elements in the template of this component
      this.$refs.fileInput.click();
    },
    onPickFile2() {
      // the $refs below give us access to all the ref elements in the template of this component
      this.$refs.fileInput2.click();
    },
    onFilePicked(event) {
      // We get the wanted file
      // console.log("[onFilePIcked] event", event);
      const files = event.target.files;
      this.fillEventDataFromImage(files[0]);
      // As we can choose only one file, we take the first one in the array
      this.filename = files[0].name;
      // Simple chack if the file is valid
      if (this.filename.lastIndexOf(".") <= 0) {
        return alert("Please enter a valid image");
      }
      // Turn it into base64
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // the result here is a base64 image
        this.imageUrl = fileReader.result;
        // console.log("this.imageUrl", this.imageUrl);
        var img = new Image();
        img.src = this.imageUrl;
        img.addEventListener("load", () => {
          let context = this.$refs.canvas.getContext("2d");
          let image = this.$refs.imageToCanvas;
          // let imageWidth = window.outerWidth - 16
          let imageWidth = 500;
          this.$refs.canvas.width = imageWidth;
          this.$refs.canvas.height = (imageWidth * image.height) / image.width;
          // Now I create the image - what?, top, left, width, height
          context.drawImage(
            image,
            0,
            0,
            imageWidth,
            (imageWidth * image.height) / image.width
          );
          this.image = this.dataURItoBlob(this.$refs.canvas.toDataURL());
          // console.log("this.image", this.image);
        });
      });
      fileReader.readAsDataURL(files[0]);
      this.showCanvas = true;
      this.showUploadImage = true;
    },
    fillEventDataFromImage(file) {
      if ("image/jpeg" !== file.type) {
        return;
      }
      this.showGost = true;
      // console.log("image ok");
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
  },
};
</script>

<style scoped>
.continueButton {
  background-color: white !important;
  color: rgb(0, 112, 176);
  margin-bottom: 45px;
  height: 60px !important;
  position: relative !important;
  margin-top: 50px !important;
  width: 200px;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}

.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px rgb(146, 146, 148) inset,
    0 0 0 2px rgb(255 255 255 / 15%) inset, 0 8px 0 0 rgb(146, 146, 148),
    0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(146, 146, 148);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px rgb(146, 146, 148) inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(0, 112, 176);
}
button {
  background-color: rgb(0, 112, 176);
  width: 72px;
  height: 72px !important;
  position: relative;
}

.photoInstruction {
  font-weight: bold;
  font-size: 21px;
  color: white;
  padding: 24px;
}
.anotherPhoto {
  font-size: 18px;
  margin-bottom: 15px;
  color: rgb(0, 112, 176);
  font-weight: bold;
  backdrop-filter: blur(10px);
}
.btn3d {
  position: relative;
  top: -6px;
  border: 0;
  transition: all 40ms linear;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
  -moz-outline-style: none;
  outline: medium none;
}
.btn:hover {
  color: rgb(0, 112, 176) !important;
}

.btn3d:active,
.btn3d.active {
  top: 2px;
}
.btn3d.btn-primary {
  /* box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #4d5bbe, 0 8px 8px 1px rgba(0, 0, 0, 0.5); */
  box-shadow: 0 0 0 1px rgb(146, 146, 148) inset,
    0 0 0 2px rgb(255 255 255 / 15%) inset, 0 8px 0 0 rgb(146, 146, 148),
    0 8px 8px 1px rgb(0 0 0 / 50%);
  /* background-color: #4274d7; */
  background-color: rgb(63, 149, 131);
}

.btn3d.btn-primary:active,
.btn3d.btn-primary.active {
  box-shadow: 0 0 0 1px rgb(146, 146, 148) inset,
    0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  background-color: rgb(63, 149, 131);
}
#ghost {
  z-index: 5;
}
.fitScreen {
  max-width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.myModal {
  width: 320px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  background-color: white;
}

.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
</style>


