<template >
  <div>
    <div
      class="row m-0 fixed-bottom time"
      style="
        height: 50px;
        max-width: 590px;
        margin: 0 auto !important;
        z-index: 2;
      "
    >
      <div class="col-2 text-center errorsTimeMap">
        {{ errorNumber }}
        <span id="erreurs">{{ lang }}{{ translation.mistakes }}</span>
      </div>
      <div class="col-3 text-center errorsTimeMap pr-1">
        {{ pointNumForEvolution }}/{{ Object.values(pointsArrayOrder).length }}
        <span id="evolution">Evolution</span>
      </div>
      <div class="col-5 text-center errorsTimeMap pl-0">
        {{ secondsToTime }}
        <span id="runningTime">{{ translation.runningTime }}</span>
      </div>
      <div
        class="col-2 text-center errorsTimeMap pl-0"
        @click="slideMapInn"
        style="color: #0070b0"
      >
        <span id="carte">{{ translation.map }}</span>
        <svg
          class="bi bi-map"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M15.817.613A.5.5 0 0116 1v13a.5.5 0 01-.402.49l-5 1a.502.502 0 01-.196 0L5.5 14.51l-4.902.98A.5.5 0 010 15V2a.5.5 0 01.402-.49l5-1a.5.5 0 01.196 0l4.902.98 4.902-.98a.5.5 0 01.415.103zM10 2.41l-4-.8v11.98l4 .8V2.41zm1 11.98l4-.8V1.61l-4 .8v11.98zm-6-.8V1.61l-4 .8v11.98l4-.8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="gameParameters.anniversary === true"
      class="m-2 p-2 border rounded bg-white"
    >
      {{ allLetters }}
    </div>

    <div
      v-if="rowPhotoMissingWord"
      style="
        display: block;
        margin: 0px;
        padding: 0px;
        min-height: calc(100vh - 150px);
        margin-bottom: 55px;
        color: white;
      "
      class="mt-4 container d-flex"
    >
      <div class="row p-0 m-0" style="max-width: 590px">
        <div
          class="row align-items-center justify-content-center align-self-center m-0 p-0"
          style="min-height: 20%"
        >
          <div class="col-12 p-0 m-0">
            <p class="text-center px-3 h4">{{ translation.pointNumber }}</p>
          </div>
          <div class="font-weight-bold h4 text-center pointNumber my-3">
            {{
              Object.values(points)[pointsArrayOrder[pointNum] - 1].pointNumber
            }}
          </div>
          <div class="col-12 p-0 m-0">
            <p class="px-2 text-center">{{ translation.goThere }}</p>
          </div>
        </div>
        <div
          class="row align-items-center justify-content-center align-self-center m-0 p-0"
          style="min-height: 40%"
        >
          <img id="imgUrl" class="align-center" width="95%" :src="getImgUrl" />
        </div>
        <div
          class="row align-items-center justify-content-center align-self-center m-0 p-0"
          style="min-height: 15%; width: 100vw; margin-bottom: 50px !important"
        >
          <div
            class="col-1 pl-2 pr-0 text-right"
            style="font-size: 30px; color: #0070b0; margin-top: 11px"
            @click="showAlertSkipPoint"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              fill="currentColor"
              class="bi bi-skip-forward-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </div>
          <div class="mt-3 col-9 pr-1">
            <input
              class="center fullWidth text-center"
              aria-describedby="missingWordAnswer"
              :placeholder="translationMissingWord"
              name="missingWordAnswer"
              label="missingWordAnswer"
              id="missingWordAnswer"
              v-model="missingWordAnswer"
              required
              style="
                border-radius: 36px;
                height: 50px;
                border: 1px #0070b0 solid;
                width: 100%;
              "
            />
          </div>
          <div
            class="col-2 pl-0"
            style="
              font-size: 40px;
              color: #0070b0;
              padding: 0px;
              margin-top: 7px;
            "
          >
            <svg
              class="bi bi-arrow-right-circle-fill"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              @click="checkMissingWord"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      id="questionDiv"
      v-if="rowPhotoMissingWord === false"
      style="display: block"
      align="center"
    >
      <div class="my-4 px-1">
        <p class="question px-3" right>
          {{ questionSuffled[questionNumber].question }}
        </p>
      </div>
      <div class="mb-5">
        <div :key="answer.id" v-for="answer in getAnswerArray">
          <button
            class="fullWidth colorBtn btn font-weight-bold mb-3"
            @click="checkAnswer(answer)"
          >
            {{ answer }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="showPointInformation === true && rowPhotoMissingWord === false"
      class="over"
    >
      <div class="myModal p-4 mb-5 modalInformationPoint">
        <div class="container">
          <div class="row">
            <div class="col-12 align-self-start">
              <div class="mb-4" right>
                <strong>{{ translation.didYouKnow }}</strong>
              </div>
            </div>

            <div class="col-12 align-self-center">
              <div style="white-space: pre-line; font-size: 18px">
                <!-- {{
                  Object.values(this.points)[
                    this.pointsArrayOrder[this.pointNum] - 1
                  ].pointInformation
                }} -->
                {{ newInformationPointString }}
              </div>
              <div>
                <audio
                  v-if="
                    Object.values(this.points)[
                      this.pointsArrayOrder[this.pointNum] - 1
                    ].audioUrl
                  "
                  id="audio"
                  controls
                  autoplay
                  class="mt-3"
                  style="width: 100%"
                >
                  <source :src="audioSrc" type="audio/mp3" />
                </audio>
              </div>
            </div>
            <div class="col-12 align-self-end">
              <button
                class="fullWidth colorBtn btn font-weight-bold mt-3"
                style="background-color: #008726; margin-bottom: 50px"
                @click="fadeInDiv"
              >
                {{ translation.goToQuestions }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="alertSkipPoint" class="over">
      <div class="myModal p-4 mb-5 red container d-flex">
        <div class="row justify-content-center align-self-center">
          <div class="h5 mb-4 text-center col-12">
            {{ translation.skipPointBeware }}
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="width: 100%"
              @click="
                skipPoint();
                hideImageQuestion();
              "
            >
              {{ translation.skipPoint }}
            </div>
          </div>
          <div class="col-6">
            <div
              class="font-weight-bold h6 text-center"
              style="
                width: 100%;
                padding-top: 9px;
                color: red;
                background: white;
                height: 38px;
                border-radius: 30px;
              "
              @click="alertSkipPoint = false"
            >
              {{ translation.back }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dialogCorrectAnswer" class="over">
      <div
        class="myModal p-4 mb-5 green container d-flex"
        @click="hideImageQuestion"
      >
        <div class="row justify-content-center align-self-center">
          <audio id="audio" controls autoplay style="display: none">
            <source src="../../static/sounds/applause.mp3" type="audio/mp3" />
          </audio>
          <div
            class="h2 mb-4 text-center col-12"
            style="font-family: 'Slackey', cursive"
          >
            {{ translation.wellDone }}
          </div>
          <div class="mb-4 text-center h5 col-12">
            {{ translation.correctAnswer }}
          </div>
          <div class="col-12 text-center">
            {{ translation.continue }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="dialogWrongAnswer" class="over">
      <div
        class="myModal p-4 mb-5 red container d-flex"
        @click="(dialogWrongAnswer = false), (missingWordAnswer = '')"
      >
        <div
          class="row justify-content-center align-self-center"
          style="color: white"
        >
          <div
            class="col-12 h2 mb-4 text-center"
            style="font-family: 'Slackey', cursive"
          >
            Oups!
          </div>
          <div class="col-12 mb-4 text-center h5">
            {{ translation.wrongAnswer }}
          </div>
          <audio id="audio" controls autoplay style="display: none">
            <source src="../../static/sounds/boo.mp3" type="audio/mp3" />
          </audio>
          <div class="col-12 text-center">
            {{ translation.tryAgain }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="showLastDialog" class="over container d-flex">
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div class="confetti"></div>
      <div
        class="myModal p-2 green d-flex row justify-content-center align-self-center align-items-center mt-0"
        @click="showResults"
      >
        <audio id="audio" controls loop autoplay style="display: none">
          <source src="../../static/sounds/endOfGame.mp3" type="audio/mp3" />
        </audio>
        <div
          class="h4 p-2 col-12 text-center"
          style="font-family: 'Slackey', cursive"
        >
          Bravo {{ userName }} !
        </div>
        <div class="p-2 col-12">
          {{ translation.visitOf }}
          {{ gameParameters.navigationName.toUpperCase() }} !
        </div>
        <div class="border border-white px-4 py-2">
          <div class="p-2 col-12 font-weight-bold">
            {{ translation.yourResults }}
          </div>
          <div class="p-2 col-12 font-italic">
            {{ translation.errorNumber }} {{ errorNumber }}
          </div>
          <div class="p-2 col-12 font-italic">
            {{ translation.totalTime }} {{ gameSecondToTime }}
          </div>
        </div>

        <div class="p-2 col-12">{{ translation.goToRanking }}</div>
      </div>
    </div>

    <div id="map">
      <span
        @click="removeMap"
        style="
          position: absolute;
          top: -2px;
          font-size: 53px;
          z-index: 100;
          color: white;
          right: 15px;
        "
      >
        <button
          type="button"
          class="close"
          aria-label="Close"
          style="font-size: 48px; color: red"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </span>
      <!-- <span
        @click="removeMap"
        style="
          position: absolute;
          top: -5px;
          font-size: 53px;
          z-index: 100;
          color: white;
          right: 15px;
        "
      >
        <svg
          class="bi bi-x-circle"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
          />
          <path
            fill-rule="evenodd"
            d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
          />
          <path
            fill-rule="evenodd"
            d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
          />
        </svg>
      </span> -->
      <GmapMap
        :center="{
          lat: Number(mapParameters.lat),
          lng: Number(mapParameters.lng),
        }"
        :zoom="Number(mapParameters.zoom)"
        map-type-id="satellite"
        :tilt="0"
        style="width: 100vw; height: 100vh"
        :styles="myMapStyle"
        :options="{
          zoomControl: false,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: true,
          fullscreenControl: false,
          disableDefaultUi: false,
        }"
      >
        <GmapMarker
          style="margin-right: 10px !important"
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :label="{ text: `${index + 1}`, color: 'white', fontSize: '9px' }"
          @click="center = m.position"
        />
      </GmapMap>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      alertSkipPoint: false,
      myMapStyle: {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      firstQuestion: 1,
      showPointInformation: false,
      secondsToTime: "00:00:00",
      gameSecondToTime: "",
      totalSeconds: 0,
      startTime: Date.now(),
      endTime: "",
      showMap: false,
      showLastDialog: false,
      key: "",
      questiomMultipleChoices: true,
      rowPhotoMissingWord: true,
      dialogWrongAnswer: false,
      dialogCorrectAnswer: false,
      missingWordAnswer: "",
      loading: false,
      src: "",
      timeNow: "",
      questionNumber: -1,
      errorNumber: 0,
      pointNum: 0,
      pointNumForEvolution: 1,
      letter: "",
      allLetters: "",
      gameTimeInSecond: "",
      userKey: "",
      translationMissingWord: "Mot manquant sur l'image",
      translation: {
        back: "Fermer",
        skipPoint: "Passer au point suivant",
        skipPointBeware:
          "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, 10 minutes seront ajoutées à votre temps final.",
        mistakes: "Erreurs",
        runningTime: "Temps écoulé",
        map: "Carte",
        pointNumber: "Sur la carte, repérez le nº",
        goThere:
          "Rendez-vous sur place et trouvez l'inscription qui se trouve sur l'image ci-dessous.",
        didYouKnow: "Le saviez-vous ?",
        goToQuestions: "Allons aux questions !",
        wellDone: "Bien joué !",
        correctAnswer: "C'est une bonne réponse !",
        continue: "Cliquez pour continuer.",
        wrongAnswer: "Ce n'est pas la bonne réponse.",
        tryAgain: "Cliquez pour réessayer.",
        visitOf: "Nous espérons que vous avez apprécié votre visite de",
        yourResults: "Vos résultats sont :",
        errorNumber: "Nombre d'erreurs :",
        totalTime: "Temps total :",
        goToRanking:
          "Avant de partir, vous pouvez aller jeter un coup d'oeil sur les résultats et voir en quelle positon vous êtes arrivé(e) !",
      },
    };
  },
  mounted() {
    if (this.gameParameters) {
      console.log("ok");
    } else {
      console.log("bug");
      this.reopenGame();
    }
    this.userKey = localStorage.getItem("userKey");
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.countSeconds();
    if (localStorage.getItem("questionNumber")) {
      if (localStorage.getItem("pointNumberOuchui")) {
        this.pointNum = localStorage.getItem("pointNumberOuchui");
        this.pointNumForEvolution = localStorage.getItem(
          "pointNumForEvolutionOuchui"
        );
      } else {
        this.pointNum = 0;
        this.pointNumForEvolution = 1;
      }
      this.startTime = localStorage.getItem("startTimeOuchui");
      if (localStorage.getItem("errorNumber")) {
        this.errorNumber = localStorage.getItem("errorNumber");
      } else {
        this.errorNumber = 0;
      }
      this.questionNumber = JSON.parse(localStorage.getItem("questionNumber"));
      this.firstQuestion = JSON.parse(localStorage.getItem("firstQuestion"));
      this.rowPhotoMissingWord = JSON.parse(
        localStorage.getItem("rowPhotoMissingWord")
      );
    }
    // Show loading animation.
    setTimeout(function () {
      // var playPromise = document.getElementById("audio").play();
      var playPromise = document.querySelector("audio");
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {})
          .catch((error) => {
            // Auto-play was prevented
            console.log("errrrrrrorrrr playing");
            // Show paused UI.
          });
      }
    }, 500);
    // }
  },
  computed: {
    newInformationPointString() {
      return this.points[
        this.pointsArrayOrder[this.pointNum]
      ].pointInformation.replaceAll("\n", "\n");
    },
    audioSrc() {
      if (
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .audioUrl
      ) {
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].audioUrl;
      } else {
        return null;
      }
    },
    numberOfQUestionPerPoint() {
      return Math.floor(
        this.$store.getters.questionsShuffled.length /
          (this.$store.getters.points.length - 1)
      );
    },
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    userName() {
      return this.$store.getters.user.userName;
    },
    mapParameters() {
      return this.$store.getters.mapParameters;
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    group() {
      return this.$store.getters.group;
    },
    points() {
      return this.$store.getters.points;
    },
    markers() {
      var markers = this.$store.getters.markers;
      var markersArray = [];
      markers.forEach((marker) => {
        markersArray.push({ position: { lat: marker.lat, lng: marker.lng } });
      });
      return markersArray;
    },
    questions() {
      // console.log(
      //   "this.$store.getters.questions",
      //   this.$store.getters.questions
      // );
      return this.$store.getters.questions;
    },
    getImgUrl() {
      if (
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .imageBase64
      ) {
        // console.log("Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1].imageBase64", Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
        //   .imageBase64)
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].imageBase64;
      } else {
        //  console.log("Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1].imageUrl", Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
        //   .imageUrl)
        return Object.values(this.points)[
          this.pointsArrayOrder[this.pointNum] - 1
        ].imageUrl;
      }
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        // console.log(
        //   "point order from firebase",
        //   this.$store.getters.pointsArrayOrder
        // );
        return this.$store.getters.pointsArrayOrder;
      }
    },
    questionSuffled() {
      if (localStorage.getItem("questionSuffled")) {
        return JSON.parse(localStorage.getItem("questionSuffled"));
      } else {
        return this.$store.getters.questionsShuffled;
      }
    },
    getAnswerArray() {
      let answerArray;
      answerArray = [
        this.questionSuffled[this.questionNumber].wrongAnswer1,
        this.questionSuffled[this.questionNumber].wrongAnswer2,
        this.questionSuffled[this.questionNumber].wrongAnswer3,
        this.questionSuffled[this.questionNumber].correctAnswer,
      ];
      let currentIndex = answerArray.length,
        temporaryValue,
        randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = answerArray[currentIndex];
        answerArray[currentIndex] = answerArray[randomIndex];
        answerArray[randomIndex] = temporaryValue;
      }
      return answerArray;
    },
  },
  methods: {
    reopenGame() {
      if (localStorage.getItem("navigationName")) {
        this.$router.push("/" + localStorage.getItem("navigationName"));
        location.reload();
      } else {
        this.$router.push("/");
        location.reload();
      }
    },
    skipPoint() {
      this.alertSkipPoint = false;
      this.startTime = this.startTime - 60 * 10 * 1000;
      // this.dialogCorrectAnswer = true;
      localStorage.setItem("pointNumberOuchui", this.pointNum);
      localStorage.setItem(
        "pointNumForEvolutionOuchui",
        this.pointNumForEvolution
      );
      this.$store.dispatch("addEvolutionToDB", {
        pointNumber: this.pointNum,
        userKey: this.userKey,
        navigationName: this.gameParameters.navigationName,
      });
    },
    showAlertSkipPoint() {
      this.alertSkipPoint = true;
    },
    translate(lang) {
      if (lang == "fr") {
        this.translationMissingWord = "Mot manquant sur l'image";
        this.translation = {
          back: "Fermer",
          skipPoint: "Passer au point suivant",
          skipPointBeware:
            "Attention! Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant, 10 minutes seront ajoutées à votre temps final.",
          mistakes: "Erreurs",
          runningTime: "Temps écoulé",
          map: "Carte",
          pointNumber: "Sur la carte, repérez le nº",
          goThere:
            "Rendez-vous sur place et trouvez l'inscription qui se trouve sur l'image ci-dessous.",
          didYouKnow: "Le saviez-vous ?",
          goToQuestions: "Allons aux questions !",
          wellDone: "Bien joué !",
          correctAnswer: "C'est une bonne réponse !",
          continue: "Cliquez pour continuer.",
          wrongAnswer: "Ce n'est pas la bonne réponse.",
          tryAgain: "Cliquez pour réessayer.",
          visitOf: "Nous espérons que vous avez apprécié votre visite de",
          yourResults: "Vos résultats sont :",
          errorNumber: "Nombre d'erreurs :",
          totalTime: "Temps total :",
          goToRanking:
            "Avant de partir, vous pouvez aller jeter un coup d'oeil sur les résultats et voir en quelle positon vous êtes arrivé(e) !",
        };
      } else {
        this.translationMissingWord = "Missing word on the picture";
        this.translation = {
          back: "Close",
          skipPoint: "Jump to the next point",
          skipPointBeware:
            "Beware! If you don't find the insciption, you can jump to the next point, 10 minutes will be added to your total time.",
          mistakes: "Mistakes",
          runningTime: "Elapsed time",
          map: "Map",
          pointNumber: "On the map, find the nº",
          goThere: "Go there, and find this inscription ",
          didYouKnow: "Did you know?",
          goToQuestions: "Go to questions!",
          wellDone: "Well done!",
          correctAnswer: "The answer is correct!",
          continue: "Click to continue.",
          wrongAnswer: "That's not the correct answer.",
          tryAgain: "Click to try again!",
          visitOf: "We hope you enjoyed your visit of",
          yourResults: "Your results are :",
          errorNumber: "Mistakes :",
          totalTime: "Total time :",
          goToRanking:
            "Before you leave, you can go take a look at the results and see where you are!",
        };
      }
    },

    slideMapInn() {
      document.getElementById("map").className = "showMap";
    },
    removeMap() {
      document.getElementById("map").classList.remove("showMap");
    },
    fadeInDiv() {
      this.showPointInformation = false;
      if (document.getElementById("questionDiv")) {
        document.getElementById("questionDiv").className = "fadeIn";
      } else {
        console.log("[fadeInDiv] no questionDiv");
      }
    },
    countSeconds() {
      setInterval((_) => {
        this.timeNow = Date.now();
        this.totalSeconds = Math.floor((this.timeNow - this.startTime) / 1000);
        var pad = function (num, size) {
            return ("000" + num).slice(size * -1);
          },
          time = parseFloat(this.totalSeconds).toFixed(3),
          hours = Math.floor(time / 60 / 60),
          minutes = Math.floor(time / 60) % 60,
          seconds = Math.floor(time - minutes * 60);

        this.secondsToTime =
          pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
      }, 1000);
    },
    showResults() {
      // console.log("showResults");
      // localStorage.clear();
      this.$router.push("/results");
      // this.$store.dispatch("getResults", this.gameParameters.navigationName);
    },
    checkMissingWord() {
      if (
        this.missingWordAnswer.toLowerCase() ===
        Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
          .missingWord
      ) {
        this.dialogCorrectAnswer = true;
      } else {
        this.dialogWrongAnswer = true;
        this.errorNumber++;
        localStorage.setItem("errorNumber", this.errorNumber);
      }
    },
    checkAnswer(answer) {
      if (document.getElementById("questionDiv")) {
        document.getElementById("questionDiv").classList.remove("fadeIn");
      } else {
        console.log("[checkAnswer] No questionDiv");
      }
      if (answer === this.questionSuffled[this.questionNumber].correctAnswer) {
        if (this.gameParameters.anniversary === true) {
          if (this.$store.getters.lettersArray[this.pointNum] != " ") {
            this.letter = this.$store.getters.lettersArray[this.pointNum];
          } else {
            this.letter = "_";
          }
          if (
            this.gameParameters.anniversary === true &&
            this.firstQuestion === this.numberOfQUestionPerPoint
          ) {
            this.allLetters = this.allLetters + " - " + this.letter;
          } else {
          }
        }
        var allPoints = Object.keys(this.points);
        if (
          Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
            .pointNumber == this.gameParameters.lastPoint
        ) {
          this.showLastDialog = true;
          // GET THE TOTAL TIME OF THE GAME
          this.endTime = Date.now();
          this.gameTimeInSecond = Math.floor(
            (this.endTime - this.startTime) / 1000
          );
          var pad = function (num, size) {
              return ("000" + num).slice(size * -1);
            },
            time = parseFloat(this.gameTimeInSecond).toFixed(3),
            hours = Math.floor(time / 60 / 60),
            minutes = Math.floor(time / 60) % 60,
            seconds = Math.floor(time - minutes * 60);

          this.gameSecondToTime =
            pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2);
          // SEND THE RESULTS TO THE DATABASE
          this.$store.dispatch("checkResults", {
            errorNumber: this.errorNumber,
            gameSecondToTime: this.gameSecondToTime,
            gameTimeInSecond: this.gameTimeInSecond,
          });
        } else {
          this.dialogCorrectAnswer = true;
        }
      } else {
        this.dialogWrongAnswer = true;
        this.errorNumber++;
        localStorage.setItem("errorNumber", this.errorNumber);
      }
    },
    // checkIfLastPoint() {
    //   if (
    //     Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
    //       .pointNumber == this.gameParameters.lastPoint
    //   ) {
    //     this.showLastDialog = true;
    //   } else {
    //     this.pointNum++;
    //     this.pointNumForEvolution++;
    //     localStorage.setItem("pointNumberOuchui", this.pointNum);
    //     localStorage.setItem(
    //       "pointNumForEvolutionOuchui",
    //       this.pointNumForEvolution
    //     );
    //     this.$store.dispatch("addEvolutionToDB", {
    //       pointNumber: this.pointNum,
    //       userKey: this.userKey,
    //       navigationName: this.gameParameters.navigationName,
    //     });
    //   }
    // },
    hideImageQuestion() {
      localStorage.setItem("startTimeOuchui", this.startTime);
      // ADD A FADEINN CLASS TO THE QUESTION DIV
      if (document.getElementById("questionDiv")) {
        document.getElementById("questionDiv").className = "fadeIn";
      } else {
      }
      // Here I check if I need to show the Information Page as per the game parameters
      this.dialogCorrectAnswer = false;
      // console.log("this.gameParameters.infoPage", this.gameParameters.infoPage);
      // console.log("this.firstQuestion", this.firstQuestion);
      // console.log("this.gameParameters.infoPage", this.gameParameters.infoPage);

      if (this.gameParameters.infoPage === true && this.firstQuestion === 1) {
        // console.log("b4 this.showPointInformation", this.showPointInformation);
        this.showPointInformation = true;
        // console.log(
        //   "After this.showPointInformation",
        //   this.showPointInformation
        // );

        setTimeout((_) => {
          this.showButtonInfoScreen = true;
        }, 3000);
      }
      this.missingWordAnswer = "";
      // ***********************************************ADDED THIS JUST FOR THE RANDOM QUESTIONS
      if (this.gameParameters.randomQuestions === true) {
        if (this.firstQuestion < this.numberOfQUestionPerPoint + 1) {
          this.questionNumber++;
          localStorage.setItem("questionNumber", this.questionNumber);
          this.firstQuestion++;
          localStorage.setItem("firstQuestion", this.firstQuestion);
          this.rowPhotoMissingWord = false;
          localStorage.setItem("rowPhotoMissingWord", this.rowPhotoMissingWord);
        } else {
          localStorage.setItem("questionNumber", this.questionNumber);
          this.rowPhotoMissingWord = !this.rowPhotoMissingWord;
          localStorage.setItem("rowPhotoMissingWord", this.rowPhotoMissingWord);

          if (this.rowPhotoMissingWord) {
            this.pointNum++;
            this.pointNumForEvolution++;
            localStorage.setItem("pointNumberOuchui", this.pointNum);
            localStorage.setItem(
              "pointNumForEvolutionOuchui",
              this.pointNumForEvolution
            );
            this.$store.dispatch("addEvolutionToDB", {
              pointNumber: this.pointNum,
              userKey: this.userKey,
              navigationName: this.gameParameters.navigationName,
            });
            this.firstQuestion = 1;
            localStorage.setItem("firstQuestion", 1);
          }
        }
      } else {
        // ******************************************************************************************
        if (
          Object.values(this.points)[this.pointsArrayOrder[this.pointNum] - 1]
            .pointNumber ==
          this.questionSuffled[this.questionNumber + 1].pointLinked
        ) {
          this.questionNumber++;

          localStorage.setItem("questionNumber", this.questionNumber);

          this.firstQuestion++;
          localStorage.setItem("firstQuestion", this.firstQuestion);

          this.rowPhotoMissingWord = false;
          localStorage.setItem("rowPhotoMissingWord", this.rowPhotoMissingWord);
        } else {
          localStorage.setItem("questionNumber", this.questionNumber);
          this.rowPhotoMissingWord = !this.rowPhotoMissingWord;
          localStorage.setItem("rowPhotoMissingWord", this.rowPhotoMissingWord);

          if (this.rowPhotoMissingWord) {
            this.pointNum++;
            this.pointNumForEvolution++;
            localStorage.setItem("pointNumberOuchui", this.pointNum);
            localStorage.setItem(
              "pointNumForEvolutionOuchui",
              this.pointNumForEvolution
            );

            this.$store.dispatch("addEvolutionToDB", {
              pointNumber: this.pointNum,
              userKey: this.userKey,
              navigationName: this.gameParameters.navigationName,
            });
            this.firstQuestion = 1;
            localStorage.setItem("firstQuestion", 1);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#erreurs {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 23px);
}
#evolution {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 23px);
}
#runningTime {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 52px);
}
#carte {
  font-size: 12px;
  position: absolute;
  top: 27px;
  left: calc(50% - 23px);
}

.center {
  display: block;
  margin: 0px auto;
}
#mapButton {
  width: 30px;
  position: absolute;
  top: 4px;
  right: -10px;
  margin-right: calc(50vw - 155px);
  font-size: 25px;
  color: white;
  /* border: solid 1px black; */
  width: 42px;
  height: 40px;
  padding: 5px;
  /* background: white; */
  z-index: 5;
}
#map {
  position: fixed;
  top: -100vh;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden;
  transition: all 1s;
}
.showMap {
  position: fixed;
  top: 0 !important;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  overflow: hidden !important;
  transition: all 1s;
}

#imgUrl {
  display: block;
  margin: 0 auto;
}
.errorsTimeMap {
  background: rgba(255, 255, 255, 1);
  border: none;
  font-size: 20px;
  padding-top: 5px;
}
.errorsTimeMap > svg {
  position: relative;
  bottom: 3px;
}
.pointNumber {
  margin: 0 auto;
  border: #d41a1b solid 2px;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 4px;
}
.verticalAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 80%;
}
.myModal {
  min-height: 200px;
  overflow: auto;
  width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  color: white;
}
.modalInformationPoint {
  background: #e7ffd5;
  box-shadow: none;
  border: solid rgb(9, 133, 235) 3px;
  margin-top: 1vh;
  width: 98vw;
  font-size: 20px;
  height: 98vh;
  overflow: auto;
  color: black;
}
.red {
  background-color: #ff3838;
  border: solid white 2px;
}
.green {
  background-color: #13bb43;
  border: 2px white solid;
}
.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
.colorBtn {
  border-radius: 36px;
  min-height: 56px;
  color: white;
  background: #008726;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.question {
  text-align: center;
  font-family: "Assistant", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: black;
}
.btnAnswer {
  margin: 0 auto;
  width: 320px;
  height: 60px;
  border-radius: 35px;
  font-size: 20px;
  color: white;
  background: rgb(64, 150, 83) !important;
}
.title {
  font-size: 25px;
  text-align: right;
  color: black;
}
.userName {
  font-size: 18px;
  text-align: left;
  color: black;
}
.time {
  letter-spacing: 0.05em;
  font-size: 24px;
  font-family: "Cabin", sans-serif;
  text-align: center;
  color: rgb(14, 15, 14, 0.8);
}
.startTime {
  letter-spacing: 0.05em;
  font-size: 40px;
  padding: 5px 0;
  font-family: "Inconsolata", monospace;
  color: #ffffff;
  text-align: center;
  position: relative;
  left: 50%;
  margin-top: 50px;
  top: -24px;
  transform: translate(-50%, -50%);
  color: #daf6ff;
}
.hebrewText {
  text-align: right;
  font-size: 15px;
  padding-right: 5px;
  color: black;
}

.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.confetti {
  width: 15px;
  height: 15px;
  background-color: #b1f70f;
  position: absolute;
  left: 50%;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
}
.confetti:nth-child(1) {
  background-color: #faf623;
  left: 10%;
  animation-delay: 0;
}
.confetti:nth-child(2) {
  background-color: #129dee;
  left: 20%;
  animation-delay: -5s;
}
.confetti:nth-child(3) {
  background-color: #e21f0d;
  left: 30%;
  animation-delay: -3s;
}
.confetti:nth-child(4) {
  background-color: #f1dc6f;
  left: 40%;
  animation-delay: -2.5s;
}
.confetti:nth-child(5) {
  background-color: #039cf5;
  left: 50%;
  animation-delay: -4s;
}
.confetti:nth-child(6) {
  background-color: #f52916;
  left: 60%;
  animation-delay: -6s;
}
.confetti:nth-child(7) {
  background-color: #5cf24e;
  left: 70%;
  animation-delay: -1.5s;
}
.confetti:nth-child(8) {
  background-color: #d717e9;
  left: 80%;
  animation-delay: -2s;
}
.confetti:nth-child(9) {
  background-color: #f30e34;
  left: 90%;
  animation-delay: -3.5s;
}
.confetti:nth-child(10) {
  background-color: #7cf24e;
  left: 100%;
  animation-delay: -2.5s;
}
.confetti:nth-child(11) {
  background-color: #f52916;
  left: 15%;
  animation-delay: -6.5s;
}
.confetti:nth-child(12) {
  background-color: #5cf24e;
  left: 25%;
  animation-delay: -1.8s;
}
.confetti:nth-child(13) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -2.5s;
}
.confetti:nth-child(14) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -3.8s;
}
.confetti:nth-child(15) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.2s;
}
.confetti:nth-child(16) {
  background-color: #d717e9;
  left: 75%;
  animation-delay: -3.5s;
}
.confetti:nth-child(18) {
  background-color: #f30e34;
  left: 85%;
  animation-delay: -6.8s;
}
.confetti:nth-child(17) {
  background-color: #7cf24e;
  left: 55%;
  animation-delay: -4.9s;
}

@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }
  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }
  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(7vw, 60vh);
  }
  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-25vw, 80vh);
  }
  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(20vw, 110vh);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
