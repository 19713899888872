<template >
  <div style="color: white; font-weight: 100 !important">
    <div
      class="row align-items-center justify-content-center align-self-center m-0 p-0 mt-3"
    >
      <div class="col-12 p-0 m-0">
        <p class="text-center px-3 h4 font-weight-bold">
          {{ translation.pointNumber }}
        </p>
      </div>
      <div class="font-weight-bold h4 text-center pointNumber my-2">
        {{
          Object.values(points)[pointsArrayOrder[$parent.pointNum] - 1]
            .pointNumber
        }}
      </div>
    </div>
    <div class="col-12 px-4 m-0 mb-3">
      <div class="px-2 text-center font-weight-bold" style="font-size: 25px">
        {{ lang }} {{ translation.goThere }}
      </div>
      <div class="px-2 text-center font-weight-bold" style="font-size: 25px">
        {{ translation.whatCanBeSeen }}
      </div>
      <div class="px-2 text-center font-weight-normal" style="font-size: 20px">
        {{ translation.explaination }}
      </div>
    </div>

    <div
      v-for="(item, index) in arrayOfImages"
      :key="index"
      @click="checkAnswer(index, $event)"
      class="imageDiv"
    >
      <!-- <div
                    v-bind:class="[
                      slide === index ? activeClass : '',
                      notActive,
                    ]"
                  ></div> -->
      <img :src="item" alt="" style="width: 100%" />
    </div>

    <!-- <button
      ref="checkAnswerButton"
      class="fullWidth font-weight-bold mt-5"
      @click="checkAnswer()"
    >
      {{ translation.checkAnswer }}
    </button> -->
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeClass: "activePoint",
      notActive: "smallPoint",
      activeClassPoint: "activeClassPoint",
      notActivePoint: "numbersInCercle",
      slide: 0,
      sliding: null,
      key: "",
      translation: "",
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
      wongAnswerSound: require("../../static/sounds/wrongAnswer.mp3"),
    };
  },
  mounted() {},
  computed: {
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    arrayOfImages() {
      let arrayOfImages;
      if (localStorage.getItem("arrayOfImagesBase64")) {
        arrayOfImages = JSON.parse(localStorage.getItem("arrayOfImagesBase64"));
      } else {
        arrayOfImages = this.$store.getters.arrayOfImagesBase64;
      }
      let currentIndex = arrayOfImages.length,
        temporaryValue,
        randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = arrayOfImages[currentIndex];
        arrayOfImages[currentIndex] = arrayOfImages[randomIndex];
        arrayOfImages[randomIndex] = temporaryValue;
      }
      return arrayOfImages;
      // return this.$store.getters.arrayOfImagesBase64;
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    pointsArrayOrder() {
      if (localStorage.getItem("pointsArrayOrder")) {
        return JSON.parse(localStorage.getItem("pointsArrayOrder"));
      } else {
        return this.$store.getters.pointsArrayOrder;
      }
    },
    points() {
      return this.$store.getters.points;
    },
  },

  methods: {
    checkAnswer(index, event) {
      // REPONSE EST JUSTE
      if (
        index ==
        this.arrayOfImages.indexOf(
          this.points[this.pointsArrayOrder[this.$parent.pointNum]].imageBase64
        )
      ) {
        event.target.parentElement.classList.add("greenBorder");
        this.$parent.errorNumber = this.$parent.errorNumber + 5;
        this.$parent.showStarUp("5+");
        this.$parent.showCorrectAnswerIcon = true;
        var audio = new Audio(this.correctAnswerSound);
        audio.play();
        this.$parent.checkElementPicked();
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        setTimeout(() => {
          this.$parent.showPointInformation = true;
          localStorage.setItem(
            "showPointInformation",
            this.showPointInformation
          );
          this.$parent.showCorrectAnswerIcon = false;
          this.$parent.showButtonInfoScreen = true;
          // setTimeout(() => {
          //   this.$parent.showButtonInfoScreen = true;
          // }, 3000);
          this.$parent.showPickedItem = false;
          localStorage.setItem("showPickedItem", this.showPickedItem);
          this.$parent.showMainScreen = false;
          localStorage.setItem("showMainScreen", this.showMainScreen);
          // this.nextPointNumber();
        }, 1000);
        // REPONSE EST FAUSSE
      } else {
        event.target.parentElement.classList.add("redBorder");
        this.$parent.errorNumber = this.$parent.errorNumber - 10;
        this.$parent.$refs.pointsCounting.classList.add("redColor");
        localStorage.setItem("errorNumber", this.$parent.errorNumber);
        this.$parent.showWongAnswerIcon = true;

        audio = new Audio(this.wongAnswerSound);
        audio.play();

        setTimeout(() => {
          //   console.log("b4 remove redborder");
          event.target.parentElement.classList.remove("redBorder");
          // this.$refs.checkAnswerButton.classList.remove("redBorder");
          this.$parent.$refs.pointsCounting.classList.remove("redColor");
          this.$parent.showWongAnswerIcon = false;
        }, 1000);
      }
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            checkAnswer: "Vérifier",
            chooseImageSeen: "Depuis ce point, je vois le visuel ci-dessus",
            imageNumber: "Visuel nº",
            goThere: "Rends-toi sur place.",
            pointNumber: "Sur la carte, trouve le numéro:",
            explaination:
              "Parmi les " +
              this.arrayOfImages.length +
              " visuels ci-dessous, lequel vois-tu de ce point ?",
          };
          break;
        case "en":
          this.translation = {
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
          };
          break;
        case "de":
          this.translation = {
            checkAnswer: "Überprüfen",
            chooseImageSeen: "Von diesem Punkt aus sehe ich das obige Bild",
            imageNumber: "Bild Nr.",
            goThere: "Gehe dorthin.",
            pointNumber: "Finde die Nummer auf der Karte:",
            explaination:
              "Welches der folgenden " +
              this.arrayOfImages.length +
              " Bilder siehst du von diesem Punkt aus?",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            checkAnswer: "Check answer",
            chooseImageSeen: "From this point, I can see the image above",
            imageNumber: "Image no.",
            goThere: "Go there.",
            pointNumber: "On the map, find the number:",
            explaination:
              "Among the " +
              this.arrayOfImages.length +
              " images below, which one can you see from this point?",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
.pointNumber {
  margin: 0 auto;
  border: yellow solid 2px;
  border-radius: 100px;
  height: 55px;
  width: 55px;
  font-size: 36px;
  padding-right: 1px;
  padding-top: 4px;
  font-weight: bold;
}
.imageDiv {
  width: 86%;
  display: block;
  margin: 0 auto;
  margin-bottom: 22px;
  border: 5px solid white;
}
.fullWidth {
  width: 80%;
  display: block;
  margin: 0px auto;
  border-radius: 50px;
  background-color: #83cb97;
  margin-bottom: 45px;
  height: 56px;
  border: solid white 5px;
  color: white;
  font-size: 20px;
}
.greenBorder {
  border: solid rgb(5, 253, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative;*/
}
.redBorder {
  border: solid rgb(253, 5, 5) 5px !important;
  /* height: 55px !important;
  width: 55px !important;
  bottom: 8px;
  font-size: 27px !important;
  position: relative; */
}
.spanNumbers {
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Cabin";
  padding-top: 3px;
}
.rowClickablePoints {
  display: flex;
  justify-content: center;
}
.arrowRight {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-top-width: 5px;
  border-right-width: 0px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  border-color: white;
  border-style: solid;
  top: 70px;
}
.arrowLeft {
  height: 50px;
  width: 10px;
  background: #83cb97;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 0px;
  border-color: white;
  border-style: solid;
  top: 70px;
}

.slidesCounting {
  width: auto;
  font-size: 21px;
  padding-top: 5px;
  background: #83cb978f;
  color: white;
  text-align: center;
  font-weight: bold;
  border: solid white 4px;
  border-radius: 4px;
  position: absolute;
  bottom: 229px;
  z-index: 2;
  padding: 8px;
  font-family: system-ui;
}

.bi-arrow-right-short,
.bi-arrow-left-short {
  font-size: 38px;
  color: white;
  position: relative;
  height: 40px;
  width: 40px;
  top: 0px;
}
.img-fluid {
  width: 100vw;
  height: auto;
}
#carousel-1 {
  /* background: rgb(171, 171, 171); */
  text-shadow: rgb(51 51 51) 1px 1px 2px;
  min-height: 230px;
  margin: 0px auto;
  width: 100vw;
  display: block;
  position: relative;
  /* max-width: 330px; */
  overflow: hidden;
}
.numbersInCercle {
  height: 40px;
  width: 40px;
  border-radius: 60px;
  background: #83cb97;
  opacity: 0.8;
  border: solid rgb(0 0 0) 1px;
  font-size: 22px;
  color: white;
}
.activeClassPoint {
  opacity: 0.7;
  height: 50px;
  width: 50px;
  /* border: solid red 2px; */
  position: relative;
  font-size: 28px;
  bottom: 6px;
}
.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.8;
}
.activePoint {
  opacity: 0.7;
  background: rgb(30, 255, 0);
  height: 10px;
  width: 10px;
  bottom: 3px;
  right: 2px;
  position: relative;
}
.carousel {
  width: 640px;
  height: 200px;
}
</style>



