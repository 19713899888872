<template >
  <div>
    <div class="container p-3 row m-0">
      <div id="quitResults" @click="showEmailBox = true">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            class="bi bi-house-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"
            />
            <path
              d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"
            />
          </svg>
        </span>
      </div>
      <!-- <div>
        <p class="results">{{ lang }}{{ translation.results }}</p>
      </div> -->
      <div
        :key="result.id"
        v-for="result in allResults"
        class="row resultDiv text-right"
      >
        <!-- <div v-if="result.place == 1" class="col-6 resultName">
          {{ translation.firstPlace }} :
        </div>
        <div v-else class="col-6 resultName">
          {{ translation.rank }} {{ result.place }} :
        </div> -->
        <div class="col-1">
          <div class="ranking">
            {{ result.place }}
          </div>
        </div>
        <div class="col-6">
          <div class="resultName">
            {{ result.resultsOfTheGame.userName }}
          </div>
        </div>

        <div class="col-3 text-center">
          <div class="text-right pointsScore">
            {{ result.resultsOfTheGame.errorNumber }}
          </div>
        </div>
        <div class="col-1 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            fill="currentColor"
            class="bi bi-star-fill star"
            viewBox="0 0 16 16"
          >
            <path
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />
          </svg>
        </div>

        <!-- <div class="col-12">
          {{ translation.errors }} : {{ result.resultsOfTheGame.errorNumber }}
        </div> -->
        <!-- <div class="col-12">
          {{ translation.scorePoints }} :
          {{ result.resultsOfTheGame.pointsScore }}
        </div> -->
        <!-- <div class="col-12 font-weight-bold">
          {{ translation.totalTime }} : {{ result.resultsOfTheGame.totalTime }}
        </div> -->
      </div>
      <div v-if="showEmailBox === true" class="over d-flex">
        <div
          class="myModal p-2 red d-flex row justify-content-center align-self-center align-items-center mt-0"
          v-if="!afterStars"
        >
          <div
            class="text-center"
            style="font-family: 'VarelaRound'; font-size: 25px"
          >
            {{ lang }} {{ translation.howWasIt }}
          </div>
          <star-rating
            :starSize="starSize"
            v-model="rating"
            :show-rating="false"
            style="margin-left: calc(50vw - 190px)"
            class="mb-3 mt-3"
          ></star-rating>
          <textarea
            name="review"
            rows="4"
            cols="50"
            v-model="comment"
            :placeholder="leaveMessage"
            class="p-2"
          >
          </textarea>
          <div
            class="position-relative text-center col-5 ml-2 pr-0 mb-3"
            style="margin-top: 22px"
            @click="afterStars = true"
          >
            {{ translation.noThanks }}
          </div>
          <div
            class="colorBtn btn position-relative col-5 mb-3"
            @click="sendComment"
          >
            {{ translation.send }}
          </div>
        </div>
        <div
          class="myModal p-2 red d-flex row justify-content-center align-self-center align-items-center mt-0"
          v-if="afterStars"
        >
          <!-- <div class="row"></div> -->
          <div class="row my-0" v-if="!emailSent">
            <div class="mt-0">
              <div class="mt-2 p-3 text-left pl-4">
                {{ translation.leaveEmail }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!emailSent">
            <div class="mt-2 col-10 pr-1">
              <input
                class="text-center"
                name="email"
                type="email"
                label="Email"
                id="email"
                v-model="email"
                required
                placeholder="E-mail"
                style="
                  border-radius: 12px;
                  height: 41px;
                  border: 0px solid rgb(212, 26, 27);
                  width: 97%;
                "
              />
            </div>
            <div
              class="col-1 pl-0"
              style="font-size: 40px; color: white; padding: 0px; top: -6px"
              @click="sendEmail"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                fill="currentColor"
                class="bi bi-arrow-right-circle-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
            </div>
          </div>
          <div class="row" style="width: 100vw">
            <div
              class="backToResults mb-0 col-4 pt-1 mb-3"
              @click="showEmailBox = false"
            >
              <div style="font-size: 40px; top: 2px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  fill="currentColor"
                  class="bi bi-trophy-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z"
                  />
                </svg>
              </div>
              <div>
                {{ translation.backToResults }}
              </div>
            </div>
            <div class="backToResults mb-3 col-4" @click="goToHomePage">
              <div style="font-size: 40px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="bi bi-house-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5Z"
                  />
                  <path
                    d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6Z"
                  />
                </svg>
              </div>
              <div>
                {{ translation.backToHomePage }}
              </div>
            </div>
            <div class="col-4 backToResults pt-3" @click="openWhatsapp">
              <div>
                <button class="btn bmd-btn-fab p-0">
                  <i
                    class="fa fa-whatsapp"
                    style="font-size: 40px; color: white"
                  ></i>
                </button>
              </div>
              <div class="mt-1 pr-1 text-center" style="padding-top: 0px">
                {{ translation.share }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

/* eslint-disable */
export default {
  components: {
    StarRating,
  },
  data() {
    return {
      emailSent: false,
      afterStars: false,
      comment: "",
      starSize: Number(40),
      rating: 5,
      leaveMessage: "Laisse-nous un message",
      translation: {
        results: "Résultats",
        first: "ère",
        afterFirst: "ème",
        errors: "Erreurs",
        gameTime: "Temps de jeu",
        totalTime: "Temps total",
        leaveEmail:
          "Si tu as apprécié le jeu, tu peux nous laisser ton email, on te tiendra au courant chaque fois qu'un nouveau parcours ouvrira !",
        backToHomePage: "Page d'accueil",
        backToResults: "Résultats",
        openWhatsApp: "Partager par WhatsApp",
      },
      loading: false,
      showEmailBox: true,
      email: "",
      eventurl: "",
      message:
        "Je viens de terminer un excellent jeu de piste ! Je te le recommande vivement : https://ouchui.ch/" +
        localStorage.getItem("navigationName"),
    };
  },
  mounted() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.$store.dispatch(
      "getResultsByPoints",
      localStorage.getItem("navigationName")
    );
  },
  computed: {
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
    allResults() {
      // console.log("this.$store.getters.results", this.$store.getters.results);
      return this.$store.getters.results;
    },
    user() {
      return this.$store.getters.user;
    },
    // loading () {
    // return this.$store.getters.loading
    // },
    timeNow() {
      return setInterval((_) => {
        let d = new Date();
        this.time = d.toLocaleTimeString("he");
      }, 1000);
    },
  },
  methods: {
    sendComment() {
      this.afterStars = true;
      var comment = {
        navigationName: localStorage.getItem("navigationName"),
        rating: this.rating,
        comment: this.comment,
        time: new Date().toString(),
        user: localStorage.getItem("myNameOuchui"),
      };
      this.$store.dispatch("sendComment", comment);
      // console.log("comment, commen", comment);
    },
    translate(lang) {
      switch (lang) {
        case "fr":
          this.email = "";
          this.leaveMessage = "Laisse-nous un message";
          this.translation = {
            first: "ère",
            gameTime: "Temps de jeu",
            totalTime: "Temps total",
            openWhatsApp: "Partager",
            firstPlace: "Première place:",
            rank: "Place",
            howWasIt: "Comment c'était ?",
            noThanks: "Non merci",
            share: "Partager",
            send: "Envoyer",
            skip: "Non merci.",
            results: "Résultats",
            afterFirst: "ème",
            errors: "Points",
            leaveEmail:
              "Laissez-nous votre adresse email, et nous vous informerons dès l'ouverture de nouveaux parcours !",
            backToHomePage: "Page d'accueil",
            backToResults: "Aller aux résultats",
          };
          break;
        case "en":
          this.email = "";
          this.leaveMessage = "Leave us a message";
          this.translation = {
            first: "st",
            gameTime: "Game time",
            totalTime: "Total time",
            openWhatsApp: "Share via WhatsApp",
            firstPlace: "First place:",
            rank: "Rank",
            howWasIt: "How was it?",
            noThanks: "No thanks",
            share: "Share",
            send: "Send",
            skip: "No thanks.",
            results: "Results",
            afterFirst: "th",
            errors: "Errors",
            leaveEmail:
              "Leave us your email address, and we'll notify you as soon as new courses open up!",
            backToHomePage: "Homepage",
            backToResults: "Back to results",
          };
          break;
        case "de":
          this.email = "";
          this.leaveMessage = "Hinterlasse uns eine Nachricht";
          this.translation = {
            first: "erster",
            gameTime: "Spielzeit",
            totalTime: "Gesamtzeit",
            openWhatsApp: "Per WhatsApp teilen",
            firstPlace: "Erster Platz:",
            rank: "Rang",
            howWasIt: "Wie war es?",
            noThanks: "Nein danke",
            share: "Teilen",
            send: "Senden",
            skip: "Nein danke.",
            results: "Ergebnisse",
            afterFirst: "ter",
            errors: "Fehler",
            leaveEmail:
              "Hinterlassen Sie uns Ihre E-Mail-Adresse, und wir werden Sie informieren, sobald neue Kurse verfügbar sind!",
            backToHomePage: "Startseite",
            backToResults: "Zurück zu den Ergebnissen",
          };
          break;
        default:
          // Default to English if no language matches
          this.email = "Email";
          this.leaveMessage = "Leave us a message";
          this.translation = {
            first: "st",
            gameTime: "Game time",
            totalTime: "Total time",
            openWhatsApp: "Share via WhatsApp",
            firstPlace: "First place:",
            rank: "Rank",
            howWasIt: "How was it?",
            noThanks: "No thanks",
            share: "Share",
            send: "Send",
            skip: "No thanks.",
            results: "Results",
            afterFirst: "th",
            errors: "Errors",
            leaveEmail:
              "If you enjoyed the game, you can leave us your email, we will keep you posted whenever a new course opens!",
            backToHomePage: "Homepage",
            backToResults: "Back to results",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    // translate(lang) {
    //   switch (lang) {
    //     case "fr":
    //       (this.email = "E-mail"),
    //         (this.leaveMessage = "Laisse-nous un message"),
    //         (this.translation = {
    //           first: "ère",
    //           gameTime: "Temps de jeu",
    //           totalTime: "Temps total",

    //           openWhatsApp: "Partager",
    //           firstPlace: "Première place:",
    //           rank: "Place",
    //           howWasIt: "Comment c'était ?",
    //           noThanks: "Non merci",
    //           share: "Partager",
    //           send: "Envoyer",
    //           skip: "Non merci.",
    //           results: "Résultats",
    //           first: "ère",
    //           afterFirst: "ème",
    //           errors: "Points",
    //           leaveEmail:
    //             "Vous pouvez nous laisser votre email, on vous tiendra au courant chaque fois qu'un nouveau parcours ouvrira!",
    //           backToHomePage: "Page d'accueil",
    //           backToResults: "Retour résultats",
    //         });
    //       //    console.log("french");
    //       break;
    //     case "en":
    //       this.translation = {
    //         results: "Results",
    //         first: "st",
    //         afterFirst: "th",
    //         errors: "Errors",
    //         gameTime: "Game time",
    //         totalTime: "Total time",
    //         leaveEmail:
    //           "If you enjoyed the game, you can leave us your email, we will keep you posted whenever a new course opens!",
    //         backToHomePage: "Homepage",
    //         backToResults: "Back to results",
    //         openWhatsApp: "Partager par WhatsApp",
    //         firstPlace: "First place",
    //         scorePoints: "Points",

    //         rank: "מקום",
    //         howWasIt: "How was it?",
    //         noThanks: "No thanks",
    //         send: "Send",
    //         share: "Share",
    //       };
    //       //  console.log("english");

    //       // code block
    //       break;
    //     case "he":
    //       (this.email = "כתובת מייל"),
    //         (this.leaveMessage = "השיראו לנו כמה מילים..."),
    //         (this.translation = {
    //           results: "תוצאות",
    //           first: "",
    //           afterFirst: "",
    //           errors: "טעויות",
    //           gameTime: "זמן משחק",
    //           totalTime: "סה''כ זמן",
    //           leaveEmail:
    //             "אם נהנתם, תשאירו לנו את הכתובת מייל שלכם ונודיע לכן כאשר ייפתחו מסלולים חדשים.",
    //           backToHomePage: "דף הבית",
    //           backToResults: "לתוצאות",
    //           openWhatsApp: "שטף",
    //           firstPlace: "מקום ראשון",
    //           scorePoints: "נקודות",

    //           rank: "מקום",
    //           howWasIt: "איך היה לכם?",
    //           noThanks: "לא תודה",
    //           send: "שלח",
    //           share: "שתפו",
    //         });
    //       //   console.log("hebreu");

    //       // code block
    //       break;
    //     default:
    //     // code block
    //   }
    // },
    // translate(lang) {
    //   if (lang == "fr") {
    //     this.translation = {
    //       results: "Résultats",
    //       first: "ère",
    //       afterFirst: "ème",
    //       errors: "Erreurs",
    //       gameTime: "Temps de jeu",
    //       totalTime: "Temps total",
    //       leaveEmail:
    //         "Si tu as apprécié le jeu, tu peux nous laisser ton email, on te tiendra au courant chaque fois qu'un nouveau parcours ouvrira!",
    //       backToHomePage: "Page d'accueil",
    //       backToResults: "Retour aux résultats",
    //     };
    //   } else {
    //     this.translation = {
    //       results: "Results",
    //       first: "st",
    //       afterFirst: "th",
    //       errors: "Errors",
    //       gameTime: "Game time",
    //       totalTime: "Total time",
    //       leaveEmail:
    //         "If you enjoyed the game, you can leave us your email, we will keep you posted whenever a new course opens!",
    //       backToHomePage: "Homepage",
    //       backToResults: "Back to results",
    //     };
    //   }
    // },
    openWhatsapp() {
      window.open(`whatsapp://send?text=` + this.message);
    },
    //
    //
    goToHomePage() {
      // this.$router.push("/home");
      // console.log(
      //   "localStorage.getItem('navigationName')",
      //   localStorage.getItem("navigationName")
      // );
      this.$router.push("/" + localStorage.getItem("navigationName"));
      // location.reload();
      localStorage.clear();
    },
    sendEmail() {
      this.$store.dispatch("sendEmail", {
        navigationName: this.gameParameters.navigationName,
        email: this.email,
      });
      this.email = "";
      this.emailSent = true;
      //  console.log("send email");
    },
  },
};
</script>

<style scoped>
.star {
  position: relative;
  top: 15px;
  color: #eef307;
}
.pointsScore {
  position: relative;
  top: 15px;
  left: 21px;
  font-family: noot;
  font-size: 29px;
}
.whatsappButton {
  border-radius: 36px;
  padding-top: 2px;
  background: white;
  height: 41px;
  margin-top: 2px;
  color: rgb(119, 190, 87);
  width: 150px;
}
.homePageButton {
  border-radius: 36px;
  height: 41px;
  width: 100%;
  font-size: 16px;
  background: white;
  color: green;
  padding-top: 8px;
  margin-top: 0px;
  text-align: center;
}
.backToResults {
  font-size: 15px;
  color: white;
  text-align: center;
  padding-right: 20px;
  /* margin-left: 16px; */
}
.center {
  display: block;
  margin: 0px auto;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.green {
  background-color: #0aa78d;
  border: 2px white solid;
}
.red {
  background-color: #0070b0;
  border: 2px white solid;
}
.blue {
  background-color: #005aff;
  border: 2px white solid;
}

.myModal {
  min-height: 200px;
  overflow: auto;
  width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  color: white;
  position: relative;
  height: auto;
}
.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
#quitResults span {
  position: fixed;
  top: 28px;
  height: 50px;
  width: 50px;
  font-size: 33px;
  color: white;
  left: 17px;
  background: radial-gradient(circle at -37px 6px, white, #0070b0);
  border: 1px #005aff solid;
  border-radius: 100%;
  box-shadow: 6px 5px 6px grey;
  z-index: 100;
}
span svg {
  bottom: 6px;
  position: relative;
  left: 7px;
}
#quitResults {
  position: absolute;
  top: 0px;
  left: 0px;
}
.resultName {
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
  padding-top: 18px;
  position: relative;
  text-align: left;
  left: 9px;
}
.ranking {
  font-family: noot;
  font-size: 30px;
  padding-top: 14px;
  position: relative;
}
.resultDiv {
  border-bottom: solid 1px grey;
  font-size: 15px;
  width: 100%;
  padding: 5px;
  background: white;
  border-radius: 12px;
  margin: 5px auto;
  color: #0070b0;
  -webkit-box-shadow: 6px 5px 6px grey;
  box-shadow: 6px 5px 6px grey;
  border: 1px #005aff solid;
}
.colorDiv {
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 25px;
  padding-top: 30px;
}
.container {
  /* margin-top: 16px; */
  /* background-color: rgba(248, 242, 242, 0.2); */
  font-size: 20px;
  /* height: calc(100vh - 82px); */
}
.colorBtn {
  border-radius: 36px;
  color: #0070b0;
  margin: 0px auto;
  margin-top: 20px;
  background-color: white;
  padding-top: 8px;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}

.results {
  font-size: 35px;
  font-family: "noot";
  text-align: right;
  color: #3d9d89;
  text-shadow: 2px 3px 2px rgb(9 103 169), 0 0 20px rgb(10 175 230 / 0%);
}
</style>
