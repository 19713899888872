<template >
  <div class="container" style="direction: ltr; text-align: left">
    <div class="h6 text-center databaseAffected">
      Databse affected:
      <strong class="text-danger">{{ navigationName }}</strong>
    </div>
    <nav style="max-width: 590px" class="center">
      <div
        class="nav nav-tabs nav-fill"
        id="nav-tab"
        role="tablist"
        style="max-width: 590px"
      >
        <a
          class="nav-item nav-link active"
          id="nav-points-tab"
          data-toggle="tab"
          href="#nav-points"
          role="tab"
          aria-controls="nav-points"
          aria-selected="true"
          @click="getAllPoints()"
          >Points</a
        >
        <a
          class="nav-item nav-link"
          id="nav-dataBase-tab"
          data-toggle="tab"
          href="#nav-dataBase"
          role="tab"
          aria-controls="nav-dataBase"
          aria-selected="false"
          @click="getAllQUestions()"
          >Questions</a
        >
        <a
          class="nav-item nav-link"
          id="nav-staticData-tab"
          data-toggle="tab"
          href="#nav-staticData"
          role="tab"
          aria-controls="nav-staticData"
          aria-selected="false"
          @click="
            getMapParameters();
            getLastPoint();
            getPointsOrders();
          "
          >Static Data</a
        >
      </div>
    </nav>

    <div v-if="showNavigationKindChoices === true" class="over center">
      <div
        v-if="!showCreateNewNavigationGame && !updateExistingOne"
        class="p-3 myModal"
        style="background-color: white"
      >
        <div class="mt-3 text-center">
          <div class="h4">
            Do you want to create a new navigation game or update an existing
            one?
          </div>
        </div>
        <button
          class="fullWidth colorBtn btn font-weight-bold"
          style="background-color: darkgreen"
          @click="showCreateNewNavigationGame = true"
        >
          Create a new one
        </button>

        <button
          class="fullWidth colorBtn btn font-weight-bold position-relative"
          style="background-color: darkred; margin-top: 10px"
          @click="updateExistingOne = true"
        >
          Update an existing one
        </button>
      </div>
      <div
        v-if="showCreateNewNavigationGame"
        class="p-3 myModal"
        style="background-color: white"
      >
        <div class="mt-3 text-center">
          <div class="h4">Name of the new navigation game:</div>
          <input
            name="navigationName"
            label="New navigation name"
            id="navigationName"
            v-model="navigationName"
            required
            class="center fullWidth mb-2"
          />
        </div>
        <div>
          <input type="checkbox" id="infoPage" v-model="infoPage" />
          <label for="infoPage">Information page for each point</label>
        </div>
        <div>
          <input
            type="checkbox"
            id="randomQuestions"
            v-model="randomQuestions"
          />
          <label for="randomQuestions">Random questions</label>
        </div>
        <div>
          <input type="checkbox" id="anniversary" v-model="anniversary" />
          <label for="anniversary">Anniversary</label>
        </div>
        <button
          class="fullWidth colorBtn btn font-weight-bold"
          style="background-color: darkgreen; margin-top: 10px"
          @click="createNewGame"
        >
          Create new game
        </button>
      </div>
      <div
        v-if="updateExistingOne"
        class="p-3 myModal"
        style="background-color: white; overflow: auto; height: 200px"
      >
        <div class="mt-3 text-center">
          <div class="h5">
            Choose for which nativation game you want to update the data base
          </div>
        </div>
        <div v-if="loading">
          <div class="spinner-border text-primary center" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <div v-else :key="navGame" v-for="navGame in allNavGameNames">
          <button
            class="fullWidth colorBtn btn font-weight-bold mb-3"
            style="background-color: green"
            @click="startEditingNavGame(navGame)"
          >
            {{ navGame }}
          </button>
        </div>
      </div>
    </div>
    <!-- ******************************POINTS*************************** -->

    <!-- ******************************POP UP CREATE NEW POINTS*************************** -->

    <div v-if="newPoint">
      <div class="p-3 newQuestion">
        <div v-if="loading">
          <div class="spinner-border text-primary center" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <form @submit.prevent="createNewPoint" width="90%" v-else>
          <div
            class="closeDiv"
            @click="
              newPoint = false;
              missingWord = '';
              pointNumber = '';
            "
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-x"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
          <div class="form-group">
            <label for="pointNumber">Point number</label>
            <input
              name="pointNumber"
              id="pointNumber"
              type="number"
              v-model="pointNumber"
              required
              class="form-control"
              aria-describedby="pointNumber"
              placeholder="Point number"
            />
          </div>

          <!-- <div class="mb-2 form-group"> -->
          <div xs12 sm6 class v-if="showUploadImage">
            <button
              tag="label"
              type="button"
              class="btn btn-primary mt-5 mb-5 btn-lg btn-block btn-sm"
              @click="onPickFile"
            >
              Upload Image
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="image/*"
                @change="onFilePicked"
              />
            </button>
          </div>
          <div>
            <img :src="imageUrl" ref="imageToCanvas" style="display: none" />
            <canvas ref="canvas" v-if="showCanvas" class="fitScreen"></canvas>
            <button
              block
              v-if="showCanvas"
              @click="onPickFile2"
              class="btn btn-primary mt-3 btnEditChangeImage mb-3"
            >
              Change image
            </button>
            <input
              type="file"
              style="display: none"
              ref="fileInput2"
              accept="image/*"
              @change="onFilePicked"
            />
          </div>
          <div class="form-group row">
            <div class="col-12 text-center border-bottom">GPS coordinates</div>
            <div class="col-6">
              <label for="pointLat">Latitude</label>
              <input
                class="form-control"
                aria-describedby="pointLat"
                placeholder="Point's Lat"
                name="pointLat"
                dark
                label="pointLat"
                id="pointLat"
                v-model="pointLat"
                required
              />
            </div>
            <div class="col-6">
              <label for="pointLng">Longitude</label>
              <input
                class="form-control"
                aria-describedby="pointLng"
                placeholder="Point's Lng"
                name="pointLng"
                dark
                label="pointLng"
                id="pointLng"
                v-model="pointLng"
                required
              />
            </div>
          </div>
          <div class="form-group">
            <label for="missingWord">Missing word</label>
            <input
              class="form-control"
              aria-describedby="missingWord"
              placeholder="missingWord"
              name="missingWord"
              dark
              label="missingWord"
              id="missingWord"
              v-model="missingWord"
              required
            />
          </div>
          <div class="form-group">
            <label for="pointInformation">Point information</label>
            <textarea
              class="form-control text-left"
              aria-describedby="pointInformation"
              placeholder="Point information"
              name="pointInformation"
              dark
              label="pointInformation"
              id="pointInformation"
              v-model="pointInformation"
              rows="3"
            ></textarea>
          </div>
          <div xs12>
            <button
              block
              type="submit"
              outline
              class="btn btn-primary mt-5 btn-lg btn-block btn-sm"
            >
              <div
                v-if="loading"
                class="spinner-border text-primary center"
                role="status"
              >
                <span class="sr-only"></span>
              </div>
              <!-- <span slot="loader" v-if="loading" class="custom-loader"></span> -->
              <span v-else>Create new point</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="tab-content px-sm-0 mt-5" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-points"
        role="tabpanel"
        aria-labelledby="nav-points-tab"
      >
        <!-- ******************************POINTS LIST*************************** -->

        <div
          class="tab-pane fade show active"
          id="nav-points"
          role="tabpanel"
          aria-labelledby="nav-points-tab"
        >
          <div class="row mx-0 pt-2">
            <div class="col-12">
              <div class="dropdown">
                <button
                  class="dropdown-toggle mb-3 btn-primary"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :language="language"
                >
                  {{ language }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    @click="(language = 'Français'), switchLangToFrench()"
                    >French database</a
                  >
                  <a
                    class="dropdown-item"
                    @click="(language = 'English'), switchLangToEnglish()"
                    >English database</a
                  >
                </div>
              </div>
            </div>
            <!-- <button
              @click="getAllPoints()"
              v-if="allPoints == ''"
              class="col-5 btn-primary showButtons"
            >
              Show points
            </button> -->
          </div>
          <div
            class="btn fabButton pmd-ripple-effect btn-primary"
            type="button"
            @click="newPoint = true"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              style="font-size: 54px"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
              />
            </svg>
          </div>
          <div v-if="loading">
            <div class="spinner-border text-primary center" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
          <div
            v-else
            v-for="(pointItem, index) in allPoints"
            :key="index"
            :class="{ active: index === activePoint }"
          >
            <div
              class="row mb-2 border-bottom bg-white pb-2 center"
              style="max-width: 590px; width: 98vw"
            >
              <!-- ******************NORMAL POINT PRESENTATION*************************** -->

              <div
                class="row px-3 pt-3 pb-2"
                style="
                  width: 98vw;
                  display: block;
                  margin: 0 auto;
                  max-width: 590px;
                "
                v-if="pointItem.editMode != true"
              >
                <div class="row mb-2">
                  <div class="col-6 mb-2">Point Number:</div>
                  <div class="col-5">
                    {{ allPoints[index].results.pointNumber }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <img
                      :src="allPoints[index].results.imageBase64"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="row border-bottom my-3">
                  <div class="col-6">Missing word:</div>
                  <div
                    class="col-6 mb-2"
                    style="background: #e6e66254; text-align: center"
                  >
                    {{ allPoints[index].results.missingWord }}
                  </div>
                </div>
                <div class="row border-bottom my-3">
                  <div class="col-12">GPS:</div>
                  <div class="col-12 mb-2">
                    <div class="row">
                      <div class="col-2 pr-0">Lat:</div>
                      <div
                        v-if="allMarkers[index]"
                        class="col-4 pl-2"
                        style="
                          background: #e6e66254;
                          text-align: center;
                          overflow: hidden;
                        "
                      >
                        {{ allMarkers[index].lat }}
                      </div>
                      <div
                        v-else
                        class="col-4 pl-2"
                        style="
                          background: #e6e66254;
                          text-align: center;
                          overflow: hidden;
                        "
                      ></div>
                      <div class="col-2">Lng:</div>

                      <div
                        v-if="allMarkers[index]"
                        class="col-4 pl-2"
                        style="
                          background: #e6e66254;
                          text-align: center;
                          overflow: hidden;
                        "
                      >
                        {{ allMarkers[index].lng }}
                      </div>
                      <div
                        v-else
                        class="col-4 pl-2"
                        style="
                          background: #e6e66254;
                          text-align: center;
                          overflow: hidden;
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row border-bottom my-2">
                  <div class="col-12">Point information:</div>
                  <div
                    class="col-12 mb-2"
                    style="background: #e6e66254; text-align: left"
                  >
                    {{ allPoints[index].results.pointInformation }}
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-primary center mt-3 btnEdit"
                  @click="selectPoint(pointItem)"
                >
                  Edit
                </button>
              </div>

              <!-- ******************EDIT POINT MODE*************************** -->

              <div v-else>
                <div class="p-3 newQuestion">
                  <form @submit.prevent="savePoint(pointItem)">
                    <div
                      class="closeDiv"
                      @click="
                        pointItem.editMode = false;
                        resetData();
                      "
                    >
                      <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16"
                        class="bi bi-x"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <label for="pointNumber" class="col-6"
                          >Point number</label
                        >
                        {{ pointNumber }}
                      </div>
                    </div>

                    <!-- <div class="mb-2 form-group"> -->
                    <!-- <div xs12 sm6 class v-if="showUploadImage"> -->
                    <!-- <button
                        tag="label"
                        type="button"
                        class="btn btn-primary mt-5 mb-5 btn-lg btn-block btn-sm"
                        @click="onPickFile3"
                      >
                        Upload Image
                        <input
                          type="file"
                          style="display: none"
                          ref="fileInput3"
                          accept="image/*"
                          @change="onFilePicked"
                        />
                      </button> -->
                    <!-- </div> -->
                    <div xs12 sm6 offset-sm3>
                      <!-- <img
                        :src="imageUrl"
                        ref="imageToCanvas"
                        style="display: none"
                      /> -->
                      <img :src="imageBase64" alt="" style="width: 100%" />
                      <!-- <canvas
                        ref="canvas"
                        v-if="showCanvas2"
                        class="fitScreen"
                      ></canvas> -->
                      <div
                        block
                        @click="onPickFile3"
                        class="btn mt-3 btnEditChangeImage mb-3"
                      >
                        Change image
                      </div>
                      <input
                        type="file"
                        style="display: none"
                        ref="fileInput3"
                        accept="image/*"
                        @change="onFilePicked2"
                      />
                    </div>
                    <div class="form-group row">
                      <div class="col-12 text-center border-bottom">
                        GPS coordinates
                      </div>
                      <div class="col-6">
                        <label for="pointLat">Latitude</label>
                        <input
                          class="form-control"
                          aria-describedby="pointLat"
                          placeholder="Point's Lat"
                          name="pointLat"
                          dark
                          label="pointLat"
                          id="pointLat"
                          v-model="pointLat"
                          required
                        />
                      </div>
                      <div class="col-6">
                        <label for="pointLng">Longitude</label>
                        <input
                          class="form-control"
                          aria-describedby="pointLng"
                          placeholder="Point's Lng"
                          name="pointLng"
                          dark
                          label="pointLng"
                          id="pointLng"
                          v-model="pointLng"
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="missingWord">Missing word</label>
                      <input
                        class="form-control"
                        aria-describedby="missingWord"
                        placeholder="missingWord"
                        name="missingWord"
                        dark
                        label="missingWord"
                        id="missingWord"
                        v-model="missingWord"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label for="pointInformation">Point information</label>
                      <textarea
                        class="form-control"
                        aria-describedby="pointInformation"
                        placeholder="Point information"
                        name="pointInformation"
                        dark
                        label="pointInformation"
                        id="pointInformation"
                        v-model="pointInformation"
                        rows="3"
                      ></textarea>
                    </div>
                    <div xs12>
                      <button
                        block
                        type="submit"
                        outline
                        class="btn btn-primary mt-3 btnEdit"
                      >
                        <div
                          v-if="loading"
                          class="spinner-border text-primary center"
                          role="status"
                        >
                          <span class="sr-only"></span>
                        </div>
                        <!-- <span slot="loader" v-if="loading" class="custom-loader"></span> -->
                        <span v-else>Update</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="btn fabButton pmd-ripple-effect btn-primary"
              type="button"
              @click="newPoint = true"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-plus"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                style="font-size: 54px"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- ******************************QUESTIONS*************************** -->

      <div v-if="newQuestion">
        <!-- class="tab-pane fade"
        id="nav-question"
        role="tabpanel"
        aria-labelledby="nav-question-tab"-->
        <div class="py-3 newQuestion">
          <form @submit.prevent="createNewQuestion" width="90%">
            <div class="closeDiv" @click="newQuestion = false">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-x"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
            <div class="form-group">
              <div class="row">
                <label for="pointLinked" class="col-6 mb-0 pt-2"
                  >Linked to point:</label
                >
                <input
                  class="form-control col-3"
                  aria-describedby="pointLinked"
                  placeholder="Point"
                  name="pointLinked"
                  dark
                  label="pointLinked"
                  id="pointLinked"
                  v-model="pointLinked"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="question">Question</label>
              <input
                class="form-control"
                aria-describedby="question"
                placeholder="question"
                name="question"
                dark
                label="question"
                id="question"
                v-model="question"
                required
              />
            </div>

            <div class="form-group">
              <label for="correctAnswer">Correct Answer</label>
              <input
                class="form-control"
                aria-describedby="correctAnswer"
                placeholder="Correct Answer"
                name="correctAnswer"
                dark
                label="correctAnswer"
                id="correctAnswer"
                v-model="correctAnswer"
                required
              />
            </div>
            <div class="form-group">
              <label for="wrongAnswer1">Wrong Answer1</label>
              <input
                class="form-control"
                aria-describedby="wrongAnswer1"
                placeholder="Wrong Answer1"
                name="wrongAnswer1"
                dark
                label="wrongAnswer1"
                id="wrongAnswer1"
                v-model="wrongAnswer1"
                required
              />
            </div>

            <div class="form-group">
              <label for="wrongAnswer2">Wrong Answer2</label>
              <input
                class="form-control"
                aria-describedby="wrongAnswer2"
                placeholder="Wrong Answer2"
                name="wrongAnswer2"
                dark
                label="wrongAnswer2"
                id="wrongAnswer2"
                v-model="wrongAnswer2"
                required
              />
            </div>

            <div class="form-group">
              <label for="wrongAnswer3">Wrong Answer3</label>
              <input
                class="form-control"
                aria-describedby="wrongAnswer3"
                placeholder="Wrong Answer3"
                name="wrongAnswer3"
                dark
                label="wrongAnswer3"
                id="wrongAnswer3"
                v-model="wrongAnswer3"
                required
              />
            </div>

            <div xs12>
              <button
                block
                type="submit"
                :loading="loading"
                outline
                class="btn btn-primary mt-5 btn-lg btn-block btn-sm"
              >
                Create new question
                <span slot="loader" class="custom-loader"></span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="nav-dataBase"
        role="tabpanel"
        aria-labelledby="nav-dataBase-tab"
      >
        <div class="row mx-0 pt-2">
          <div class="col-12">
            <div class="dropdown">
              <button
                class="dropdown-toggle mb-3 btn-primary"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :language="language"
              >
                {{ language }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  @click="(language = 'Français'), switchLangToFrench()"
                  >French database</a
                >
                <a
                  class="dropdown-item"
                  @click="(language = 'English'), switchLangToEnglish()"
                  >English database</a
                >
              </div>
            </div>
          </div>
          <!-- <button
            @click="getAllQUestions()"
            v-if="allQuestions == ''"
            class="btn-primary showButtons col-5 px-1"
          >
            Show questions
          </button> -->
        </div>
        <div
          v-for="(questionItem, index) in allQuestions"
          :key="index"
          :class="{ active: index === activeQuestion }"
        >
          <div
            class="row mb-2 border-bottom bg-white pt-2 pb-2 center"
            style="max-width: 590px; width: 95vw"
          >
            <div class="col-12 pl-3">Question number: {{ index + 1 }}</div>
            <!-- //*************************************************************************************** */ -->
            <div
              class="col-12 newQuestion"
              v-if="questionItem.editMode === true"
            >
              <form @submit.prevent="saveQuestion(questionItem)">
                <div
                  class="closeDiv"
                  @click="
                    questionItem.editMode = false;
                    resetDataQuestion();
                  "
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-x"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
                <div class="form-group">
                  <div class="row">
                    <label for="pointLinked" class="col-6 mb-0 pt-2"
                      >Linked to point:</label
                    >
                    <input
                      class="form-control col-3"
                      aria-describedby="pointLinked"
                      placeholder="pointLinked"
                      name="pointLinked"
                      dark
                      label="pointLinked"
                      v-model="pointLinked"
                      required
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="question">Question</label>
                  <input
                    class="form-control"
                    aria-describedby="question"
                    placeholder="question"
                    name="question"
                    dark
                    label="question"
                    v-model="question"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="correctAnswer">Correct Answer</label>
                  <input
                    class="form-control"
                    aria-describedby="correctAnswer"
                    placeholder="correctAnswer"
                    name="correctAnswer"
                    dark
                    label="correctAnswer"
                    v-model="correctAnswer"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="wrongAnswer1">Wrong Answer 1</label>
                  <input
                    class="form-control"
                    aria-describedby="wrongAnswer1"
                    placeholder="wrongAnswer1"
                    name="wrongAnswer1"
                    dark
                    label="wrongAnswer1"
                    v-model="wrongAnswer1"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="wrongAnswer2">Wrong Answer 2</label>
                  <input
                    class="form-control"
                    aria-describedby="wrongAnswer2"
                    placeholder="wrongAnswer2"
                    name="wrongAnswer2"
                    dark
                    label="wrongAnswer2"
                    v-model="wrongAnswer2"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="wrongAnswer3">Wrong Answer 3</label>
                  <input
                    class="form-control"
                    aria-describedby="wrongAnswer3"
                    placeholder="wrongAnswer3"
                    name="wrongAnswer3"
                    dark
                    label="wrongAnswer3"
                    v-model="wrongAnswer3"
                    required
                  />
                </div>
                <button class="btn btn-primary mt-3 btnEdit">Update</button>
              </form>
            </div>
            <div
              class="row py-3 border-bottom mx-0"
              style="
                width: 95vw;
                display: block;
                margin: 0 auto;
                max-width: 590px;
              "
              v-else
            >
              <div class="row border-bottom mb-2">
                <div class="col-6 ml-2 pl-4 pb-2">Linked to point:</div>
                <div
                  class="col-5"
                  style="background: #e6e66254; text-align: center"
                >
                  {{ allQuestions[index].results.data.pointLinked }}
                </div>
              </div>
              <div class="col-3 my-2">Question:</div>
              <div
                class="col-11"
                style="
                  background: rgba(230, 230, 98, 0.33);
                  text-align: center;
                  display: block;
                  margin: 0 auto;
                "
              >
                {{ allQuestions[index].results.data.question }}
              </div>

              <div class="col-8 my-2">Correct answer:</div>
              <div
                class="col-11"
                style="
                  background: rgba(230, 230, 98, 0.33);
                  text-align: center;
                  display: block;
                  margin: 0 auto;
                "
              >
                {{ allQuestions[index].results.data.correctAnswer }}
              </div>
              <div class="col-8 my-2">Wrong answer 1:</div>
              <div
                class="col-11"
                style="
                  background: rgba(230, 230, 98, 0.33);
                  text-align: center;
                  display: block;
                  margin: 0 auto;
                "
              >
                {{ allQuestions[index].results.data.wrongAnswer1 }}
              </div>
              <div class="col-8 my-2">Wrong answer 2:</div>
              <div
                class="col-11"
                style="
                  background: rgba(230, 230, 98, 0.33);
                  text-align: center;
                  display: block;
                  margin: 0 auto;
                "
              >
                {{ allQuestions[index].results.data.wrongAnswer2 }}
              </div>
              <div class="col-8 my-2">Wrong answer 3:</div>
              <div
                class="col-11"
                style="
                  background: rgba(230, 230, 98, 0.33);
                  text-align: center;
                  display: block;
                  margin: 0 auto;
                "
              >
                {{ allQuestions[index].results.data.wrongAnswer3 }}
              </div>
              <button
                type="button"
                class="btn btn-primary mt-3 btnEdit"
                @click="selectQuestion(questionItem)"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div
          class="btn fabButton pmd-ripple-effect btn-primary"
          type="button"
          @click="newQuestion = true"
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="bi bi-plus"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style="font-size: 54px"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
            />
          </svg>
        </div>
      </div>

      <!-- <div
        class="tab-pane fade"
        id="nav-sentence"
        role="tabpanel"
        aria-labelledby="nav-sentence-tab"
      >
        <form @submit.prevent="storeSentence" width="90%">
          <div class="form-group">
            <label for="sentence">Sentence to be discovered</label>
            <input
              class="form-control"
              aria-describedby="sentence"
              placeholder="sentence"
              name="sentence"
              dark
              label="sentence"
              id="sentence"
              v-model="sentence"
              required
            />
          </div>

          <div xs12>
            <button
              block
              type="submit"
              :loading="loading"
              outline
              class="btn btn-primary mt-5 btn-lg btn-block"
            >
              Store this sentence
              <span slot="loader" class="custom-loader"></span>
            </button>
          </div>
        </form>
      </div>-->
      <!-- ******************************STATIC DATA*************************** -->

      <div
        class="tab-pane fade"
        id="nav-staticData"
        role="tabpanel"
        aria-labelledby="nav-staticData-tab"
      >
        <form
          @submit.prevent="storeMapParameters"
          width="90%"
          class="mb-3 tabpanel p-3"
        >
          <div class="text-center border-bottom pb-2 mb-2">Map parameters</div>
          <div class="border-bottom pb-2 mb-2" style="font-size: 12px">
            The Map parameters are the GPS coordinates that are in the center of
            the map on the game. The Zoom is how closed you want the map to be
            shown. Usualy it's 16.
          </div>

          <div class="row">
            <div class="form-group col-3">
              <label for="lat">Lat</label>
              <input
                class="form-control"
                aria-describedby="lat"
                placeholder="Lat"
                name="lat"
                dark
                label="lat"
                id="lat"
                v-model="lat"
              />
            </div>
            <div class="form-group col-3">
              <label for="lng">Lng</label>
              <input
                class="form-control"
                aria-describedby="lng"
                placeholder="Lng"
                name="lng"
                dark
                label="lng"
                id="lng"
                v-model="lng"
              />
            </div>
            <div class="form-group col-4">
              <label for="zoom">Zoom</label>
              <input
                class="form-control"
                aria-describedby="zoom"
                placeholder="Zoom"
                name="zoom"
                dark
                label="zoom"
                id="zoom"
                v-model="zoom"
              />
            </div>
            <div class="form-group col-2">
              <button
                type="submit"
                class="col-1 pl-0 mr-4 mt-4 float-right"
                style="
                  font-size: 31px;
                  color: #007bff;
                  padding: 0px;
                  margin-top: -7px;
                  border: none;
                  left: -3px;
                "
              >
                <svg
                  class="bi bi-arrow-right-circle-fill"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>
        <form
          @submit.prevent="storeEndPoint"
          width="90%"
          class="mb-3 tabpanel p-3"
        >
          <div class="text-center border-bottom pb-2 mb-2">End point</div>
          <div class="border-bottom pb-2 mb-2" style="font-size: 12px">
            The End point is the last point of the game.
          </div>
          <div class="row">
            <div class="form-group col-6">
              <label for="endPoint">End point</label>
              <input
                class="form-control"
                aria-describedby="endPoint"
                placeholder="endPoint"
                name="endPoint"
                dark
                label="endPoint"
                id="endPoint"
                v-model="endPoint"
                rows="3"
              />
            </div>
            <div class="col-6">
              <button
                type="submit"
                class="col-1 pl-0 mr-4 mt-4 float-right"
                style="
                  font-size: 31px;
                  color: #007bff;
                  padding: 0px;
                  margin-top: -7px;
                  border: none;
                  left: 9px;
                "
              >
                <svg
                  class="bi bi-arrow-right-circle-fill"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>

        <!-- <div
          class="tab-pane fade"
          id="nav-newPointToArray"
          role="tabpanel"
          aria-labelledby="nav-newPointToArray-tab"
        >-->
        <div class="form-group mb-3 tabpanel p-3">
          <div class="text-center border-bottom pb-2 mb-2">Points order</div>
          <div class="border-bottom pb-2 mb-2" style="font-size: 12px">
            The Points order is the order in which the user will go from point
            to the other.
          </div>
          <div class="row mb-3">
            <div class="col-7">Add a point to the list:</div>
            <input
              ref="inputInFocus"
              class="form-control col-2 ml-3 py-0"
              aria-describedby="newPointToArray"
              placeholder
              name="newPointToArray"
              dark
              label="newPointToArray"
              id="newPointToArray"
              v-model="newPointToArray"
              style="height: 30px"
            />
            <!-- <button class="btn btn-primary" @click="addPointToArray">
              ADD
            </button> -->
            <div
              class="col-1 pl-0 ml-1"
              style="
                font-size: 22px;
                color: #007bff;
                padding: 0px;
                margin-top: -4px;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="currentColor"
                class="bi bi-plus-circle-fill"
                viewBox="0 0 16 16"
                @click="addPointToArray"
              >
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
                />
              </svg>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <div>Point order list:</div>
              <div>
                {{ pointsOrder }}
              </div>
            </div>
            <div class="col-3 mt-2">
              <button class="btn btn-danger" @click="pointsOrder = []">
                Erase
              </button>
            </div>
            <div class="col-2 mt-2">
              <button
                type="submit"
                class="col-1 pl-0 mr-4 float-right"
                style="
                  font-size: 31px;
                  color: #007bff;
                  padding: 0px;
                  margin-top: -7px;
                  border: none;
                "
              >
                <svg
                  class="bi bi-arrow-right-circle-fill"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="storePointsOrder"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="row mt-3">
            <!-- <div class="col-6">
              <button class="btn btn-danger" @click="pointsOrder = []">
                Erase
              </button>
            </div> -->
            <!-- <div class="col-6">
              <button
                block
                type="submit"
                :loading="loading"
                outline
                class="btn btn-primary"
                @click="storePointsOrder"
              >
                Save
                <span slot="loader" class="custom-loader"></span>
              </button>
            </div> -->
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const exifparser = require("exif-parser");

export default {
  data() {
    return {
      imageBase64: "",
      allMarkers: "",
      pointLat: "",
      pointLng: "",
      language: "Français",
      editing: false,
      endPoint: "",
      activePoint: null,
      activeQuestion: null,
      zoom: "",
      appExplaination: "",
      lng: "",
      lat: "",
      anniversary: false,
      randomQuestions: false,
      infoPage: false,
      navigationName: "",
      showCreateNewNavigationGame: false,
      updateExistingOne: false,
      pointInformation: "",
      showNavigationKindChoices: true,
      navigationNumber: "",
      id: "",
      index: "",
      btnId: "btnId",
      key: "",
      e1: "recent",
      email: "",
      firstName: "",
      lastName: "",
      pointNumber: "",
      pointLinked: "",
      correctAnswer: "",
      wrongAnswer1: "",
      wrongAnswer2: "",
      wrongAnswer3: "",
      showUploadImage: true,
      imageUrl: "",
      showCanvas: false,
      image: null,
      // sentence: "",
      pointsOrder: [],
      newPointToArray: "",
      missingWord: "",
      navigationKind: "",
      allQuestions: [],
      allPoints: [],
      question: "",
      newQuestion: false,
      newPoint: false,
    };
  },
  mounted() {
    this.$store.dispatch("fetchAllNavGames");
    this.getAllPoints();
  },
  computed: {
    allNavGameNames() {
      var allNavGameNames = this.$store.getters.allNavGameNames;
      return allNavGameNames;
    },
    user() {
      return this.$store.getters.user;
    },
    loading() {
      return this.$store.getters.loading;
    },
    gameParameters() {
      return this.$store.getters.gameParameters;
    },
  },
  methods: {
    switchLangToFrench() {
      this.$store.dispatch("switchLangToFrench");
      this.$store.dispatch("fetchPoints", this.navigationName);
      this.$store.dispatch("fetchNavtigationData", this.navigationName);
      this.getAllQUestions();
      this.getAllPoints();
    },
    switchLangToEnglish() {
      this.$store.dispatch("switchLangToEnglish");
      this.$store.dispatch("fetchNavtigationData", this.navigationName);
      this.$store.dispatch("fetchPoints", this.navigationName);
      this.getAllQUestions();
      this.getAllPoints();
    },
    saveQuestion(element) {
      element.editMode = false;
      const question = {
        pointLinked: this.pointLinked,
        question: this.question,
        correctAnswer: this.correctAnswer,
        wrongAnswer1: this.wrongAnswer1,
        wrongAnswer2: this.wrongAnswer2,
        wrongAnswer3: this.wrongAnswer3,
      };
      this.$store.dispatch("updatQuestion", {
        question: question,
        navigationName: this.navigationName,
        key: element.results.key,
      });
      this.pointLinked = "";
      this.question = "";
      this.correctAnswer = "";
      this.wrongAnswer1 = "";
      this.wrongAnswer2 = "";
      this.wrongAnswer3 = "";
      this.$store.dispatch("fetchNavtigationData", this.navigationName);
      this.startEditingNavGame(this.navigationName);
      var getQuestionsInterval = setInterval(() => {
        this.getAllQUestions();
        console.log("getQuestionsInterval");
        if (this.loading === false) {
          clearInterval(getQuestionsInterval);
        }
      }, 1000);
      // setTimeout(() => {
      //   this.getAllQUestions();
      // }, 1000);
    },
    savePoint(element) {
      element.editMode = false;
      console.log("UPDATE new poiint");
      const point = {
        pointNumber: this.pointNumber,
        image: this.image,
        missingWord: this.missingWord.toLowerCase(),
        navigationName: this.navigationName,
        pointInformation: this.pointInformation,
        imageBase64: this.imageBase64,
      };
      var marker = {
        position: {
          lat: Number(this.pointLat),
          lng: Number(this.pointLng),
        },
        pointNumber: element.results.pointNumber,
        navigationName: element.results.navigationName,
      };
      console.log("create point", point);
      this.$store.dispatch("updatePoint", point);
      this.$store.dispatch("createNewMarker", marker);
      this.$store.dispatch("fetchPoints", this.navigationName);
      this.startEditingNavGame(this.navigationName);
      this.pointNumber = "";
      this.image = "";
      this.missingWord = "";
      this.pointInformation = "";
      this.imageBase64 = "";
      this.pointLat = "";
      this.pointLng = "";

      var getPointsInterval = setInterval(() => {
        this.getAllPoints();
        console.log("getPointsInterval");
        if (this.loading === false) {
          clearInterval(getPointsInterval);
        }
      }, 1000);
    },
    getMapParameters() {
      var mapParameters = this.$store.getters.mapParameters;
      this.lat = mapParameters.lat;
      this.lng = mapParameters.lng;
      this.zoom = mapParameters.zoom;
    },
    getLastPoint() {
      this.endPoint = this.gameParameters.lastPoint;
      console.log("[getLastPoint] this.endPoint ", this.endPoint);
    },
    getPointsOrders() {
      this.pointsOrder = this.gameParameters.pointsOrder;
    },
    getAllQUestions() {
      var newArrayOfQUestion = [];
      var arrayOfQuestions = Object.values(this.$store.getters.questionsList);
      arrayOfQuestions.forEach(function (results) {
        if (results != null) {
          newArrayOfQUestion.push({ results, editMode: false });
        }
      });
      this.allQuestions = newArrayOfQUestion;
      console.log("[allQuestions] this.allQuestions", this.allQuestions);
      this.$forceUpdate();
    },
    getAllPoints() {
      var newArrayOfPoints = [];
      var arrayOfPoints = Object.values(this.$store.getters.points);
      arrayOfPoints.forEach(function (results) {
        if (results != null) {
          newArrayOfPoints.push({ results, editMode: false });
        }
      });
      this.allPoints = newArrayOfPoints;

      var newArrayOfMarkers = [];
      var arrayOfMarkers = Object.values(this.$store.getters.markers);
      arrayOfMarkers.forEach(function (results) {
        if (results != null) {
          newArrayOfMarkers.push(results);
        }
      });
      this.allMarkers = newArrayOfMarkers;
      this.$forceUpdate();
    },
    selectQuestion(element) {
      element.editMode = true;
      this.pointLinked = element.results.data.pointLinked;
      this.question = element.results.data.question;
      this.correctAnswer = element.results.data.correctAnswer;
      this.wrongAnswer1 = element.results.data.wrongAnswer1;
      this.wrongAnswer2 = element.results.data.wrongAnswer2;
      this.wrongAnswer3 = element.results.data.wrongAnswer3;
    },
    selectPoint(element) {
      element.editMode = true;
      console.log("element", element);
      this.missingWord = element.results.missingWord;
      this.pointNumber = element.results.pointNumber;
      this.pointInformation = element.results.pointInformation;
      this.imageBase64 = element.results.imageBase64;
      this.pointLat = this.allMarkers[element.results.pointNumber - 1].lat;
      this.pointLng = this.allMarkers[element.results.pointNumber - 1].lng;
    },
    resetData() {
      this.missingWord = "";
      this.pointNumber = "";
      this.pointInformation = "";
      this.imageBase64 = "";
      this.pointLat = "";
      this.pointLng = "";
    },
    resetDataQuestion() {
      this.pointLinked = "";
      this.question = "";
      this.correctAnswer = "";
      this.wrongAnswer1 = "";
      this.wrongAnswer2 = "";
      this.wrongAnswer3 = "";
    },
    storeMapParameters() {
      this.$store.dispatch("storeMapParameters", {
        lat: this.lat,
        lng: this.lng,
        zoom: this.zoom,
        navigationName: this.navigationName,
      });
      this.lat = "";
      this.lng = "";
      this.zoom = "";
      setTimeout(() => {
        this.getMapParameters();
      }, 2000);
    },
    storeEndPoint() {
      this.$store.dispatch("storeEndPoint", {
        endPoint: this.endPoint,
        navigationName: this.navigationName,
      });
      this.endPoint = "";
      setTimeout(() => {
        this.getLastPoint();
      }, 2000);
    },
    addPointToArray() {
      if (this.newPointToArray === "") {
        alert(
          "Please fill the new point number to be added to the new array of points"
        );
      } else {
        this.pointsOrder.push(Number(this.newPointToArray));
        this.newPointToArray = "";
        this.$refs.inputInFocus.focus();
        console.log("addPointToArray => pointsOrder", this.pointsOrder);
      }
    },
    storePointsOrder() {
      this.$store.dispatch("storePointsOrder", {
        pointsOrder: this.pointsOrder,
        navigationName: this.navigationName,
      });
      this.pointsOrder = [];
      this.newPointToArray = "";
    },
    startEditingNavGame(navGame) {
      console.log("[startEditingNavGame] navGame", navGame);
      this.navigationName = navGame;
      this.updateExistingOne = false;
      this.showCreateNewNavigationGame = false;
      this.showNavigationKindChoices = false;
      this.$store.dispatch("fetchNavtigationData", navGame);
      this.$store.dispatch("fetchPoints", navGame);
      var getPointsInterval = setInterval(() => {
        this.getAllPoints();
        console.log("getPointsInterval");
        if (this.loading === false) {
          clearInterval(getPointsInterval);
        }
      }, 1000);
    },
    createNewGame() {
      if (this.navigationName === "") {
        alert("Please fill the new navigation name");
        console.log("this.navigationName === null");
      } else {
        this.showCreateNewNavigationGame = false;
        this.showNavigationKindChoices = false;
        console.log(
          "createNewGame => value + this.navigationName",
          this.navigationName,
          this.anniversary,
          this.randomQuestions,
          this.infoPage
        );
        var newGame = {
          navigationName: this.navigationName,
          anniversary: this.anniversary,
          randomQuestions: this.randomQuestions,
          infoPage: this.infoPage,
        };
        console.log("[create new game] newGame", newGame);
        this.$store.dispatch("createNewGame", newGame);
      }
    },
    createNewPoint() {
      console.log("Create new poiint");
      const point = {
        audioUrl: "",
        imageBase64: this.imageUrl,
        pointNumber: this.pointNumber,
        image: this.image,
        missingWord: this.missingWord.toLowerCase(),
        navigationName: this.navigationName,
        pointInformation: this.pointInformation,
      };
      var marker = {
        position: { lat: this.pointLat, lng: this.pointLng },
        pointNumber: this.pointNumber,
        navigationName: this.navigationName,
      };
      console.log("create point", point);

      this.$store.dispatch("createNewMarker", marker);
      console.log("create point", point);
      this.$store.dispatch("createNewPoint", point);
      this.pointNumber = "";
      this.missingWord = "";
      this.pointInformation = "";
      this.imageUrl = "";
      this.image = null;
      this.pointLat = "";
      this.pointLng = "";
      this.$store.dispatch("fetchPoints", this.navigationName);
      this.startEditingNavGame(this.navigationName);
      setTimeout(() => {
        this.getAllPoints();
      }, 1000);
    },
    createNewQuestion() {
      this.newQuestion = false;
      console.log("Create new question");
      const question = {
        pointLinked: this.pointLinked,
        question: this.question,
        correctAnswer: this.correctAnswer,
        wrongAnswer1: this.wrongAnswer1,
        wrongAnswer2: this.wrongAnswer2,
        wrongAnswer3: this.wrongAnswer3,
      };
      var navigationName = this.navigationName;
      console.log("create question", {
        question: question,
        navigationName: navigationName,
      });
      this.$store.dispatch("createNewQuestion", {
        question: question,
        navigationName: navigationName,
      });
      this.pointLinked = "";
      this.question = "";
      this.correctAnswer = "";
      this.wrongAnswer1 = "";
      this.wrongAnswer2 = "";
      this.wrongAnswer3 = "";
      this.$store.dispatch("fetchNavtigationData", this.navigationName);
      this.startEditingNavGame(this.navigationName);
      setTimeout(() => {
        this.getAllQUestions();
      }, 1000);
    },
    // ******************************************** UPLOAD PICTURE**********************************
    onPickFile() {
      // the $refs below give us access to all the ref elements in the template of this component
      console.log("this.$refs.fileInput", this.$refs.fileInput);
      this.$refs.fileInput.click();
    },
    onPickFile2() {
      // the $refs below give us access to all the ref elements in the template of this component
      this.$refs.fileInput2.click();
    },
    onPickFile3() {
      // the $refs below give us access to all the ref elements in the template of this component
      console.log("this.$refs.fileInput3", this.$refs.fileInput3[0]);

      this.$refs.fileInput3[0].click();
    },
    onFilePicked2(event) {
      console.log("[onFilePIcked] event", event);
      const files = event.target.files;
      console.log("[onFilePIcked] files", files);

      this.fillEventDataFromImage(files[0]);

      // As we can choose only one file, we take the first one in the array
      this.filename = files[0].name;
      // console.log("[onFilePIcked] filename", this.filename);

      // Simple chack if the file is valid
      if (this.filename.lastIndexOf(".") <= 0) {
        return alert("Please enter a valid image");
      }
      // Turn it into base64
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // the result here is a base64 image
        this.imageUrl = fileReader.result;
        // console.log("this.imageUrl", this.imageUrl);
        this.imageBase64 = fileReader.result;
        var img = new Image();
        img.src = this.imageUrl;
      });
      fileReader.readAsDataURL(files[0]);
      this.showCanvas = true;
      this.showUploadImage = false;
    },
    onFilePicked(event) {
      // We get the wanted file
      console.log("[onFilePIcked] event", event);
      const files = event.target.files;
      console.log("[onFilePIcked] files", files);

      this.fillEventDataFromImage(files[0]);

      // As we can choose only one file, we take the first one in the array
      this.filename = files[0].name;
      console.log("[onFilePIcked] filename", this.filename);

      // Simple chack if the file is valid
      if (this.filename.lastIndexOf(".") <= 0) {
        return alert("Please enter a valid image");
      }
      // Turn it into base64
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        // the result here is a base64 image
        this.imageUrl = fileReader.result;
        console.log("this.imageUrl", this.imageUrl);

        var img = new Image();
        img.src = this.imageUrl;

        img.addEventListener("load", () => {
          let context = this.$refs.canvas.getContext("2d");
          let image = this.$refs.imageToCanvas;
          // let imageWidth = window.outerWidth - 16
          let imageWidth = 500;
          this.$refs.canvas.width = imageWidth;
          this.$refs.canvas.height = (imageWidth * image.height) / image.width;
          // Now I create the image - what?, top, left, width, height
          context.drawImage(
            image,
            0,
            0,
            imageWidth,
            (imageWidth * image.height) / image.width
          );
          this.image = this.dataURItoBlob(this.$refs.canvas.toDataURL());
        });
      });
      fileReader.readAsDataURL(files[0]);
      this.showCanvas = true;
      this.showUploadImage = false;
    },
    fillEventDataFromImage(file) {
      if ("image/jpeg" !== file.type) {
        console.log("IMAGE NOT OK");
        return;
      }
      console.log("image ok");
      const file_reader = new FileReader();
      const component = this;
      file_reader.addEventListener("load", (e) => {
        console.log("e", e);
        const parser = exifparser.create(file_reader.result);
        try {
          const imagemeta = parser.parse();
          var lng = imagemeta.tags.GPSLongitude;
          var lat = imagemeta.tags.GPSLatitude;
          if (lat === undefined) {
            // var emptyMarker = {
            //   position: { lat: 1, lng: 1 },
            //   pointNumber: this.pointNumber,
            //   navigationName: this.navigationName,
            // };
            this.pointLat = 1;
            this.pointLng = 1;
            console.log("empty marker");
            // this.$store.dispatch("createNewMarker", emptyMarker);
          } else {
            // var marker = {
            //   position: { lat: lat, lng: lng },
            //   pointNumber: this.pointNumber,
            //   navigationName: this.navigationName,
            // };
            this.pointLat = lat;
            this.pointLng = lng;
            console.log("lng, lat", lng, lat);
            // this.$store.dispatch("createNewMarker", marker);
          }
        } catch (err) {
          component.$error(err);
        }
      });
      file_reader.readAsArrayBuffer(file);
    },
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      console.log("[dataURItoBlob] blob", blob);
      return blob;
    },
  },
};
</script>

<style scoped>
.btnEditChangeImage {
  height: 35px;
  border: 0px;
  border-radius: 16px;
  margin: 0 auto;
  display: block;
  max-width: 170px;
  color: #3c69d9;
  border: solid #3c69d9 2px;
  padding-top: 4px;
}
.btnEdit {
  padding: 0px 20px;
  height: 35px;
  border: 0px;
  border-radius: 16px;
  margin: 0 auto;
  display: block;
  max-width: 120px;
  min-width: 120px;
}
.showButtons {
  padding: 0px 20px;
  height: 35px;
  border: 0px;
  border-radius: 16px;
  /* min-width: 174px; */
  margin: 0 auto;
  display: block;
}

#dropdownMenuButton {
  padding: 0px 20px;
  height: 35px;
  border: 0px;
  border-radius: 16px;
  /* min-width: 174px; */
  margin: 0 auto;
  display: block;
}
.tabpanel {
  background: rgba(255, 255, 255, 0.6);
}
.databaseAffected {
  position: fixed;
  padding-top: 20px;
  width: 100vw;
  background: white;
  z-index: 5;
  padding-bottom: 15px;
  top: 0px;
  max-width: 590px;
}
nav {
  position: fixed;
  top: 54px;
  width: 100vw;
  background: white;
  z-index: 5;
}
.closeDiv {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  font-size: 25px;
}
.newQuestion {
  position: fixed;
  top: 0px;
  background: white;
  height: 94vh;
  width: 94vw;
  z-index: 2;
  margin-top: 3vh;
  border: solid 4px red;
  overflow: hidden scroll;
  border-radius: 10px;
  z-index: 6;
  padding: 8px;
  max-width: 590px;
}
.fabButton {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50px;
  background: blue;
  padding: 0px;
}
.center {
  display: block;
  margin: 0px auto;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.myModal {
  min-height: 340px;
  width: 300px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  background-color: white;
  /* border: 5px solid; */
  -moz-box-shadow: 0 0 15px 15px;
  -webkit-box-shadow: 0 0 15px 15px;
  box-shadow: 0 0 15px 15px var(--box-shadow-color);
}
.over {
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 5;
}
.fitScreen {
  max-width: 100%;
}
.uploadPicture {
  border-style: dashed;
  border-color: grey;
  border-width: thin;
  height: 144px;
  margin: 0 auto;
}
.container {
  margin-top: 0px;
  background-color: rgba(255, 255, 255, 0);
  margin: 0px;
  padding: 0px;
}
@media only screen and (max-width: 600px) {
  .newQuestion {
    margin-left: 3vw;
  }
}
</style>



