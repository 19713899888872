<template >
  <div class="over">
    <div
      class="container"
      style="
        position: relative;
        padding: 10px;
        max-width: 360px;
        background-color: rgba(0, 0, 0, 0);
      "
    >
      <!-- <div class="row">
        <div
          class="col-12 text-center btnTuto px-2"
          style="background-color: rgba(255,255,255, 1);color: black;font-size: 16px;  min-height: 66px;"
      >-->
      <div v-if="slide === 0" class="captionDiv1 captionDiv">
        {{ lang }}{{ translation.slide0 }}
      </div>
      <div v-if="slide === 1" class="captionDiv2 captionDiv">
        {{ lang }}{{ translation.slide1 }}
      </div>
      <div v-if="slide === 2" class="captionDiv3 captionDiv">
        {{ lang }}{{ translation.slide2 }}
      </div>
      <div v-if="slide === 3" class="captionDiv4 captionDiv">
        {{ lang }}{{ translation.slide3 }}
      </div>
      <!-- <div v-if="slide === 4" class="captionDiv5 captionDiv ">
        {{ lang }}{{ translation.slide4 }}
      </div> -->
      <div v-if="slide === 4" class="captionDiv7 captionDiv">
        {{ lang }}{{ translation.slide6 }}
      </div>

      <!-- <div v-if="slide === 5" class="captionDiv6 captionDiv ">
        {{ lang }}{{ translation.slide5 }}
      </div>
      <div v-if="slide === 6" class="captionDiv7 captionDiv ">
        {{ lang }}{{ translation.slide6 }}
      </div>
      <div v-if="slide === 7" class="captionDiv8 captionDiv ">
        {{ lang }}{{ translation.slide7 }}
      </div> -->
      <!-- </div>
      </div>-->
      <div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="0"
          no-wrap
          background="#ababab"
          img-width="1024"
          img-height="480"
          style=""
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block w-95 img-fluid"
              width="1024"
              height="480"
              src="../../static/img/tuto/tuto1.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block w-95 img-fluid"
              width="1024"
              height="480"
              src="../../static/img/tuto/tuto2.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/tuto/tuto3.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/tuto/tuto4.png"
              alt
            />
          </b-carousel-slide>

          <!--       <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/tuto/tuto5.png"
              alt
            />
          </b-carousel-slide>-->
        </b-carousel>
      </div>

      <div class="row fixed-bottom mb-3 footer">
        <!-- <div class="col-1" v-if="slide != 5"></div> -->
        <!-- <div
          v-if="slide === 4"
          class="text-center btnTuto col-4"
          style="
            background-color: rgb(84, 163, 49);
            border: 1px solid white;
            border-radius: 50px;
            height: 80px;
            width: 80px;
            padding-top: 26px;
            color: white;
            font-weight: bold;
            top: 5px;
            margin: auto;
          "
          @click="startGame"
        >
          {{ translation.playButton }}
        </div> -->

        <div
          class="row align-items-center justify-content-center bottomBar"
          @click="startGame"
          v-if="slide === 3"
        >
          <div class="startButton d-flex justify-content-center">
            <img
              src="../../static/img/step.png"
              alt=""
              style="height: 60px; position: relative; top: 10px"
            />
          </div>
          <div class="startText">
            {{ translation.letsGoButton }}
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-9 pl-5" style="padding-top: 17px">
            <div class="row">
              <div class="col-1">
                <!-- <div class="smallPoint activePoint"></div> -->
                <div
                  v-bind:class="[slide === 0 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 1 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 2 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 3 ? activeClass : '', notActive]"
                ></div>
              </div>
              <!--     <div class="col-1">
                <div
                  v-bind:class="[slide === 4 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 5 ? activeClass : '', notActive]"
                ></div>
              </div>
            <div class="col-1">
                <div
                  v-bind:class="[slide === 6 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 7 ? activeClass : '', notActive]"
                ></div>
              </div> -->
            </div>
          </div>
          <!-- <div class="col-1" v-if="slide != 5"></div> -->

          <div class="col-2">
            <svg
              style="
                font-size: 38px;
                color: white;
                border-radius: 50px;
                position: relative;
                bottom: 10px;
              "
              v-if="slide != 4"
              @click="slide = slide + 1"
              width="60px"
              height="60px"
              viewBox="0 0 16 16"
              class="bi bi-arrow-right-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
            <!-- <path
              fill-rule="evenodd"
              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8.354 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L9.793 7.5H5a.5.5 0 0 0 0 1h4.793l-2.147 2.146z"
            />
          </svg>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      // loading: false
      slide: 0,
      sliding: null,
      activeClass: "activePoint",
      notActive: "smallPoint",
      translation: {
        letsGoButton: "C'est parti",
        slide0:
          "Durant le jeu de navigation, vous serez invités à naviguer d'un point à un autre, à répondre à des questions, à assembler des puzzles et plus encore. Sur la carte de l'application, vous verrez l'emplacement des lieux où vous devez vous rendre. Bonne chance !",
        slide1:
          "L'application vous indique le numéro du lieu où vous devez vous rendre.",
        slide2: "Trouvez le point sur la carte.",
        slide3:
          "Après être arrivés sur place, faites défiler les images et découvrez ce que l'on peut voir depuis cet endroit et cliquez sur l'image correspondante.",
        slide4:
          "Une fois que vous avez identifié ce qui peut être vu depuis ce point, écrivez le mot qui apparaît au centre de l'image dans l'application.",
        slide5:
          "Vous pouvez passer à un point suivant en cliquant sur le bouton. Attention, cela vous coûtera 10 points.",
        slide6:
          "Sur la carte, en cliquant sur le bouton, vous pouvez voir votre position. Attention ! Vous recevrez une notification pour confirmer les services de localisation. Si vous souhaitez voir votre position, vous devez confirmer. Utilisateurs d'iPhone, si vous rencontrez des problèmes, allez dans Paramètres > Confidentialité > Services de localisation. Assurez-vous que les services de localisation sont activés pour voir votre position. Veuillez également confirmer les services de localisation dans Safari sous Paramètres > Confidentialité > Services de localisation.",
        slide7:
          "Si vous quittez le jeu, vous pouvez toujours revenir en cliquant ici",
        slide8:
          "Sur la carte, en cliquant sur le bouton, vous pouvez voir votre position. Attention ! Pour les utilisateurs d'iPhone, allez dans Paramètres > Confidentialité > Services de localisation. Assurez-vous que les services de localisation sont activés pour voir votre position. En outre, veuillez également activer les services de localisation dans Safari sous Paramètres > Confidentialité > Services de localisation.",
        playButton: "Jouer",
      },
    };
  },
  computed: {
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    welcomePage() {
      return this.$store.getters.welcomePage;
    },
    buttonStartGame() {
      return this.$store.getters.welcomePage.buttonStartGame;
    },
    user() {
      // console.log("[computed] user", this.$store.getters.user)
      return this.$store.getters.user;
    },
    loading() {
      console.log(
        "[computed] this.$store.getters.loading",
        this.$store.getters.loading
      );
      if (this.$store.getters.points) {
        return true;
      } else {
        return false;
      }
    },
    timeNow() {
      return setInterval((_) => {
        let d = new Date();
        this.time = d.toLocaleTimeString("he");
      }, 1000);
    },
  },
  methods: {
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            letsGoButton: "C'est parti",
            slide0:
              "Durant le jeu de navigation, vous serez invités à naviguer d'un point à un autre, à répondre à des questions, à assembler des puzzles et plus encore. Sur la carte de l'application, vous verrez l'emplacement des lieux où vous devez vous rendre. Bonne chance !",
            slide1:
              "L'application vous indique le numéro du lieu où vous devez vous rendre.",
            slide2: "Trouvez le point sur la carte.",
            slide3:
              "Après être arrivés sur place, faites défiler les images et découvrez ce que l'on peut voir depuis cet endroit. Cliquez sur l'image correspondante. Une mauvaise réponse vous coutera 10 points.",
            slide4:
              "Une fois que vous avez identifié ce qui peut être vu depuis ce point, écrivez le mot qui apparaît au centre de l'image dans l'application.",
            slide5:
              "Vous pouvez passer à un point suivant en cliquant sur le bouton. Attention, cela vous coûtera 10 points.",
            slide6:
              "Sur la carte, en cliquant sur le bouton, vous pouvez voir votre position. Attention ! Vous recevrez une notification pour confirmer les services de localisation. Si vous souhaitez voir votre position, vous devez confirmer. Utilisateurs d'iPhone, si vous rencontrez des problèmes, allez dans Paramètres > Confidentialité > Services de localisation. Assurez-vous que les services de localisation sont activés pour voir votre position. Veuillez également confirmer les services de localisation dans Safari sous Paramètres > Confidentialité > Services de localisation.",
            slide7:
              "Si vous quittez le jeu, vous pouvez toujours revenir en cliquant ici",
            slide8:
              "Sur la carte, en cliquant sur le bouton, vous pouvez voir votre position. Attention ! Pour les utilisateurs d'iPhone, allez dans Paramètres > Confidentialité > Services de localisation. Assurez-vous que les services de localisation sont activés pour voir votre position. En outre, veuillez également activer les services de localisation dans Safari sous Paramètres > Confidentialité > Services de localisation.",
            playButton: "Jouer",
          };
          break;
        case "en":
          this.translation = {
            letsGoButton: "Let's go",
            slide0:
              "During the navigation game, you will be asked to move from point to point, answer questions, assemble puzzles, and more. On the app's map, you will see the locations you need to reach. Good luck!",
            slide1:
              "The app shows you the number of the point you need to reach.",
            slide2: "Find the point on the map.",
            slide3:
              "After arriving at the location, scroll through the images and find out what can be seen from there, then select the corresponding image. A wrong answer would cost you 10 points.",
            slide4:
              "Once you identify what can be seen from that point, write the word that appears in the center of the image in the app.",
            slide5:
              "You can skip to the next point by clicking the button. Be aware, it will cost you 10 points.",
            slide6:
              "On the map, by clicking the button, you can see your location. Be aware! You will receive a notification to confirm location services. If you want to see your location, you must confirm. iPhone users, if you encounter any issues, go to Settings > Privacy > Location Services. Make sure location services are enabled to see your location. Also, please confirm location services in Safari under Settings > Privacy > Location Services.",
            slide7:
              "If you exit the game, you can always return by clicking here",
            slide8:
              "On the map, by clicking the button, you can see your location. Be aware! For iPhone users, go to Settings > Privacy > Location Services. Make sure location services are enabled to see your location. Additionally, please enable location services in Safari under Settings > Privacy > Location Services.",
            playButton: "Play",
          };
          break;
        case "de":
          this.translation = {
            letsGoButton: "Los geht's",
            slide0:
              "Im Navigationspiel werden Sie gebeten, von Punkt zu Punkt zu navigieren, Fragen zu beantworten, Puzzles zu lösen und mehr. Auf der Karte in der App sehen Sie die Orte, die Sie erreichen müssen. Viel Erfolg!",
            slide1:
              "Die App zeigt Ihnen die Nummer des Punktes, den Sie erreichen müssen.",
            slide2: "Finden Sie den Punkt auf der Karte.",
            slide3:
              "Nachdem Sie am Ort angekommen sind, blättern Sie durch die Bilder und finden Sie heraus, was von dort aus zu sehen ist, dann wählen Sie das entsprechende Bild aus.",
            slide4:
              "Sobald Sie identifiziert haben, was von diesem Punkt aus zu sehen ist, schreiben Sie das Wort, das in der Mitte des Bildes in der App erscheint.",
            slide5:
              "Sie können zum nächsten Punkt springen, indem Sie auf den Button klicken. Achtung, das kostet Sie 10 Punkte.",
            slide6:
              "Auf der Karte können Sie durch Klicken auf den Button Ihren Standort sehen. Achtung! Sie erhalten eine Benachrichtigung, um die Standortdienste zu bestätigen. Wenn Sie Ihren Standort sehen möchten, müssen Sie bestätigen. iPhone-Benutzer, wenn Sie Probleme haben, gehen Sie zu Einstellungen > Datenschutz > Standortdienste. Stellen Sie sicher, dass die Standortdienste aktiviert sind, um Ihren Standort sehen zu können. Bitte bestätigen Sie auch die Standortdienste in Safari unter Einstellungen > Datenschutz > Standortdienste.",
            slide7:
              "Wenn Sie das Spiel verlassen, können Sie jederzeit durch Klicken hier zurückkehren",
            slide8:
              "Auf der Karte können Sie durch Klicken auf den Button Ihren Standort sehen. Achtung! Für iPhone-Benutzer gehen Sie zu Einstellungen > Datenschutz > Standortdienste. Stellen Sie sicher, dass die Standortdienste aktiviert sind, um Ihren Standort sehen zu können. Zusätzlich, bitte aktivieren Sie die Standortdienste in Safari unter Einstellungen > Datenschutz > Standortdienste.",
            playButton: "Spielen",
          };
          break;
        default:
          // Default case
          break;
      }
    },

    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    startGame() {
      if (localStorage.getItem("navigationName") == "yaffoCroisades") {
        this.$router.push("/paymentPage");
      } else {
        this.$router.push("/play");
      }
    },
  },
};
</script>

<style scoped>
.startText {
  color: white;
  position: absolute;
  bottom: 10px;
  font-weight: bold;
}
.startButton {
  height: 80px;
  width: 80px;
  background: white;
  color: #3369bc;
  position: relative;
  bottom: 47px;
  border-radius: 100%;
}
.bottomBar {
  height: 10%;
  background: #3369bc;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  max-width: 590px;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 0px;
}
#carousel-1 {
  text-shadow: 1px 1px 2px #333;
  height: 50%;
  max-width: 330px;
  margin: 0 auto;
  /* top: 54px; */
  position: absolute;
}
.footer {
  max-width: 590px;
  display: block;
  margin: 0 auto;
}
.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.5;
}
.activePoint {
  opacity: 1;
}
.carousel {
  width: 640px;
  height: 100px;
}
.tuto1 {
  height: 52px;
  width: 52px;
  border: red 3px solid;
  position: absolute;
  top: 142px;
  left: 153px;
  z-index: 10;
  animation: tuto1 2s ease-in-out;
}
.tuto2 {
  height: 47px;
  width: 167px;
  border: red 3px solid;
  position: absolute;
  top: 0px;
  left: 178px;
  z-index: 10;
  animation: tuto2 2s ease-in-out;
}
.tuto3 {
  height: 211px;
  width: 319px;
  border: red 3px solid;
  position: absolute;
  top: 257px;
  left: 20px;
  z-index: 10;
  animation: tuto3 2s ease-in-out;
}
.tuto4 {
  height: 46px;
  width: 322px;
  border: red 3px solid;
  position: absolute;
  top: 466px;
  left: 20px;
  z-index: 10;
  animation: tuto4 2s ease-in-out;
}
.captionDiv1 {
  top: 240px;
  margin-left: 10vw;
  width: 241px;
}
.captionDiv2 {
  top: 125px;
  margin-left: 2vw;
  width: 241px;
}
.captionDiv3 {
  top: 547px;
  right: 95px;
  height: 59px;
}
.captionDiv2:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 61%;
  top: -20px;
  border: 10px solid transparent;

  border-bottom: 10px solid white;
}
.captionDiv3:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 10%;
  top: 59px;
  border: 10px solid transparent;
  /* border-left: 10px solid white; */
  /* border-right: 10px solid white; */
  border-top: 10px solid white;
}
.captionDiv4 {
  top: 42px;
  right: 51px;
  width: 261px;
}
.captionDiv4:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 45%;
  bottom: -20px;
  border: 10px solid transparent;
  /* border-left: 10px solid white; */
  /* border-right: 10px solid white; */
  border-top: 10px solid white;
}
.captionDiv5 {
  top: 387px;
  right: 66px;
  width: 221px;
}
.captionDiv5:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 48%;
  bottom: -20px;
  border: 10px solid transparent;
  /* border-left: 10px solid white; */
  /* border-right: 10px solid white; */
  border-top: 10px solid white;
  /* border-bottom: 10px solid white; */
}
.captionDiv7 {
  top: 159px;
  left: 20px;
  width: 239px;
}
.captionDiv7:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 6%;
  top: -20px;
  border: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.captionDiv8 {
  top: 344px;
  left: 108px;
  width: 239px;
}
.captionDiv8:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 66%;
  top: 44px;
  border: 10px solid transparent;
  /* border-left: 10px solid white; */
  /* border-right: 10px solid white; */
  border-top: 10px solid white;
}
/* .captionDiv8 {
  top: 118px;
  right: 14px;
  width: 290px;
}
.captionDiv8:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 65%;
  top: -20px;
  border: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
} */
.captionDiv6 {
  top: 414px;
  right: 3px;
  width: 222px;
}
.captionDiv6:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 76%;
  bottom: -20px;
  border: 10px solid transparent;
  /* border-left: 10px solid white; */
  /* border-right: 10px solid white; */
  border-top: 10px solid white;
  /* border-bottom: 10px solid white; */
}

.captionDiv {
  animation: getOpacity 1s;
  position: absolute;
  z-index: 5;
  font-size: 16px;
  background: white;
  border-radius: 10px;
  padding: 10px;
}
@keyframes getOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btnTuto {
  background-color: white;
  color: black;
  padding: 9px;
  font-size: 16px;
}
.over {
  top: 0px;
  left: 0px;
  font-size: 18px;
  width: 100vw;
  max-width: 590px;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: start;
}
.colorDiv {
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 25px;
  padding-top: 30px;
}
.container {
  /* margin-top: 16px; */
  background-color: rgba(248, 242, 242, 0.2);
  font-size: 20px;
  height: calc(100vh - 82px);
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.rtl {
  direction: rtl;
}
.userName {
  font-size: 25px;
  text-align: right;
  color: black;
  /* text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0); */
}
</style>
