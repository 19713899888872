<template >
  <div class="over">
    <div
      class="container"
      style="position: relative; padding='10px'; max-width: 360px; background-color: rgba(0,0,0,0);"
    >
      <div v-if="slide === 0" class="captionDiv1 captionDiv">
        {{ lang }}{{ translation.slide0 }}
      </div>
      <div v-if="slide === 1" class="captionDiv2 captionDiv">
        {{ lang }}{{ translation.slide1 }}
      </div>
      <div v-if="slide === 2" class="captionDiv3 captionDiv">
        {{ lang }}{{ translation.slide2 }}
      </div>
      <div v-if="slide === 3" class="captionDiv4 captionDiv">
        {{ lang }}{{ translation.slide3 }}
      </div>
      <div v-if="slide === 4" class="captionDiv5 captionDiv">
        {{ lang }}{{ translation.slide4 }}
      </div>
      <div v-if="slide === 5" class="captionDiv6 captionDiv">
        {{ lang }}{{ translation.slide5 }}
      </div>
      <div v-if="slide === 6" class="captionDiv7 captionDiv">
        {{ lang }}{{ translation.slide6 }}
      </div>
      <div>
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="0"
          no-wrap
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="
            text-shadow: 1px 1px 2px #333;
            height: 50%;
            max-width: 330px;
            margin: 0 auto;
          "
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block w-95 img-fluid"
              width="1024"
              height="480"
              src="../../static/img/numeroPoint.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/CarteWelcome.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/pancarte2.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/motManquant.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/passerLePoint.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/ouvrirMenu.png"
              alt
            />
          </b-carousel-slide>
          <b-carousel-slide>
            <img
              slot="img"
              class="d-block img-fluid w-95"
              width="1024"
              height="480"
              src="../../static/img/reprendre.png"
              alt
            />
          </b-carousel-slide>
        </b-carousel>
      </div>

      <div class="row fixed-bottom mb-3 footer">
        <div
          v-if="slide === 6"
          class="text-center btnTuto col-4"
          style="
            background-color: rgb(84, 163, 49);
            border: 1px solid white;
            border-radius: 50px;
            height: 80px;
            width: 80px;
            padding-top: 26px;
            color: white;
            font-weight: bold;
            top: 5px;
            margin: auto;
          "
          @click="startGame"
        >
          {{ translation.playButton }}
        </div>
        <div class="row" v-else>
          <div class="col-9 pl-5" style="padding-top: 17px">
            <div class="row">
              <div class="col-1">
                <div
                  v-bind:class="[slide === 0 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 1 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 2 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 3 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 4 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 5 ? activeClass : '', notActive]"
                ></div>
              </div>
              <div class="col-1">
                <div
                  v-bind:class="[slide === 6 ? activeClass : '', notActive]"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <svg
              style="
                font-size: 38px;
                color: white;
                border-radius: 50px;
                position: relative;
              "
              v-if="slide != 6"
              @click="slide = slide + 1"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-arrow-right-short"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"
              />
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      // loading: false
      slide: 0,
      sliding: null,
      activeClass: "activePoint",
      notActive: "smallPoint",
      translation: {
        slide0:
          "L'application vous indique vers quel point vous devez vous rendre.",
        slide1: "Trouver le point sur la carte.",
        slide2: "Allez-y et trouvez la pancarte que vous voyez sur l'image.",
        slide3: "Ecrivez le mot manquant sur l'image.",
        slide4:
          "Si vous ne trouvez pas la pancarte, vous pouvez passer au point suivant et 10 minutes seront ajoutées à votre temps final.",
        slide5: "Ouvrez le menu.",
        slide6:
          "Si vous avez pris une petite pause, ou que ça a coincé quelque part, vous pouvez à tout moment revenir dans le jeu en cliquant là.",
        playButton: "Jouez !",
      },
    };
  },
  computed: {
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    welcomePage() {
      return this.$store.getters.welcomePage;
    },
    buttonStartGame() {
      return this.$store.getters.welcomePage.buttonStartGame;
    },
    user() {
      return this.$store.getters.user;
    },
    loading() {
      console.log(
        "[computed] this.$store.getters.loading",
        this.$store.getters.loading
      );
      if (this.$store.getters.points) {
        return true;
      } else {
        return false;
      }
    },
    timeNow() {
      return setInterval((_) => {
        let d = new Date();
        this.time = d.toLocaleTimeString("he");
      }, 1000);
    },
  },
  methods: {
    translate(lang) {
      if (lang == "fr") {
        this.translation = {
          slide0:
            "L'application vous indique vers quel point vous devez vous rendre.",
          slide1: "Trouver le point sur la carte.",
          slide2:
            "Allez-y et trouvez l'inscription que vous voyez sur l'image.",
          slide3: "Ecrivez le mot manquant sur l'image.",
          slide4:
            "Si vous ne trouvez pas la pancarte ou la réponse, vous pouvez passer au point suivant et 10 minutes seront ajoutées à votre temps final.",
          slide5: "Ouvrez le menu.",
          slide6:
            "Si vous avez pris une petite pause, ou que ça a coincé quelque part, vous pouvez à tout moment revenir dans le jeu en cliquant là.",
          playButton: "Jouez !",
        };
      } else {
        this.translation = {
          slide0: "The app lets you know to which point you need to go.",
          slide1: "Find the location on the map.",
          slide2: "Go there and find this sign.",
          slide3: "Enter the missing word.",
          slide4:
            "If you don't finf the sign, you can jump to the next point and 10 minutes will be added to your final time.",
          slide5: "Open the menu.",
          slide6:
            "If you took a break, you can always go back to your last point by clicking here.",
          playButton: "Play!",
        };
      }
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    startGame() {
      if (this.$store.getters.navigationName == "escaladeKids") {
        this.$router.push("/paymentPage");
      } else {
        this.$router.push("/play");
      }
    },
  },
};
</script>

<style scoped>
.footer {
  max-width: 590px;
  display: block;
  margin: 0 auto;
}
.smallPoint {
  width: 5px;
  height: 5px;
  border-radius: 60px;
  background: white;
  opacity: 0.5;
}
.activePoint {
  opacity: 1;
}
.carousel {
  width: 640px;
  height: 100px;
}
.tuto1 {
  height: 52px;
  width: 52px;
  border: red 3px solid;
  position: absolute;
  top: 142px;
  left: 153px;
  z-index: 10;
  animation: tuto1 2s ease-in-out;
}
.tuto2 {
  height: 47px;
  width: 167px;
  border: red 3px solid;
  position: absolute;
  top: 0px;
  left: 178px;
  z-index: 10;
  animation: tuto2 2s ease-in-out;
}
.tuto3 {
  height: 211px;
  width: 319px;
  border: red 3px solid;
  position: absolute;
  top: 257px;
  left: 20px;
  z-index: 10;
  animation: tuto3 2s ease-in-out;
}
.tuto4 {
  height: 46px;
  width: 322px;
  border: red 3px solid;
  position: absolute;
  top: 466px;
  left: 20px;
  z-index: 10;
  animation: tuto4 2s ease-in-out;
}
.captionDiv1 {
  top: 144px;
  margin-left: 2vw;
  width: 241px;
}
.captionDiv2 {
  top: 426px;
  right: 24px;
  height: 44px;
}
.captionDiv1:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 62%;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid white;
}
.captionDiv2:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 84%;
  top: 44px;
  border: 10px solid transparent;
  border-top: 10px solid white;
}
.captionDiv3 {
  top: 116px;
  right: 60px;
  width: 251px;
}
.captionDiv3:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 51%;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid white;
}
.captionDiv4 {
  top: 332px;
  right: 39px;
}
.captionDiv4:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 48%;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid white;
}
.captionDiv5 {
  top: 240px;
  left: 11px;
  width: 252px;
}
.captionDiv5:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 6%;
  bottom: -20px;
  border: 10px solid transparent;
  border-top: 10px solid white;
}
.captionDiv6 {
  top: 12px;
  right: 4px;
}
.captionDiv6:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 8%;
  right: 10%;
  top: -20px;
  border: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.captionDiv7 {
  top: 110px;
  right: 14px;
  width: 250px;
}
.captionDiv7:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  right: 8%;
  right: 34%;
  top: -20px;
  border: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.captionDiv {
  animation: getOpacity 1s;
  position: absolute;
  z-index: 5;
  font-size: 16px;
  background: white;
  border-radius: 10px;
  padding: 10px;
}
@keyframes getOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btnTuto {
  background-color: white;
  color: black;
  padding: 9px;
  font-size: 16px;
}
.over {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 18px;
  max-width: 590px;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.colorDiv {
  margin: 0 auto;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 25px;
  padding-top: 30px;
}
.container {
  /* margin-top: 16px; */
  background-color: rgba(248, 242, 242, 0.2);
  font-size: 20px;
  height: calc(100vh - 82px);
  display: block;
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.rtl {
  direction: rtl;
}
.userName {
  font-size: 25px;
  text-align: right;
  color: black;
  /* text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0); */
}
</style>
