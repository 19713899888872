// import mapElementFactory from './mapElementFactory.js'
import { MapElementFactory } from "vue2-google-maps";

const props = {
  draggable: {
    type: Boolean,
  },
  editable: {
    type: Boolean,
  },
  options: {
    twoWay: false,
    type: Object,
  },
  path: {
    type: Array,
    twoWay: true,
  },
};

const events = [
  "click",
  "dblclick",
  "drag",
  "dragend",
  "dragstart",
  "mousedown",
  "mousemove",
  "mouseout",
  "mouseover",
  "mouseup",
  "rightclick",
];

export default MapElementFactory({
  name: "PolylineMap",
  // ctr: () => google.maps.Polyline,
  ctr() {
    return window.google.maps.Polyline;
  },

  mappedProps: props,
  //   props: {
  //     // deepWatch: {
  //     //   type: Boolean,
  //     //   default: false,
  //     // },
  //     path: [
  //         { lat: 32.654829556504545, lng: 35.420663047555436 },
  //         { lat: 32.65504634818813, lng: 35.40512769241839 },
  //       ],
  //   },
  events,

  afterCreate(polylineDraw) {
    let polylinePath = new window.google.maps.Polyline({
      path: [
        { lat: 39.654829556504545, lng: 31.420663047555436 },
        { lat: 32.65504634818813, lng: 35.40512769241839 },
      ],
      geodesic: true,
      strokeColor: "#5C81C4",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let path = [
      { lat: 32.654829556504545, lng: 35.420663047555436 },
      { lat: 32.65504634818813, lng: 35.40512769241839 },
    ];
    // console.log("polylinePath", polylinePath)
    console.log("polylineDraw", polylineDraw);
    polylinePath.setPath(path);

    // this.$watch(
    //   () => [this.origin, this.destination, this.travelMode, this.waypoints],
    //   () => {
    //     let { origin, destination, travelMode, waypoints } = this;
    //     if (!origin || !destination || !travelMode || !waypoints) return;
    //     directionsService.route(
    //       {
    //         origin,
    //         destination,
    //         travelMode,
    //         waypoints
    //       },
    //       (response, status) => {
    //         if (status !== "OK") return;
    //         // eslint-disable-next-line no-debugger
    //         polylineDraw.getPath(path);
    //         // directionsRenderer.setOptions({suppressMarkers: true});

    //         // console.log("response", response)
    //       }
    //     );
    //   }
    // );

    // var clearEvents = () => {}

    // this.$watch('path', (path) => {
    //   if (path) {
    //     clearEvents()

    //     this.$polylineObject.setPath(path)

    //     const mvcPath = this.$polylineObject.getPath()
    //     const eventListeners = []

    //     const updatePaths = () => {
    //       this.$emit('path_changed', this.$polylineObject.getPath())
    //     }

    //     eventListeners.push([mvcPath, mvcPath.addListener('insert_at', updatePaths)])
    //     eventListeners.push([mvcPath, mvcPath.addListener('remove_at', updatePaths)])
    //     eventListeners.push([mvcPath, mvcPath.addListener('set_at', updatePaths)])

    //     clearEvents = () => {
    //       eventListeners.map(([obj, listenerHandle]) => // eslint-disable-line no-unused-vars
    //       window.google.maps.event.removeListener(listenerHandle))
    //     }
    //   }
    // }, {
    //   deep: this.deepWatch,
    // //   immediate: true,
    // immediate: false,

    // })
  },

  //   afterCreate (inst) {
  //     var clearEvents = () => {}

  //     this.$watch('path', (path) => {
  //       if (path) {
  //         clearEvents()

  //         this.$polylineObject.setPath(path)

  //         const mvcPath = this.$polylineObject.getPath()
  //         const eventListeners = []

  //         const updatePaths = () => {
  //           this.$emit('path_changed', this.$polylineObject.getPath())
  //         }

  //         eventListeners.push([mvcPath, mvcPath.addListener('insert_at', updatePaths)])
  //         eventListeners.push([mvcPath, mvcPath.addListener('remove_at', updatePaths)])
  //         eventListeners.push([mvcPath, mvcPath.addListener('set_at', updatePaths)])

  //         clearEvents = () => {
  //           eventListeners.map(([obj, listenerHandle]) => // eslint-disable-line no-unused-vars
  //           window.google.maps.event.removeListener(listenerHandle))
  //         }
  //       }
  //     }, {
  //       deep: this.deepWatch,
  //       immediate: true,
  //     })
  //   }
});
