<template >
  <div
    class="container"
    style="
      min-height: 100%;
      font-size: 30px;
      position: absolute;
      top: 0px;
      left: 0px;
      min-width: 100vw !important;
      background: white;
    "
  >
    <div class="row align-items-center text-center">
      <div class="col-12 mainTitle">oùchui?</div>
      <div class="col-12 subtitle">Jeu d'orientation</div>
      <div style="position: absolute; top: 0px; left: 6px" @click="openMain">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="bi bi-arrow-left-short"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style="color: green; font-size: 45px"
        >
          <path
            fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      </div>
    </div>

    <div class="row align-items-center text-center background1">
      <div class="col-6">
        <img src="../../static/img/map.png" class="imageIcon" alt="" />
      </div>
      <div class="col-6 text-left iconTextLeft">
        Vous devrez lire une carte, vous diriger d'un point à un autre tout en
        répondant à des questions.
      </div>
    </div>

    <div class="row align-items-center text-center background2">
      <div class="col-6 text-right iconTextRight">
        Le jeu vient à vous, là où vous déciderez.
      </div>
      <div class="col-6">
        <img src="../../static/img/quartier.png" class="imageIcon" alt="" />
      </div>
    </div>
    <div class="row align-items-center text-center background1">
      <div class="col-6">
        <img src="../../static/img/party.png" class="imageIcon" alt="" />
      </div>
      <div class="col-6 text-left iconTextLeft">
        Une activité qui convient à n'importe quel événement; un anniversaire,
        une soirée de boîte ou un parcours éducatif!
      </div>
    </div>

    <div class="row align-items-center text-center background2">
      <div class="col-6 text-right iconTextRight">
        Vous décidez où passe le parcours!
      </div>
      <div class="col-6">
        <img src="../../static/img/gps.png" class="imageIcon" alt="" />
      </div>
    </div>
    <div class="row align-items-center text-center background1">
      <div class="col-6">
        <img src="../../static/img/education.png" class="imageIcon" alt="" />
      </div>
      <div class="col-6 text-left iconTextLeft">
        Vous receverez un soutien complet lors de la mise en place du jeu.
      </div>
    </div>

    <div class="row align-items-center text-center background2">
      <div class="col-6 text-right iconTextRight">
        Il n'y a pas de limite dans le nombre d'utilisateurs.
      </div>
      <div class="col-6">
        <img src="../../static/img/people.png" class="imageIcon" alt="" />
      </div>
    </div>

    <div class="row align-items-center text-center background1">
      <div class="col-6">
        <img src="../../static/img/trophy.png" class="imageIcon" alt="" />
      </div>
      <div class="col-6 text-left iconTextLeft">
        Attention, c'est un concours, vous verrez à quelle place vous êtes
        arrivé à la fin.
      </div>
    </div>

    <div class="row align-items-center text-center background2">
      <div class="col-6 text-right iconTextRight">
        Le jeu est mis en place en quelques heures.
      </div>
      <div class="col-6">
        <img src="../../static/img/clock.png" class="imageIcon" alt="" />
      </div>
    </div>

    <div class="row align-items-center text-center background1">
      <div class="col-12 text-center textTryGame p-0">Voulez-vous essayer?</div>
      <div class="col-12 text-left">
        <div class="text-center btnTuto rtl tryButton" @click="openExampleGame">
          Départ
        </div>
      </div>
    </div>
    <a
      href="https://wa.me/41782296550/?text=Nous%20aurions%20voulu%20recevoir%20plus%20d'inofrmation%20à%20propos%20de%20la%20création%20d'un%20parcours%20sur%20l'application%20Oùchui."
    >
      <div
        class="row align-items-center text-center background2"
        style="height: 85px"
      >
        <div class="col-8 text-right iconTextRight pl-2">
          Contactez-nous par WhatsApp
        </div>
        <div class="col-4">
          <button class="btn bmd-btn-fab p-0">
            <i
              class="fa fa-whatsapp"
              style="font-size: 55px; color: #46ad46"
            ></i>
          </button>
        </div>
      </div>
    </a>

    <div class="row align-items-center text-center footer">
      <div class="col-12">contact@ouchui.ch</div>
      <div class="col-12">© 2021 ouchui.ch</div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      navigationName: "",
      translation: {
        geneva: "GENEVE",
        lancy: "LANCY",
        lake: "LAC",
      },
    };
  },
  mounted() {
    this.scrollToTop();
  },

  computed: {
    error() {
      return this.$store.getters.error;
    },
    lang() {
      return this.translate(this.$store.getters.lang);
    },
    // loading() {
    //   return this.$store.getters.loading;
    // }
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    // translate(lang) {
    //   switch (lang) {
    //     case "fr":
    //       this.translation = {
    //         restart: "Recommencer le jeu",
    //         reopen: "Retour aux dernier point",
    //       };
    //       console.log("french");
    //       break;
    //     case "en":
    //       this.translation = {
    //         restart: "Recommencer le jeu",
    //         reopen: "Retour aux dernier point",
    //       };
    //       console.log("english");

    //       // code block
    //       break;
    //     case "he":
    //       this.translation = {
    //         restart: "להתחיל את השמחק מהתחלה",
    //         reopen: "לחזור לנקודה האחרונה",
    //       };
    //       console.log("hebreu");

    //       // code block
    //       break;
    //     default:
    //     // code block
    //   }
    // },
    openMain() {
      this.$router.push("/");
    },
    translate(lang) {
      if (lang == "fr") {
        this.translation = {
          geneva: "GENEVE",
          lancy: "LANCY",
          lake: "LAC",
        };
      } else {
        this.translation = {
          geneva: "GENEVA",
          lancy: "LANCY",
          lake: "LAKE",
        };
      }
    },
    openExampleGame() {
      this.$router.push("/demoPage");
    },
    // openNations() {
    //   this.$router.push("/nations");
    // },
    // openLancy() {
    //   this.$router.push("/lancy");
    // },
    // openGeneve() {
    //   this.$router.push("/geneve");
    // },
    // openLake() {
    //   this.$router.push("/lake");
    // },
    // openMeyrin() {
    //   this.$router.push("/meyrin");
    // },
    // openDarkGeneva() {
    //   this.$router.push("/darkGeneva");
    // },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>

<style scoped>
.tryButton {
  background-color: rgb(84, 163, 49);
  border: 3px solid green;
  border-radius: 92px;
  height: 120px;
  width: 120px;
  padding-top: 35px;
  color: white;
  font-weight: bold;
  top: -21px;
  margin: auto;
  font-size: 25px;
  position: relative;
}
.iconTextLeft {
  font-size: 25px;
  padding-right: 80px;
}
.iconTextRight {
  font-size: 25px;
  padding-left: 80px;
}
.textTryGame {
  font-size: 39px;
}
.subtitle {
  bottom: 44px;
  position: relative;
  font-size: 39px;
  color: green;
}
.mainTitle {
  font-family: "Slackey", cursive;
  font-size: 70px;
  color: green;
  margin-top: 80px;
}
.imageIcon {
  height: 250px;
}
.rtl {
  direction: rtl;
}
.background1 {
  background: rgb(21 132 21 / 9%);
  color: rgba(0, 0, 0, 70%);
  height: 300px;
}
.background2 {
  color: rgba(0, 0, 0, 70%);
  height: 300px;
}
.footer {
  font-size: 18px;
  color: white;
  background: green;
}
.myModal {
  width: 320px;
  border-radius: 10px;
  display: block;
  margin: 0px auto;
  margin-top: calc(50vh - 170px);
  background-color: white;
}
.over {
  position: fixed;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.colorBtn {
  border-radius: 36px;
  height: 56px;
  color: white;
}
.fullWidth {
  width: 95%;
  display: block;
  margin: 0px auto;
}
.center {
  display: block;
  margin: 0px auto;
}
/*This is the code to make the spinner spin*/
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
/* @font-face {
  font-family: noot;
  src: url(../../static/fonts/Noot/noot-aj.woff);
} */
@media only screen and (max-width: 600px) {
  .imageIcon {
    height: 100px;
  }
  .mainTitle {
    font-size: 40px;
    margin-top: 28px;
  }
  .subtitle {
    bottom: 20px;
    font-size: 23px;
  }
  .iconTextLeft {
    font-size: 16px;
    padding-right: 0px;
  }
  .iconTextRight {
    font-size: 16px;
    padding-left: 0px;
  }
  .background1 {
    height: 180px;
  }
  .background2 {
    height: 180px;
  }
  .footer {
    font-size: 15px;
    color: white;
    background: green;
  }
  .textTryGame {
    font-size: 22px;
  }
  .tryButton {
    border: 1px solid green;
    border-radius: 50px;
    height: 80px;
    width: 80px;
    padding-top: 26px;
    top: -11px;
    font-size: 16px;
  }
}
</style>



